import { AxiosError, AxiosResponse } from "axios";
import BaseServiceHttp from "../../_metronic/helpers/functions/http/BaseServiceHttp";

import { getToken } from './../redux/api/auth/getToken';
import * as authHelper from './../modules/auth/core/AuthHelpers';


// recupera os carner do contrato
export async function getReportFrame(token: string | null = null) {

    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';
    const dataLocalStorage = getToken();

    const customConfig: any = {
        method: 'get',
        url: `/module/reportFrame/${uuid || lastUuid}`,
        headers: {
            Authorization: `Bearer ${dataLocalStorage?.token || token}`,
            'X-UUID-Assinante': uuid || lastUuid
        }

    };
    const http = BaseServiceHttp(customConfig);

    return await http(customConfig)
        .then((response: AxiosResponse<any>) => {
            return response;
        })
        .catch((error: AxiosError) => {
            // throw error; // Rejeita a Promise para propagar o erro

            return {
                data: []
            }
        });
}