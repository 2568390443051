import axios, { AxiosError, AxiosResponse } from 'axios'
import { IDadosGerais, ICarne, ITypeInstallment, IInstallment, IFormContract } from '../interface/I_Contract'
import { IClient, IParticipant } from '../interface/I_Client';
import { IResponse, AuthType } from '../interface/I_General';
import { useAuth } from '../modules/auth';
import { ChangeInMass } from '../types/T_General';
import BaseServiceHttp from '../../_metronic/helpers/functions/http/BaseServiceHttp';

import { getToken } from './../redux/api/auth/getToken';
import * as authHelper from './../modules/auth/core/AuthHelpers';

const DATA_GENERAL_URL = `${process.env.REACT_APP_API_URL}/module/details`;
const API_URL = process.env.REACT_APP_API_URL;

// recupera detalhes do contarto
export function detailsContract(id: number) {

    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';
    const dataLocalStorage = getToken();


    const authorization_barear = {
        headers: {
            Authorization: `Bearer ${dataLocalStorage?.token}`,
            'X-UUID-Assinante': uuid || lastUuid
        }
    };

    return axios.post<IDadosGerais | any>(`${API_URL}/module/details`, { id }, authorization_barear);
}

// recupera os carner do contrato
export async function getMeatContract(id: number) {

    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';
    const dataLocalStorage = getToken();

    const customConfig: any = {
        method: 'get',
        url: `/module/getMeatContract`,
        data: {
            id: id,
        },
        headers: {
            Authorization: `Bearer ${dataLocalStorage?.token}`,
            'X-UUID-Assinante': uuid || lastUuid
        }

    };
    const http = BaseServiceHttp(customConfig);

    return await http(customConfig)
        .then((response: AxiosResponse<any>) => {
            return response;
        })
        .catch((error: AxiosError) => {
            // throw error; // Rejeita a Promise para propagar o erro

            return {
                data: []
            }
        });
    // return axios.get<ICarne>(`${API_URL}/module/getMeatContract`, { params: { id } });
}

// recupera dados do participante
export async function getParticipant(id: number) {
    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';
    const dataLocalStorage = getToken();

    const authorization_barear = {
        headers: {
            Authorization: `Bearer ${dataLocalStorage?.token}`,
            'X-UUID-Assinante': uuid || lastUuid
        }
    };

    try {
        const response = await axios.post<IParticipant>(`${API_URL}/module/getParticipant`, { params: { id } }, authorization_barear);
        // Tratar a resposta aqui
        return response;
    } catch (error) {
        // Tratar o erro aqui
        console.error("Ocorreu um erro ao buscar os dados do participante:", error);
        throw error;
    }

}

// recupera dados do tipo de parcela
export async function getTypeInstallment(id: number | string, auth: AuthType) {
    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';
    const dataLocalStorage = getToken();

    const customConfig: any = {
        method: 'post',
        url: `/module/getTypeInstallment`,
        data: {
            id: id,
        },
        headers: {
            Authorization: `Bearer ${auth.api_token}`,
            'X-UUID-Assinante': uuid || lastUuid
        }
    };

    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
        .then((response: AxiosResponse<any>) => {
            return response;
        })
        .catch((error: AxiosError) => {
            // throw error; // Rejeita a Promise para propagar o erro

            const { response } = error;
            let statusResponse: any;
            if (response) {
                const { status, data } = response;
                statusResponse = status;
            }
            return {
                data: {
                    success: false,
                    data: [],
                    status: statusResponse,
                    message: 'Ocorreu um erro ao buscar condições de pagamentos'
                }
            }
        });
    return response;

}

// recupera dados do tipo de parcela
export async function getInstallment(id: number) {
    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';
    const dataLocalStorage = getToken();

    try {
        const response = await axios.get<IInstallment>(
            `${API_URL}/module/getInstallment`,
            {
                params: { id },
                headers: {
                    Authorization: `Bearer ${dataLocalStorage?.token}`,
                    'X-UUID-Assinante': uuid || lastUuid
                }

            }
        );
        // Tratar a resposta aqui
        return response;
    } catch (error) {
        // Tratar o erro aqui
        // console.log("Ocorreu um erro ao buscar os dados tipo de parcela:", error);
        throw error;
    }

}


// inseri um contrato
export async function insertContractAPI(form: IFormContract, auth: AuthType) {
    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';
    const dataLocalStorage = getToken();

    const customConfig: any = {
        method: 'post',
        url: `/module/insertContract`,
        data: {
            form: form,
        },
        headers: {
            Authorization: `Bearer ${auth.api_token}`,
            'X-UUID-Assinante': uuid || lastUuid
        }

    };
    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
        .then((response: AxiosResponse<any>) => {
            return response;
        })
        .catch((error: AxiosError) => {
            // throw error; // Rejeita a Promise para propagar o erro

            const { response } = error;
            let statusResponse: any;
            if (response) {
                const { status, data } = response;
                statusResponse = status;
            }
            return {
                data: {
                    success: false,
                    data: [],
                    status: statusResponse,
                    message: 'Ocorreu um erro ao tentar criar o contrato'
                }
            }
        });
    return response;

}


// editar um contrato
export async function editContractAPI(form: IFormContract, auth: AuthType) {
    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';
    const dataLocalStorage = getToken();

    const customConfig: any = {
        method: 'post',
        url: `/module/editContract`,
        data: {
            form,
        },
        headers: {
            Authorization: `Bearer ${auth.api_token}`,
            'X-UUID-Assinante': uuid || lastUuid
        }


    };
    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
        .then((response: AxiosResponse<any>) => {
            return response;
        })
        .catch((error: AxiosError) => {
            // throw error; // Rejeita a Promise para propagar o erro
            const { response } = error;
            let statusResponse: any;
            if (response) {
                const { status, data } = response;
                statusResponse = status;
            }
            return {
                data: {
                    success: false,
                    data: [],
                    status: statusResponse,
                    message: 'Ocorreu um erro ao tentar editar contrato'
                }
            }
        });
    return response;

}

// deleta uma ou mais contratos
export async function deleteContractAPI(ids: string[], auth: AuthType) {
    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';
    const dataLocalStorage = getToken();

    const customConfig: any = {
        method: 'post',
        url: `/module/deleteContract`,
        data: {
            ids,
        },
        headers: {
            Authorization: `Bearer ${auth.api_token}`,
            'X-UUID-Assinante': uuid || lastUuid
        }
    };

    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
        .then((response: AxiosResponse<any>) => {
            return response;
        })
        .catch((error: AxiosError) => {
            // throw error; // Rejeita a Promise para propagar o erro
            const { response } = error;
            let statusResponse: any;
            if (response) {
                const { status, data } = response;
                statusResponse = status;
            }
            return {
                data: {
                    success: false,
                    data: [],
                    status: statusResponse,
                    message: 'Ocorreu um erro ao tentar deletar contrato'
                }
            }
        });
    return response;
}

// altera dados da parcela em massa
export async function changeParcelInMassaApi(ids: string[], data: ChangeInMass[], auth: AuthType) {
    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';
    const dataLocalStorage = getToken();

    const customConfig: any = {
        method: 'post',
        url: `/module/changeParcelInMassa`,
        data: {
            ids,
            data
        },
        headers: {
            Authorization: `Bearer ${auth.api_token}`,
            'X-UUID-Assinante': uuid || lastUuid
        }
    };

    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
        .then((response: AxiosResponse<any>) => {
            return response;
        })
        .catch((error: AxiosError) => {
            // throw error; // Rejeita a Promise para propagar o erro
            const { response } = error;
            let statusResponse: any;
            if (response) {
                const { status, data } = response;
                statusResponse = status;
            }

            return {
                data: {
                    success: false,
                    data: [],
                    status: statusResponse,
                    message: 'Ocorreu um erro ao tentar atualizar parcelas em massa'
                }
            }
        });
    return response;

}

// altera contratos em massa
export async function changeContractInMassaApi(ids: string[], data: ChangeInMass[], auth: AuthType) {
    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';
    const dataLocalStorage = getToken();

    const customConfig: any = {
        method: 'post',
        url: `/module/changeContractInMassa`,
        data: {
            ids,
            data
        },
        headers: {
            Authorization: `Bearer ${auth.api_token}`,
            'X-UUID-Assinante': uuid || lastUuid
        }
    };

    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
        .then((response: AxiosResponse<any>) => {
            return response;
        })
        .catch((error: AxiosError) => {
            // throw error; // Rejeita a Promise para propagar o erro
            const { response } = error;
            let statusResponse: any;
            if (response) {
                const { status, data } = response;
                statusResponse = status;
            }

            return {
                data: {
                    success: false,
                    data: [],
                    status: statusResponse,
                    message: 'Ocorreu um erro ao tentar atualizar contratos em massa'
                }
            }
        });
    return response;

}

export const getContractByName = async (name: string, auth: AuthType) => {
    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';
    const dataLocalStorage = getToken();
    try {

        let headers = {};
        if (auth && auth.api_token) {
            headers = {
                Authorization: `Bearer ${auth.api_token}`,
                'X-UUID-Assinante': uuid || lastUuid
            };
        }

        const response = await axios.post<IResponse>(`${API_URL}/module/getContractByName`,
            { name },
            { headers }
        );

        return response;
    } catch (error) {
        throw error;
    }
}

// faz consulta em bsca do boleto
export async function queryBoleto(token_boleto: string, auth: AuthType) {
    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';
    const dataLocalStorage = getToken();
    try {

        let headers = {};
        if (auth && auth.api_token) {
            headers = {
                Authorization: `Bearer ${auth.api_token}`,
                'X-UUID-Assinante': uuid || lastUuid
            };
        }

        const response = await axios.post<IResponse>(`${API_URL}/module/queryBoleto`,
            { token_boleto },
            { headers }
        );

        return response;
    } catch (error) {
        throw error;
    }

}

export const exportExcel = async (form: any, url: string = 'exportExcelContract') => {
    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';
    const dataLocalStorage = getToken();
    const customConfig: any = {
        method: 'post',
        url: `/module/${url}`,
        data: {
            ...form
        },
        headers: {
            Authorization: `Bearer ${dataLocalStorage?.token}`,
            'X-UUID-Assinante': uuid || lastUuid
        }
    };

    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
        .then((response: AxiosResponse<any>) => {
            return response;
        })
        .catch((error: AxiosError) => {
            // throw error; // Rejeita a Promise para propagar o erro
            const { response } = error;
            let statusResponse: any;
            if (response) {
                const { status, data } = response;
                statusResponse = status;
            }

            return {
                data: {
                    success: false,
                    data: [],
                    status: statusResponse,
                    message: 'Ocorreu um erro ao tentar processar.'
                }
            }
        });
    return response;
}