
import { PageLink, PageTitle } from "../../../_metronic/layout/core"
import TabRouteHeaderPixQrCode from "../../../_metronic/partials/configurartion/pix/tabs/TabRouteHeaderPixQrCode"
import * as authHelper from './../../modules/auth/core/AuthHelpers'
const uuid = authHelper.getAuthUui() || '';
const lastUuid = authHelper.getAuthLastUui() || '';

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Configuração',
    path: `/configuration/access/${uuid || lastUuid}`,
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const PixWrapper = () => {
  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>QrCode - Pix</PageTitle>
      <TabRouteHeaderPixQrCode />
    </>

  )
}