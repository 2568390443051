import React, { createContext, useContext, ReactNode, memo, useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import useHashValidator from './UuidValidator';
// import usePermissionStore from '../../app/zustand/PermissionStore'; // Importando o store de permissões
import { useDispatch } from 'react-redux';
// import { fetchPermissions } from '../../app/redux/api/permission/permissionSlice';
import { AppDispatch, RootState } from '../../app/redux/store';
import { permissionsList, useFetchPermissionQuery } from '../../app/redux/api/permission/permissionList';
import { useSelector } from 'react-redux';
import { resetAllFilters, resetClientFilter, resetContractFilter, resetDashboardFilter, resetParcelFilter, resetPaymentsFilter, resetProductPlanFilter, resetUnitFilter, setProductFilter } from '../../app/redux/filter/filterSlice';

// Interface para os valores do contexto
interface HashValidatorContextType {
    isValid: boolean | null;
    isLoading: boolean;
    message: string;
    assinante: any | null;
    currentPath: string;
}

// Valores padrão para o contexto
const defaultContextValue: HashValidatorContextType = {
    isValid: null,
    isLoading: false,
    message: '',
    assinante: null,
    currentPath: '',
};

// Criando o contexto
const HashValidatorContext = createContext<HashValidatorContextType>(defaultContextValue);

// Hook personalizado para usar o contexto
export const useHashValidatorContext = () => useContext(HashValidatorContext);

// Provider que deve ser usado dentro das rotas da aplicação
const HashValidatorProviderComponent: React.FC<{ children: ReactNode }> = ({ children }) => {
    const location = useLocation();
    const [currentPath, setCurrentPath] = useState(location.pathname);
    const dispatch = useDispatch<AppDispatch>()

    // Obtendo o estado atual do filtro de produto do Redux
    const productFilter = useSelector((state: RootState) => state.filter.product);

    // Referência para controlar requisições em andamento
    const pendingRequests = useRef<Record<string, boolean>>({});

    // Hook do validador de hash (sem as funções reset e validate que você mencionou não existirem)
    const {
        isValid,
        isLoading,
        message,
        assinante,
        validateHash,
        refreshRoute
    } = useHashValidator();

    // Função para resetar todos os filtros, preservando apenas o produto se necessário
    const resetFiltersOnRouteChange = () => {
        // Primeiro, verificamos se existe um filtro de produto válido que deve ser preservado
        const shouldPreserveProduct =
            productFilter &&
            productFilter.id_product &&
            productFilter.id_product !== '0' &&
            productFilter.id_product !== '';

        // Salvamos o ID do produto antes de resetar tudo
        const productId = shouldPreserveProduct ? productFilter.id_product : '';

        // Resetamos todos os filtros
        // dispatch(resetAllFilters());
        // Resetamos filtros individualmente, exceto o de produto
        dispatch(resetDashboardFilter());
        dispatch(resetContractFilter());
        dispatch(resetPaymentsFilter());
        dispatch(resetParcelFilter());
        dispatch(resetProductPlanFilter());
        dispatch(resetUnitFilter());
        dispatch(resetDashboardFilter());
        dispatch(resetClientFilter());


        // Se tínhamos um ID de produto válido, restauramos apenas ele
        if (shouldPreserveProduct) {
            // console.log('Preservando filtro de produto:', productId);
            dispatch(setProductFilter({ id_product: productId }));
        }
    };

    // Função para validar o hash e buscar permissões
    const validate = async () => {
        const path = location.pathname;

        // Se já existe uma requisição em andamento para esta rota, não fazemos nada
        if (pendingRequests.current[path]) {
            // console.log(`Já existe uma requisição em andamento para ${path}`);
            return;
        }

        try {
            // Marcamos que há uma requisição em andamento
            pendingRequests.current[path] = true;

            // Primeiro validamos o hash/token
            // console.log(`Validando hash para rota ${path}`);

            // Use refreshRoute em vez de validateHash se estiver apenas atualizando a mesma página
            const hashResult = await refreshRoute();

            if (hashResult.isValid) {

                // Se o hash for válido, buscamos as permissões
                console.log('Hash válido, buscando permissões');
                const result = await dispatch(permissionsList.endpoints.fetchPermission.initiate(null, { forceRefetch: true }));
                if ('error' in result) {
                    console.error('Erro ao buscar permissões:', result.error);
                } else {
                    console.log('Permissões carregadas:');
                }
                // await dispatch(useFetchPermissionQuery());
                // fetchPermissions();
            } else {
                console.log('Hash inválido:', hashResult.message);
            }
        } catch (error) {
            console.error('Erro durante validação:', error);
        } finally {
            // Sempre removemos a marcação de requisição em andamento
            pendingRequests.current[path] = false;
        }
    };

    // Efeito para detectar mudanças de rota
    useEffect(() => {
        const newPath = location.pathname;

        // Se a rota mudou
        if (currentPath !== newPath) {
            // console.log(`Rota mudou: ${currentPath} -> ${newPath}`);
            setCurrentPath(newPath);

            resetFiltersOnRouteChange();

            // Não validamos (nova consulta de validacao) se já existe uma requisição em andamento para esta rota
            if (!pendingRequests.current[newPath]) {
                validate();
            } else {
                // console.log(`Ignorando validação para ${newPath}, requisição já em andamento`);
            }
        }
    }, [location.pathname]);

    // Criamos um objeto que contém os campos do contexto
    const contextValue: HashValidatorContextType = {
        isValid,
        isLoading,
        message,
        assinante,
        currentPath
    };

    return (
        <HashValidatorContext.Provider value={contextValue}>
            {children}
        </HashValidatorContext.Provider>
    );
};

// Memorizando o componente provider para evitar rerenderizações desnecessárias
export const HashValidatorProvider = memo(HashValidatorProviderComponent);