import { KTSVG } from "../../helpers";
import { SetStateAction, useEffect, useState } from "react";
import { editParcelAPI, insertParcelAPI } from "../../../app/request/parcel";

import getHeaderForTypeContract from "./HeaderTableContract";
import { TableOptions } from "../../../app/interface/I_Table";
import TableAdvanced from "../../layout/components/tables/TableAdvanced";
import { FormEditParcel } from "./modal/parcel/FormEditParcel";
import Swal from "sweetalert2";
import { useAuth } from "../../../app/modules/auth";
import { FormHistory, FormInvoice, FormValues } from "../../../app/interface/I_Parcel";
import { formatDateDefaultString, formatDateToBrString } from "../../helpers/functions/export/format";
import { optionsActionInMassa } from "../../../app/globals/OptionsGlobals";
import { Drawerfile } from "./invoice/Drawerfile";
import { DrawerHistoryParcel } from "../../../app/pages/contrato/DrawerHistoryParcel";
import { getInvoice } from "../../../app/request/invoice";
import { returMarkParcelPaid, returMarkParcelPaidAmigavel } from "../../helpers/functions/tables/General";
import { LoadAnimation } from "../../helpers/functions/swal/LoadAnimation";
import { recriarOnclick } from "../../helpers/functions/boleto/boleto";
// import { FormInsertParcel } from "./modal/parcel/FormInsertParcel";

interface Parcel {
    id: string;
    // outras propriedades da Parcel
}

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
    }
});

export const Installment = ({ id }: { id: number | undefined }) => {

    // const [editData, setEditData] = useState(null);
    const [tableData, setTableData] = useState<FormValues[]>([]);
    const [parcelData, setParcelData] = useState<{ id: string }[]>([]);
    const [isModalEditOpen, setIsModalEditOpen] = useState(false);
    const [isModalInsertOpen, setIsModalInsertOpen] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isDrawerHistoryOpen, setIsDrawerHistoryOpen] = useState(false);
    const [typeSave, setTypeSave] = useState('');
    const { auth, logout, setCurrentUser } = useAuth();

    /** estado de dados do dormualrio da parcela */
    const [formValues, setFormValues] = useState<FormValues>({
        id: 0,
        form_payment: 'Boleto',
        token_boleto: '',
        name_integration: '',
        number_parcel: '1',
        table: 'parcels',
        mark_parcel_paid: null,
        type_parcel: '',
        value: '0.0',
        value_paid: '0.0',
        due_date: '',
        payment_date: '',
        status: 'Pendente',
        is_recurring: '0',
        frequency: '1M'

    });

    /** estado de dados da nota fiscal */
    const [formInvoice, setFormInvoice] = useState<FormInvoice>({
        id: '',
        id_contract: '',
        id_parcel: '',
        id_client: '',
        invoice_number: 0,
        competencia: '',
        issuance_date: '',
        value: '',
        path: '',
        base64: '',
        file: null,
        created_at: ''
    });

    /** estado de dados da nota fiscal */
    const [formHistory, setFormHistory] = useState<FormHistory>({
        id_parent: '',
        id_contract: '',
        id_parcel: '',
        id_client: '',
        title: '',
        description: '',
        value_option: ''
    });

    interface Option {
        value: string;
        label: string;
    }


    /**
     * value: valor a atualizar no banco
     * field  o campo do banco que será atualizado
     * label: apenas texto de exibição no select
     */

    /** ao clicar no botão editar */
    const handleEdit = (row: any) => {

        setTypeSave('edit');

        setFormValues({
            id: row.id,
            table: 'parcels',
            mark_parcel_paid: returMarkParcelPaid(row.status),
            token_boleto: row.token_boleto || '',
            form_payment: row.form_payment,
            confirmation_send: row.confirmation_send,
            name_integration: !!row.name_integration ? row.name_integration : null,
            boleto: null,
            baixa: null,
            value: row.value,
            value_paid: row.value_paid,
            due_date: formatDateDefaultString(row.due_date),
            payment_date: formatDateDefaultString(row.payment_date),
            status: row.status.trim()

        });

        setIsModalEditOpen(true);
    };


    /** ao clicar inserir nota fiscal */
    const handleDrawer = async (row: any) => {

        clearFormInvoice();

        setFormInvoice((preveState) => ({
            ...preveState,
            id_contract: row.id_contract,
            id_parcel: row.id,
            id_client: row.id_client,

        }))

        if (auth && auth.api_token) {
            const response = await getInvoice(row.id, auth);

            if (response.data.success && response.data.data) {
                const formData: FormInvoice = {
                    id: response.data.data[0].id,
                    value: response.data.data[0].value,
                    issuance_date: response.data.data[0].issuance_date,
                    competencia: response.data.data[0].competencia || '',
                    id_contract: response.data.data[0].id_contract,
                    id_parcel: response.data.data[0].id_parcel,
                    id_client: response.data.data[0].id_client,
                    invoice_number: response.data.data[0].invoice_number,
                    path: response.data.data[0].path,
                    base64: response.data.data[0].base64,
                    file: null,
                    created_at: response.data.data[0].created_at,
                };

                setFormInvoice(formData);
            }
        }

        setIsDrawerOpen(true);
    };

    const clearFormInvoice = () => {

        setFormInvoice((prevState) => ({
            ...prevState,
            id: '',
            value: '',
            issuance_date: '',
            competencia: '',
            id_contract: '',
            id_parcel: '',
            id_client: '',
            invoice_number: '',
            path: '',
            base64: '',
            file: null,
            created_at: '',
        }));
    }

    /** ao clicar inserir comentario */
    const handleHistory = (row: any) => {
        setFormHistory({
            id_parent: '',
            id_contract: row.id_contract,
            id_parcel: row.id,
            id_client: row.id_client,
            title: '',
            description: '',
            value_option: ''
        });

        setIsDrawerHistoryOpen(true);
    };

    /** ao clicar no botão inserir */
    const handleInsert = () => {
        setTypeSave('insert');
        setFormValues({
            id: 0,
            table: 'parcels',
            form_payment: 'Boleto',
            number_parcel: '1',
            type_parcel: '',
            value: '0.00',
            due_date: '',
            status: 'Pendente',
            is_recurring: '0',
            frequency: '1M'
        });

        setIsModalInsertOpen(true);
    };


    /** modal editar */
    const closeModalEdit = () => {
        setIsModalEditOpen(false);
    };

    /** modal inserir */
    const closeModalInsert = () => {
        setIsModalInsertOpen(false);
    };

    /** drawer nota fiscal */
    const closeDrawer = () => {
        setIsDrawerOpen(false);
    };

    /** drawer do historico na parcela */
    const closeDrawerHistory = () => {
        setIsDrawerHistoryOpen(false);
    };

    const handleChange = (fieldName: keyof FormValues, value: string | null | number) => {

        setFormValues(prevFormValues => ({
            ...prevFormValues,
            [fieldName]: value,
            mark_parcel_paid: (fieldName === 'status') ? returMarkParcelPaidAmigavel(value?.toString() || null) : null
        }));

    };

    const confirmSaveForm = () => {

        let textAlert = 'Deseja salvar as mudanças feitas nesta parcela?';

        let isPassValidTypeParcel = !!formValues.type_parcel;

        if (typeSave == 'insert') {
            textAlert = 'Deseja salvar esta nova parcela?';
        } else {
            isPassValidTypeParcel = true;
        }

        if (!!formValues.due_date && isPassValidTypeParcel && !!formValues.value && formValues.value !== "R$ 0") {

            if (formValues.status === 'Recorrente' && formValues.is_recurring === "0") {
                Swal.fire({
                    icon: "info",
                    title: "Atenção!",
                    text: "Para que a situação seja marcada como 'Recorrente' o campo recorrência deve ser 'Sim'",
                    showCancelButton: false,
                    confirmButtonText: 'Ok, entendi!'
                });
            } else if (formValues.due_date !== "Data inválida") {
                Swal.fire({
                    icon: "info",
                    title: "Atenção!",
                    text: textAlert,
                    showCancelButton: true,
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Sim, Salvar!',
                    cancelButtonText: 'Cancelar'
                }).then((result) => {
                    if (result.isConfirmed) {
                        LoadAnimation('Estamos processando...');
                        saveForm();
                    }
                });
            } else {

                Swal.fire({
                    icon: "info",
                    title: "Atenção!",
                    text: "Selecione a data novamente.",
                    showCancelButton: false,
                    confirmButtonText: 'Ok!',
                });
            }
        } else {

            Swal.fire({
                icon: "info",
                title: "Atenção!",
                html: "Preencha todos os campos vazios e assinalados como 'obrigatório'.",
                showCancelButton: false,
                confirmButtonText: 'Ok, entendi!',
            });
        }
    }

    const saveForm = async () => {

        if (auth && auth.api_token) {
            let response;
            if (typeSave == 'edit') {
                if (!!formValues.value) {
                    if (formValues.value.toString().includes('R$')) {
                        var valorEmReais = formValues.value.toString().replace(/^R\$\s*/g, "");
                        valorEmReais = valorEmReais.replace(/\./g, "");
                        valorEmReais = valorEmReais.replace(",", ".");
                        var valorFloat = parseFloat(valorEmReais);
                        formValues.value = valorFloat;
                    }
                    // var valorEmReais = formValues.value.toString(); // Supondo que 'formValues' seja o objeto do formulário
                    // valorEmReais = valorEmReais.replace(/\./g, ""); // Remover os pontos separadores de milhares
                    // valorEmReais = valorEmReais.replace(",", ".");  // Substituir a vírgula pelo ponto como separador decimal
                    // valorEmReais = valorEmReais.replace(/^R\$\s*/g, "");  // remove R$
                    // var valorFloat = parseFloat(valorEmReais);


                    // formValues.value = valorFloat;
                }

                if (!!formValues.value_paid) {
                    var valorPagoEmReais = formValues.value_paid.toString(); // Supondo que 'formValues' seja o objeto do formulário
                    valorPagoEmReais = valorPagoEmReais.replace(/\./g, ""); // Remover os pontos separadores de milhares
                    valorPagoEmReais = valorPagoEmReais.replace(",", ".");  // Substituir a vírgula pelo ponto como separador decimal
                    valorPagoEmReais = valorPagoEmReais.replace(/^R\$\s*/g, "");  // remove R$
                    var valorPagoFloat = parseFloat(valorPagoEmReais);


                    formValues.value_paid = valorPagoFloat;
                }

                response = await editParcelAPI(formValues, auth);

            } else {

                response = await insertParcelAPI(formValues, id, auth);
            }

            if (response.data.success) {
                Toast.fire({
                    icon: 'success',
                    title: 'Parcela salva com sucesso'
                });

                formValues.due_date = formatDateToBrString(formValues.due_date);

                if (typeSave === 'insert') {
                    if (response.data.data !== null) {
                        formValues.id = response.data.data.toString();
                    }
                }

                if (!!response.data.data.link_boleto) {
                    // document.getElementById(`boleto_${response.data.data.toString()}`)
                    recriarOnclick(`boleto_${response.data.data.toString()}`, response.data.data.link_boleto)
                }

                setTableData([formValues]);
                setIsModalEditOpen(false);
                setIsModalInsertOpen(false);

            } else if (response.data.status && response.data.status !== 403) {
                Swal.fire({
                    icon: "info",
                    title: "Atenção",
                    html: `Não foi possível salvar, verifique a conexao com internet, <br> ou fale com um administrador do sistema.`,
                    showCancelButton: false,
                    confirmButtonText: 'Entendi',
                });
            } else {
                Swal.fire({
                    icon: "info",
                    title: "Atenção",
                    html: response.data.message,
                    showCancelButton: false,
                    confirmButtonText: 'Entendi',
                });
            }
        }
    }

    // Propopriedade para montar header e endopoint dinamico
    const optionsInstallment: TableOptions = {
        id: id,
        header: getHeaderForTypeContract('installment', handleEdit, handleDrawer, handleHistory),
        iInterface: 'installment',
        endPoint: `getInstallment`,
        methodEndPoint: 'post',
        title: 'Lista de Parcelas',
        classHeadTd: 'w-auto text-center',
        classTbody: 'fw-semibold text-gray-800',
        classtbodyTr: 'text-center',
        classTbodyTd: 'w-auto text-center',
        isHidePaginate: false,
        funDeleteInMass: 'deleteParcelInMassa',
        funChangeInMass: 'changeParcelInMassa',
        textDelete: 'Parcelas',
        isHideChekbox: false,
        verifyPermission: 'payment'
    };

    const handlerEditInvoice = (data: any) => {

    }

    return (
        <div className="card card-flush pt-3 mb-5 mb-xl-10">
            <TableAdvanced
                options={optionsInstallment}
                optionsActionInMassa={optionsActionInMassa}
                tableData={tableData}
            // handleInsert={handleInsert}
            />
            {isModalEditOpen &&
                <FormEditParcel
                    closeModal={closeModalEdit}
                    handlerSaveForm={confirmSaveForm}
                    formValues={formValues}
                    handleChange={handleChange}
                />
            }

            {isDrawerOpen &&
                <Drawerfile
                    closeDrawer={closeDrawer}
                    // handlerSaveForm={confirmSaveForm} 
                    formValues={formInvoice}
                // handleEdit={handlerEditInvoice}
                />
            }

            {isDrawerHistoryOpen &&
                <DrawerHistoryParcel
                    closeDrawer={closeDrawerHistory}
                    formValues={formHistory}
                />
            }


            {/* {isModalInsertOpen && 
                <FormInsertParcel 
                    closeModal={closeModalInsert} 
                    handlerSaveForm={confirmSaveForm} 
                    formValues={formValues}
                    handleChange={handleChange}
                />
            } */}

        </div>

    );
}