/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { SidebarMenuItem } from './SidebarMenuItem'
import * as authHelper from './../../../../../app/modules/auth/core/AuthHelpers';
// import { fetchPermissions } from '../../../../../app/redux/api/permission/permissionSlice'
import { useFetchPermissionQuery } from '../../../../../app/redux/api/permission/permissionList'
import { RouteMenu } from '../../../../../app/interface/I_Auth'

const SidebarMenuMain = () => {

    const uuid = authHelper.getAuthUui()
    const lastUuid = authHelper.getAuthLastUui()
    // const { uui } = useParams();

    const intl = useIntl()
    // const location = useLocation();

    // const dispatch = useDispatch();

    // const filterState: any = useSelector((state: any) => state.filter);

    // useEffect(() => {

    //     const newObject = Object.fromEntries(
    //         Object.entries(filterState).map(([key, value]) => [key, ''])
    //     );

    //     dispatch(setFilter(newObject));

    // }, [dispatch, filterState, location.pathname]);


    const { data, error, isLoading }: any = useFetchPermissionQuery(null);
    let routesMenu = [];
    if (data && data.allowedsRoutes) {
        routesMenu = data.allowedsRoutes;
    }

    return (
        <>
            {
                routesMenu && routesMenu.length > 0 && routesMenu.map((menu: RouteMenu, index: number) => {

                    return (
                        <div key={index} >
                            <SidebarMenuItem
                                to={`${menu.to}/${uuid || lastUuid}`}
                                icon={menu.icon}
                                title={menu.intl ? intl.formatMessage({ id: menu.intl }) : 'Menu Item'}
                                fontIcon='bi-app-indicator'

                            />
                        </div>)
                })
            }
            {/*  */}

        </>
    )
}

export { SidebarMenuMain }
