
import React, { MouseEventHandler, useCallback, useEffect, useRef, useState } from "react";
import { KTSVG } from "../../../../_metronic/helpers";
import Select from "react-select";
import { useAuth } from "../../../modules/auth";
import * as Yup from 'yup'
// import {schemaContract} from "./SchemaForm"; 
import { getAllProduct } from "../../../request/product";
import { useMemo } from 'react';
import Swal from "sweetalert2";
import { FormUnitProps, IFormUnit } from "../../../interface/I_Unit";
import { SchemaUnits } from "./SchemaUnits";
import { editUnitAPI } from "../../../request/unit";

export const FormUnit: React.FC<FormUnitProps> = ({
    formValues,
    closeModal,
    onFormSubmit
}) => {

    const { auth, logout, setCurrentUser } = useAuth();

    interface Option {
        value: string;
        label: string;
    }

    interface OptionSelected {
        id_product: string;
        id_unit: string;
    }

    const [optionsProduct, setOptionsProduct] = useState<Option[]>([]);

    const [formData, setFormData] = useState<IFormUnit>(formValues || {
        id: 0,
        id_product: 0,
        name: ''
    });

    const [formErrors, setFormErrors] = useState<{ [key: string | number]: string | number }>({

        id_product: '',
        name: ''
    });

    const [latId, setLastId] = useState<string | number>(0);
    const [runFormSubmit, setRunFormSubmit] = useState<boolean>(false);

    useEffect(() => {
        fetchProducts();
    }, []);

    useEffect(() => {
        if (formValues) {
            setFormData({
                id: formValues.id || 0,
                id_product: formValues.id_product || 0,
                name: formValues.name || '',
            });

            setLastId(formValues.id || 0);

        }

    }, [formValues]);

    useEffect(() => {
        if (formData.id !== 0 && runFormSubmit) {
            returnForm();
        }

    }, [formData]);

    const handleContractChange = useCallback((e: any) => {

        const { name, value } = e.target;

        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }, []);

    const handleSelectChange = <K extends keyof OptionSelected>(selectedOption: Option | null, fieldName: K) => {
        const value = selectedOption ? selectedOption.value : 0;
        setFormData(prevState => ({
            ...prevState,
            [fieldName]: value.toString(),
        }));
    };

    const fetchProducts = async () => {
        if (auth && auth.api_token) {
            const response = await getAllProduct(auth);

            if (response && response.data && Array.isArray(response.data)) {
                const newOptionsProduct = response.data.map((product: { id: any; name: any; }) => ({
                    value: product.id.toString(),
                    label: product.name,
                }));

                setOptionsProduct(newOptionsProduct);
            }
        }
    };

    function returnForm() {
        if (typeof onFormSubmit === 'function') {
            onFormSubmit(formData);
        }

        console.log('returnForm', formData)
    }


    // envia formulario
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {

            await SchemaUnits.validate(formData, { abortEarly: false });
            setFormErrors({});

            await Swal.fire({
                icon: "info",
                title: "Atenção!",
                text: "Deseja salvar esta unidade?",
                showCancelButton: true,
                confirmButtonText: 'Sim, salvar!',
                cancelButtonText: 'Não',
            }).then((result) => {

                if (result.isConfirmed) {
                    saveForm();
                }
            });

        } catch (errors) {

            if (errors instanceof Yup.ValidationError) {
                const validationErrors: { [key: string]: string } = {};
                errors.inner.forEach((error) => {
                    validationErrors[error.path as string] = error.message
                });
                setFormErrors(validationErrors);
            }
        }
    };

    const saveForm = async () => {

        const response = await editUnitAPI(formData);

        if (response.data.success) {
            Swal.fire({
                icon: "success",
                title: "Parabéns",
                html: response.data.message,
                showCancelButton: false,
                confirmButtonText: 'Ok',
            });

            if (response.data.data !== "0" && response.data.data !== 0 && response.data.data !== null && response.data.data !== "") {
                let id = response.data.data.toString();
                console.log('ultimo id', id)

                setFormData(prevState => ({
                    ...prevState,
                    id: id
                }));

                setLastId(id);

                setRunFormSubmit(true)

                // returnForm();
            }

        } else if (response.data.status && response.data.status !== 403) {
            Swal.fire({
                icon: "info",
                title: "Atenção",
                html: `Não foi possível salvar, verifique a conexao com internet, <br> ou fale com um administrador do sistema.`,
                showCancelButton: false,
                confirmButtonText: 'Entendi',
            });
        }
    }

    return (
        <div className={`modal fade bg-dark bg-opacity-75 ${!closeModal ? "" : "show"}`} id="modalEditContract" tabIndex={-1} aria-modal="true" role="dialog" style={{ "display": !closeModal ? "none" : 'block' }}>
            <div className="modal-dialog modal-xl p-5 p-lg-10" id="">
                <div className="modal-content rounded-4">
                    <div className="modal-header flex-stack border-0 px-10 pt-5 pb-0" id="">
                        <div id="" className="position-relative z-index-1"></div>

                        <div className="btn btn-sm btn-icon btn-active-color-primary me-n2 position-relative z-index-1" onClick={closeModal}>
                            <KTSVG className="svg-icon svg-icon-2x" path="/media/icons/duotune/general/gen034.svg" />
                        </div>
                    </div>

                    <div className="modal-body pt-0 pb-5 px-15 mt-n5" id="">
                        <div className="container-fluid">
                            <div className="d-block">
                                <div className="tab-content">
                                    <div className="pt-5 tab-pane fade active show" role="tabpanel">
                                        <form onSubmit={handleSubmit}>
                                            {/* Contrato */}
                                            <div className="separator d-flex flex-center">
                                                <span className="text-uppercase bg-body fs-7 fw-semibold text-muted px-3">Dados da Unidade</span>
                                            </div>
                                            <div className="pe-12 me-n12 mt-15" >
                                                <div className="row gy-5">

                                                    {/* produto */}
                                                    <div className="col-lg-4  fv-plugins-icon-container">
                                                        <label className='form-label fw-bold'>Produto</label>
                                                        <div className=''>
                                                            <Select
                                                                name="id_product"
                                                                className="w-auto"
                                                                placeholder="Selecione um Produto"
                                                                value={
                                                                    formData.id_product !== '0' &&
                                                                        formData.id_product !== '' &&
                                                                        formData.id_product !== 0
                                                                        ? {
                                                                            value: formData.id_product,
                                                                            label: optionsProduct.find(option => option.value === formData.id_product)?.label
                                                                        }
                                                                        : null
                                                                }

                                                                options={optionsProduct}
                                                                onChange={(selectedOption: any) => handleSelectChange(selectedOption as Option | null, "id_product")}
                                                            />
                                                            {formErrors.id_product && (
                                                                <div className="fv-plugins-message-container invalid-feedback">{formErrors.id_product}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* informações adicioanis */}
                                                    <div className="col-lg-8  fv-plugins-icon-container">
                                                        <label className='form-label fw-bold'>Nome da Unidade (Ex:. Quadra: 01 - Lote: 01)</label>
                                                        <div className=''>
                                                            <input
                                                                name="name"
                                                                className="form-control"
                                                                value={
                                                                    Array.isArray(formData.name) ? formData.name[1] : formData.name || ""
                                                                }
                                                                onChange={handleContractChange}

                                                            />
                                                            {formErrors.name && (
                                                                <div className="fv-plugins-message-container invalid-feedback">{formErrors.name}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="modal-footer border-0 mt-5">
                                                <button type="button" id="btn_close" className="btn btn-light" onClick={closeModal}>Fechar</button>
                                                <button type="submit" className="btn btn-success me-4">
                                                    {/* <button type="submit" className="btn btn-success me-4" onClick={handlerSaveForm}> */}
                                                    Salvar
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}