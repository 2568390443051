import { useEffect, useState } from "react";
import { IHeaderWithCell, IHeader } from "../../../app/interface";
import ComponentPermission from "../../../_metronic/layout/components/permissions/ComponentPermission";

export const HeaderTableCorretores = (type: string, handleEdit?: (row: any) => void): IHeaderWithCell[] => {

    switch (type) {
        case 'corretor':
            return [
                { Header: 'Corretor', accessor: 'name' },
                { Header: 'Cpf', accessor: 'cpf' },
                { Header: 'Telefone', accessor: 'phone' },
                { Header: 'Creci', accessor: 'creci' },
                { Header: 'Email', accessor: 'email' },
                {
                    Header: 'Açoes', accessor: (row: any) => {
                        return (
                            <>
                                {/*  EXIBIÇÂO DO BOTAO EDITAR */}
                                {

                                    handleEdit && typeof handleEdit === 'function' && (
                                        <ComponentPermission>
                                            <ComponentPermission.Edit>
                                                <button
                                                    className="btn btn-sm ms-2 fw-bold bg-body btn-color-gray-700 btn-active-color-primary"
                                                    onClick={() => handleEdit(row)}
                                                >
                                                    Editar
                                                </button>
                                            </ComponentPermission.Edit>
                                        </ComponentPermission>
                                    )
                                }
                            </>
                        )
                    }
                }
            ];

        default:
            return [
                { Header: 'Corretor', accessor: 'name' },
                { Header: 'Cpf', accessor: 'cpf' },
                { Header: 'Telefone', accessor: 'phone' },
                { Header: 'Creci', accessor: 'creci' },
                { Header: 'Email', accessor: 'email' },
                {
                    Header: 'Açoes', accessor: (row: any) => {
                        return (
                            <>
                                {/*  EXIBIÇÂO DO BOTAO EDITAR */}
                                {

                                    handleEdit && typeof handleEdit === 'function' && (
                                        <button
                                            className="btn btn-sm ms-2 fw-bold bg-body btn-color-gray-700 btn-active-color-primary"
                                            onClick={() => handleEdit(row)}
                                        >
                                            Editar
                                        </button>
                                    )
                                }
                            </>
                        )
                    }
                }
            ];
    }


};



export default HeaderTableCorretores