import { AuthUui, Tokens } from "../../../interface/I_Auth";

export const getToken = () => {
    const lsValue: string | null = localStorage.getItem('kt-auth-react-v');
    if (!lsValue) {
        return
    }

    try {
        const token: Tokens = JSON.parse(lsValue) as Tokens
        if (token) {
            return token
        }
    } catch (error) {
        console.error('TOKEN LOCAL STORAGE PARSE ERROR', error)
    }
}



// export const getUui = () => {
//     const lsUui: string | null = localStorage.getItem('auth-uui');
//     if (!lsUui) {
//         return
//     }

//     try {
//         const uui: AuthUui = JSON.parse(lsUui) as AuthUui
//         if (uui) {
//             return uui
//         }
//     } catch (error) {
//         console.error(' GET AUTH UUI LOCAL STORAGE PARSE ERROR', error)
//     }
// }