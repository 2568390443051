import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { TabHeaderUser } from "./TabHeaderUser";
import * as authHelper from './../../../app/modules/auth/core/AuthHelpers'
const uuid = authHelper.getAuthUui() || '';
const lastUuid = authHelper.getAuthLastUui() || '';


const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Usuários',
    path: `/users/access/${uuid || lastUuid}`,
    isSeparator: false,
    isActive: false,
  },

  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const UserWrapper = () => {
  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>Usuários</PageTitle>
      <TabHeaderUser />
    </>

  )
}

