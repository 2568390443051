import { KTSVG } from "../../../../_metronic/helpers";
import { useEffect, useState } from "react";

import HeaderTablePayment from "./HeaderTablePayment";
import { TableOptions } from "../../../interface/I_Table";
import TableAdvanced from "../../../../_metronic/layout/components/tables/TableAdvanced";
import { FormEditParcel } from "../../../../_metronic/partials/contract/modal/parcel/FormEditParcel";
import { FormHistory, FormNewBoleto, FormValues, ResponseNewBoleto } from "../../../interface/I_Parcel";
import { formatDateDefaultString, formatDateToBrString } from "../../../../_metronic/helpers/functions/export/format";
import Swal from "sweetalert2";
import { useAuth } from "../../../modules/auth";
import { editParcelAPI } from "../../../request/parcel";
import { optionsStatusParcel } from "../../../globals/OptionsGlobals";
import { downloadExcel, sendFilterdownloadExcel } from "../../../request/payment";
import axios from "axios";
import { useSelector } from "react-redux";
import { DrawerHistoryParcel } from "../../contrato/DrawerHistoryParcel";
import { returMarkParcelPaid } from "../../../../_metronic/helpers/functions/tables/General";
import { markParcelPaid } from "../../../Constants";
import { gerarNovoBoleto, getReajusteParcel } from "../../../../_metronic/helpers/functions/boleto/boleto";
import { FormDateValue } from "../../../../_metronic/partials/payments/parcel/modal/FormDateValue";


const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 20000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
    }
});

export const ListPayments = () => {

    const [isModalEditOpen, setIsModalEditOpen] = useState(false);
    const [isDrawerHistoryOpen, setIsDrawerHistoryOpen] = useState(false);
    const [isNewBoletoModalOpen, setIsNewBoletoModalOpen] = useState(false);
    const [tableData, setTableData] = useState<FormValues[]>([]);
    const { auth, currentUser, logout, setCurrentUser } = useAuth();


    const filterForm = useSelector((state: any) => state.filter);
    const filterGlobalProduct = useSelector((state: any) => state.productSlice);


    const [formValues, setFormValues] = useState<FormValues>({
        id: 0,
        form_payment: 'Boleto',
        token_boleto: '',
        name_integration: '',
        number_parcel: '1',
        table: 'parcels',
        mark_parcel_paid: null,
        type_parcel: '',
        value: '0.0',
        value_paid: '0.0',
        due_date: '',
        payment_date: '',
        status: 'Pendente',
        is_recurring: '0',
        frequency: '1M'
    });

    // const [formNewBoleto, setFormNewBoleto] = useState<FormNewBoleto>({
    //     id_parcel: '',
    //     id_boleto: '',
    //     id_contract: '',
    //     due_date: '',
    //     value: ''
    // });

    /** estado de dados da nota fiscal */
    const [formHistory, setFormHistory] = useState<FormHistory>({
        id_parent: '',
        id_contract: '',
        id_parcel: '',
        id_client: '',
        title: '',
        description: '',
        value_option: ''
    });

    /** modal editar */
    const closeModalEdit = () => {
        setIsModalEditOpen(false);
    };

    /** ao clicar no botão editar */
    const handleEdit = (row: any) => {

        setFormValues({
            id: row.id,
            table: 'parcels',
            mark_parcel_paid: returMarkParcelPaid(row.status.trim() || null),
            token_boleto: row.token_boleto || '',
            name_integration: row.name_integration || '',
            form_payment: row.form_payment,
            value: row.value,
            value_paid: row.value_paid,
            due_date: formatDateDefaultString(row.due_date),
            payment_date: formatDateDefaultString(row.payment_date),
            status: row.status.trim()
        });

        setIsModalEditOpen(true);
    };

    const handleChange = (fieldName: keyof FormValues, value: string | null | number) => {

        setFormValues(prevFormValues => ({
            // ...formValues,
            ...prevFormValues,
            [fieldName]: value,
            ['mark_parcel_paid']: (fieldName === 'status') ? returMarkParcelPaid(value?.toString() || null) : null
        }));
    };

    /** ao clicar inserir comentario */
    const handleHistory = (row: any) => {
        setFormHistory({
            id_parent: '',
            id_contract: row.id_contract,
            id_parcel: row.id,
            id_client: row.id_client,
            title: '',
            description: '',
            value_option: ''
        });

        setIsDrawerHistoryOpen(true);
    };

    const confirmSaveForm = () => {

        if (!!formValues.due_date && !!formValues.value && formValues.value !== "R$ 0") {

            if (formValues.due_date !== "Data inválida") {
                Swal.fire({
                    icon: "info",
                    title: "Atenção!",
                    text: 'Deseja salvar as mudanças feitas nesta parcela?',
                    showCancelButton: true,
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Sim, Salvar!',
                    cancelButtonText: 'Cancelar'
                }).then((result) => {
                    if (result.isConfirmed) {
                        saveForm();
                    }
                });
            } else {

                Swal.fire({
                    icon: "info",
                    title: "Atenção!",
                    text: "Selecione a data novamente.",
                    showCancelButton: false,
                    confirmButtonText: 'Ok!',
                });
            }
        } else {

            Swal.fire({
                icon: "info",
                title: "Atenção!",
                html: "Preencha todos os campos vazios e assinalados como 'obrigatório'.",
                showCancelButton: false,
                confirmButtonText: 'Ok, entendi!',
            });
        }
    }

    const saveForm = async () => {

        if (auth && auth.api_token) {
            let response;

            response = await editParcelAPI(formValues, auth);

            if (response.data.success) {
                Toast.fire({
                    icon: 'success',
                    html: 'Parcela salva com sucesso.'
                })

                formValues.due_date = formatDateToBrString(formValues.due_date);

                const newValueDataTable = [formValues].map((item: any) => {

                    let mark_parcel = item?.mark_parcel_paid;
                    item.mark_parcel_paid = markParcelPaid.find(item => item.value === mark_parcel)?.label || 'Pendente';

                    return item;
                });

                setTableData(newValueDataTable);
                setIsModalEditOpen(false);

            } else {

                if (!!response.data.message) {
                    Swal.fire({
                        icon: "info",
                        title: "Atenção",
                        html: response.data.message,
                        showCancelButton: false,
                        confirmButtonText: 'Entendi',
                    });

                } else {
                    Swal.fire({
                        icon: "info",
                        title: "Atenção",
                        html: `Não foi possível salvar, verifique a conexao com internte, <br> ou fale com um administrador do sistema.`,
                        showCancelButton: false,
                        confirmButtonText: 'Entendi',
                    });
                }

            }
        }
    }

    // relacionado a geração de novos boletos

    /** ao clicar no botão gerar novo boleto */
    const handleNewBoleto = async (row: any) => {
        // Exibe a mensagem de carregamento
        Swal.fire({
            title: "Carregando...",
            text: "Estamos buscando informações.. Aguarde!",
            icon: "info",
            allowOutsideClick: false,
            showConfirmButton: false, // Remove o botão de confirmação para parecer um loading
            didOpen: () => {
                Swal.showLoading(); // Mostra o ícone de loading
            }
        });

        try {
            const response: ResponseNewBoleto = await getReajusteParcel({ value: row.value, due_date: row.due_date });

            // Fecha o Swal de carregamento após a resposta
            Swal.close();

            setFormValues((prevFormValues: any) => ({
                ...prevFormValues,
                ...row,
                value: response?.data?.value || prevFormValues.value,
                due_date: response?.data?.due_date || prevFormValues.due_date
            }));

            setIsNewBoletoModalOpen(true);
        } catch (error) {
            // Fecha o Swal e exibe um erro se a requisição falhar
            Swal.fire({
                title: "Erro!",
                text: "Não foi possível buscar as informações. Tente novamente.",
                icon: "error",
                confirmButtonText: "OK"
            });
        }
    };


    const handleChangeNewBoleto = (fieldName: keyof FormValues, value: string | null | number) => {

        setFormValues(prevFormValues => ({
            ...prevFormValues,
            [fieldName]: value,
        }));
    };

    const confirmSaveNewBoleto = () => {

        if (!!formValues.due_date && !!formValues.value && formValues.value !== "R$ 0") {

            if (formValues.due_date !== "Data inválida") {
                Swal.fire({
                    icon: "info",
                    title: "Atenção!",
                    text: 'Deseja prosseguir com geração de um novo boleto?',
                    showCancelButton: true,
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Sim, Salvar!',
                    cancelButtonText: 'Cancelar'
                }).then((result) => {
                    if (result.isConfirmed) {
                        saveNewBoleto();
                    }
                });
            } else {

                Swal.fire({
                    icon: "info",
                    title: "Atenção!",
                    text: "Selecione a data novamente.",
                    showCancelButton: false,
                    confirmButtonText: 'Ok!',
                });
            }
        } else {

            Swal.fire({
                icon: "info",
                title: "Atenção!",
                html: "Preencha todos os campos vazios e assinalados como 'obrigatório'.",
                showCancelButton: false,
                confirmButtonText: 'Ok, entendi!',
            });
        }
    }

    const saveNewBoleto = async () => {

        if (auth && auth.api_token) {


            const response = await gerarNovoBoleto(formValues);

            const data = response.data as {
                link_boleto?: string;
                token_boleto?: string;
                our_number?: string;
                message?: string;
            };

            if (response?.success) {
                Toast.fire({
                    icon: 'success',
                    html: response?.message
                })

                formValues.due_date = formatDateToBrString(formValues.due_date);

                // const newValueDataTable = [formValues].map((item: any) => {
                //     item.value = formValues.value;
                //     item.due_date = formValues.due_date;

                //     return item;
                // });

                const newValueDataTable = [formValues].map((item: any) => {
                    return {
                        ...item,
                        value: formValues.value,
                        due_date: formValues.due_date,
                        status: '0',
                        link_boleto: data.link_boleto || '', // adicione o link do boleto se disponível
                        token_boleto: data?.token_boleto, // adicione o token do boleto se disponível
                        name_integration: formValues.name_integration || null,
                        our_number: data.our_number || null, // número do boleto, se disponível
                        mark_parcel_paid: 'Pendente', // status inicial da parcela
                        boleto_deleted_at: null // garantir que não esteja marcado como deletado
                    };
                });

                setTableData(newValueDataTable);
                setIsNewBoletoModalOpen(false);

            } else {

                if (!!response.message) {
                    Swal.fire({
                        icon: "info",
                        title: "Atenção",
                        html: response.message,
                        showCancelButton: false,
                        confirmButtonText: 'Entendi',
                    });

                } else {
                    Swal.fire({
                        icon: "info",
                        title: "Atenção",
                        html: `Não foi possível salvar, verifique a conexao com internet, <br> ou fale com um administrador do sistema.`,
                        showCancelButton: false,
                        confirmButtonText: 'Entendi',
                    });
                }

            }
        }
    }

    // Criar um formulário dinamicamente
    // const formulario = document.createElement('form');

    const download = async () => {

        if (auth && auth.api_token) {

            // teste
            // const url = `https://w2.zoome.digital/webhook-test/c63ed164-d0eb-44bd-a230-27a564258dd5`;

            // prucao
            const url = `https://webhook-w2.zoome.digital/webhook/c63ed164-d0eb-44bd-a230-27a564258dd5`;
            if (filterForm) {
                let FILTER: any = {}

                for (const chave in filterForm?.payments) {
                    if (filterForm?.payments.hasOwnProperty(chave)) {
                        if (!!filterForm?.payments[chave]) {
                            FILTER[chave] = filterForm?.payments[chave]
                        }
                    }
                }

                FILTER.id_product = filterGlobalProduct?.product?.id_product || '';

                Toast.fire({
                    icon: 'warning',
                    html: 'Estamos processando o seu download, por favor aguarde..'
                });

                const response = await downloadExcel(FILTER, auth, url);
                if (response && !!response) {
                    Toast.fire({
                        icon: 'info',
                        html: 'Fazendo download de seu arquivo, por favor aguarde..',
                        didOpen: () => {
                            const progressBar = document.querySelector('.swal2-timer-progress-bar') as HTMLElement | null;
                            if (progressBar) {
                                progressBar.style.transition = 'none';
                                progressBar.style.width = '0%';
                            }
                        }
                    });


                    const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    const blobURL = URL.createObjectURL(blob);

                    // Configura o nome do arquivo para o download
                    const fileName = 'Relatório de pagamentos.xlsx';

                    // Cria um elemento de âncora para iniciar o download
                    const link = document.createElement('a');
                    link.href = blobURL;
                    link.download = fileName;

                    // Adicione o elemento de âncora ao corpo do documento
                    document.body.appendChild(link);

                    // Simule o clique para iniciar o download
                    link.click();


                    setTimeout(function () { Swal.close(); }, 2000);

                    // Remove o elemento de âncora e libere o URL temporário quando o download estiver concluído
                    link.addEventListener('focus', function () {
                        document.body.removeChild(link);
                        URL.revokeObjectURL(blobURL);

                    });
                }

            } else {
                Swal.fire({
                    icon: "info",
                    title: "Atenção!",
                    text: "Não foi possivel fazer download, verifique sua sessão.",
                    showCancelButton: false,
                    confirmButtonText: 'Ok!',
                });
            }
        }
    }

    /** drawer do historico na parcela */
    const closeDrawerHistory = () => {
        setIsDrawerHistoryOpen(false);
    };

    /** drawer do historico na parcela */
    const closeModalNewBoleto = () => {
        setIsNewBoletoModalOpen(false);
    };


    // Propopriedade para montar header e  lass e endopoint dinamico
    const optionsInstallment: TableOptions = {
        header: HeaderTablePayment('listPayment', handleEdit, handleHistory, handleNewBoleto),
        iInterface: 'listPayment',
        endPoint: 'getListPayments',
        methodEndPoint: 'post',
        title: 'Lista de Parcelas',
        classTable: 'table align-middle table-row-dashed fs-6 gy-4 mb-0',
        classHeadTr: 'border-bottom border-gray-200 text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0',
        classHeadTd: 'w-auto text-center',
        classTbody: 'fw-semibold text-gray-800',
        classtbodyTr: '',
        classTbodyTd: 'w-auto text-center',
        classTbodyTdDiv: 'fw-normal text-gray-600',
        funChangeInMass: 'changeParcelInMassa',
        isHideChekbox: false,
        download: download,
    };

    return (
        <div className="card card-flush pt-3 mb-5 mb-xl-10">

            {/* <TablePayments
                proposTable={proposTable}
            /> */}

            <TableAdvanced
                options={optionsInstallment}
                optionsActionInMassa={optionsStatusParcel}
                tableData={tableData}

            />

            {isModalEditOpen &&
                <FormEditParcel
                    closeModal={closeModalEdit}
                    handlerSaveForm={confirmSaveForm}
                    formValues={formValues}
                    handleChange={handleChange}
                />
            }

            {isDrawerHistoryOpen &&
                <DrawerHistoryParcel
                    closeDrawer={closeDrawerHistory}
                    formValues={formHistory}
                />
            }

            {isNewBoletoModalOpen &&
                <FormDateValue
                    closeModal={closeModalNewBoleto}
                    handlerSaveForm={confirmSaveNewBoleto}
                    formValues={formValues}
                    handleChange={handleChangeNewBoleto}
                />
            }

        </div>

    );
}