/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { OverviewParcel } from '../../../_metronic/partials/payments/parcel/OverviewParcel'
import { StickyContract } from '../../../_metronic/partials/contract/StickyContract'
import TabRouteContract from '../../../_metronic/partials/contract/TabRouteContract'
import * as authHelper from './../../modules/auth/core/AuthHelpers';
const uuid = authHelper.getAuthUui() || '';
const lastUuid = authHelper.getAuthLastUui() || '';

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Pagamento',
    path: `/payments/access/${uuid || lastUuid}`,
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ParcelDetailsPage: FC<{ id: number | string | undefined }> = ({ id }) => (
  <>

    <div className="container-xxl ">
      <div className="d-flex flex-column flex-lg-row">
        <div className="flex-lg-row-fluid me-lg-15 order-2 order-lg-1 mb-10 mb-lg-0">
          <OverviewParcel id={id} />
        </div>
        {/* <StickyContract id={id} />  */}
      </div>
    </div>
  </>
)

const ParcelDetailsWrapper: FC = () => {
  const intl = useIntl()
  const { id } = useParams();

  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>Parcela</PageTitle>
      <ParcelDetailsPage id={id ? parseInt(id) : undefined} />
    </>
  )
}

export { ParcelDetailsWrapper }
