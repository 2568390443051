/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { TableOptions } from "../../interface/I_Table";
import TableAdvanced from '../../../_metronic/layout/components/tables/TableAdvanced'
import { FormInsertCorretor } from './modal/FormInsertCorretor';
import { FormEditCorretor } from './modal/FormEditCorretor';
import { ICorretor } from '../../interface/I_Corretor';
import HeaderTableCorretores from './HeaderTableCorretores';
import * as authHelper from './../../modules/auth/core/AuthHelpers';
const uuid = authHelper.getAuthUui() || '';
const lastUuid = authHelper.getAuthLastUui() || '';


const profileBreadCrumbs: Array<PageLink> = [
    {
        title: 'Home',
        path: `/dashboard/access/${uuid || lastUuid}`,
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]


const CorretorPage: FC = () => {

    const [isModalEditOpen, setIsModalEditOpen] = useState(false);
    const [isModalInsertOpen, setIsModalInsertOpen] = useState(false);
    const [tableData, setTableData] = useState<ICorretor[]>([]);

    const [formValues, setFormValues] = useState<ICorretor>({
        id: '0',
        name: '',
        rg: '',
        cpf: '',
        phone: '',
        email: '',
        creci: '',
        code_bank: '',
        bank: '',
        codigo_operation_bank: '',
        operation_bank: '',
        agency: '',
        account: '',
        account_digit: '',
        pix: '',
    });

    /** ao clicar no botão inserir */
    const handleInsert = () => {
        setIsModalInsertOpen(true);
    };

    /** ao clicar no botão editar */
    const handleEdit = (row: any) => {

        setFormValues({
            id: row.id || '',
            name: row.name || '',
            rg: row.rg || '',
            cpf: row.cpf || '',
            phone: row.phone || '',
            email: row.email || '',
            creci: row.creci || '',
            code_bank: row.code_bank || '',
            bank: row.bank || '',
            codigo_operation_bank: row.codigo_operation_bank || '',
            operation_bank: row.operation_bank || '',
            agency: row.agency || '',
            account: row.account || '',
            account_digit: row.account_digit || '',
            pix: row.pix || '',
        });

        setIsModalEditOpen(true);
    };

    /** modal inserir */
    const closeModalInsert = () => {
        setIsModalInsertOpen(false);
    };

    /** modal editar */
    const closeModalEdit = () => {
        setIsModalEditOpen(false);
    };

    const handlerCallbackFormSubmit = (formData: ICorretor) => {

        if (formData.hasOwnProperty('id') && formData.id !== "0" && formData.id !== null && formData.id !== "" && formData.name !== "") {
            const updatedClient = {
                ...formData
            };

            const updatedTableData = [updatedClient];

            setTableData(updatedTableData);

            setIsModalInsertOpen(false);
            setIsModalEditOpen(false);
        }
    }

    const optionsCorretor: TableOptions = {
        // id:id,
        header: HeaderTableCorretores('corretor', handleEdit),
        iInterface: 'corretor',
        endPoint: `tableCorretor`,
        methodEndPoint: 'post',
        title: 'Lista de Corretores',
        classHeadTd: 'min-w-150px',
        classTbody: 'fw-semibold text-gray-800',
        classtbodyTr: '',
        classTbodyTd: '',
        isHidePaginate: true,
        funDeleteInMass: 'deleteCorretorInMassa',
        // funChangeInMass: 'changeContractInMassa',
        textDelete: 'Corretores',
        // verifyPermission: 'corretor'
    };

    return (

        <div className="app-container  container-xxl ">
            <div className="d-flex flex-column flex-lg-row ms-n11">
                {/* <TableContract api={`tableContract`} type={`contrato`}/> */}
                {/* <TableSimple options={optionsContract} /> */}
                <TableAdvanced
                    options={optionsCorretor}
                    // optionsActionInMassa={optionsActionInMassa}
                    tableData={tableData}
                    handleInsert={handleInsert}
                />

                {isModalEditOpen &&
                    <FormEditCorretor
                        formValues={formValues}
                        closeModal={closeModalEdit}
                        onFormSubmit={handlerCallbackFormSubmit}
                    />
                }

                {isModalInsertOpen &&
                    <FormInsertCorretor
                        closeModal={closeModalInsert}
                        onFormSubmit={handlerCallbackFormSubmit}
                    // handlerSaveForm={confirmSaveForm} 
                    // formValues={formValues}
                    // handleChange={handleChange}
                    />
                }

            </div>
        </div>
    );
}

const ListCorretoresWrapper: FC = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Corretores</PageTitle>
            {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle> */}
            <CorretorPage />
        </>
    )
}

export { ListCorretoresWrapper }
