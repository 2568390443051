/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'

import TableAdvanced from '../../../_metronic/layout/components/tables/TableAdvanced'

import HeadertTableProduct from './HeadertTableProduct'
import { TableOptions } from '../../interface/I_Table'
import { InsertProduct } from '../../../_metronic/partials/product/modal/InsertProduct'
import { FormInsertProduct } from '../../interface/I_Product'
import * as authHelper from './../../modules/auth/core/AuthHelpers';
const uuid = authHelper.getAuthUui() || '';
const lastUuid = authHelper.getAuthLastUui() || '';


const API_URL = process.env.REACT_APP_API_URL;

const profileBreadCrumbs: Array<PageLink> = [
    {
        title: 'Lista',
        path: `/dashboard/access/${uuid || lastUuid}`,
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]


const ProductWrapper: FC = () => {

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [formValues, setFormValues] = useState<FormInsertProduct>({ name: '', product_plan: 'H' });

    const optionsUnit: TableOptions = {
        // id:id, // opcional
        header: HeadertTableProduct('product'),
        iInterface: 'IUnit',
        endPoint: `tableProduct`,
        methodEndPoint: 'post',
        title: 'Lista de Produtos',
        classHeadTd: 'min-w-150px',
        classTbody: 'fw-semibold text-gray-800',
        classtbodyTr: '',
        classTbodyTd: '',
        isHidePaginate: true,
        funDeleteInMass: 'deleteProductInMassa',
        // funChangeInMass: 'changeProductInMassa', // TODO
        textDelete: 'Produtos',
        // verifyPermission: 'product'
    };

    const handleInsert = () => {
        setIsOpenModal(true);
    }

    const handlerCloseModal = () => {
        setIsOpenModal(false);
    }

    const handlerCallbackFormSubmit = (formData: FormInsertProduct) => {
        setIsOpenModal(false);
    }

    return (
        <>
            <div className="app-container  container-xxl ">
                <div className="d-flex flex-column flex-lg-row ms-n11">
                    {/* <TableContract api={`tableLote`} type={`lote`}/> */}
                    <TableAdvanced
                        options={optionsUnit}
                        // tableData={tableData}                            
                        handleInsert={handleInsert}
                    />

                </div>
                {
                    isOpenModal && (

                        <InsertProduct
                            formValues={formValues}
                            closeModal={handlerCloseModal}
                            onFormSubmit={handlerCallbackFormSubmit}

                        />
                    )
                }
            </div>
        </>
    )
}

export { ProductWrapper }
