import React, { useContext, useEffect, useState } from 'react';
import Select from "react-select";
import "moment/locale/pt-br";
import { useDispatch } from 'react-redux';
import { resetProductPlanFilter, setFilter, setProductFilter, setProductPlanFilter } from '../../../../app/redux/filter/filterSlice';
import { useFetchListProductQuery } from '../../../../app/redux/api/product/listProduct';
import { useSelector } from 'react-redux';
import { KTSVG } from '../../../helpers';

const FormFilterProduct: React.FC = () => {

    const dispatch = useDispatch();

    const [formData, setFormData] = useState(
        {
            product_plan: {
                type_plan: ''
            }
        }
    );

    const optionsTypePlan: Option[] = [
        { value: "H", label: "Terreno" },
        { value: "V", label: "Predio" }
    ];

    interface Option {
        value: string;
        label: string;
    }

    const handleSelectChange = (value: string, fieldName: string) => {

        setFormData((prevState) => ({
            ...prevState,
            product_plan: {
                ...prevState.product_plan,
                type_plan: value
            },
        }));
    };


    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault();

        // Despachar a ação setFilter com os dados do filtro
        if (formData.product_plan.type_plan) {
            dispatch(setProductPlanFilter(formData.product_plan));
        }
    }

    const resetFilterForm = () => {

        setFormData({
            product_plan: {
                type_plan: ''
            }
        });

        // Despachar a ação setFilter com os dados do filtro
        dispatch(resetProductPlanFilter());
    }

    return (

        <form id="form_filter_contract" onSubmit={handleSubmit}>
            <div className="d-flex flex-column align-items align-items-start">
                <div className="d-flex align-items-center mb-2"></div>
                <div className='mb-10 row'>
                    {/* <div className="col-md-6">
                            <label className='form-label fw-bold'>Produto</label>
                            <div className='d-flex'>
                                <Select
                                    styles={{ container: (base:any) => ({ ...base, zIndex: 99 }) }}
                                    className="w-450px"
                                    placeholder="Selecione um produto"
                                    value={selectedProduto !== '0' && selectedProduto !== '' ? { value: selectedProduto, label: optionsProduct.find(option => option.value === selectedProduto)?.label } : null}
                                    
                                    onChange={(e:any) => handleSelectChange(e?.value || '', 'id_product')}
                                    options={optionsProduct}
                                />
                            </div>
                        </div> */}
                    <div className="col-md-12">
                        <label className='form-label fw-bold'>Tipo</label>
                        <div className='d-flex'>
                            <Select
                                styles={{ container: (base: any) => ({ ...base, zIndex: 99 }) }}
                                className="w-450px"
                                placeholder="Selecione um tipo"
                                value={formData.product_plan.type_plan !== '0' && formData.product_plan.type_plan !== '' ? { value: formData.product_plan.type_plan, label: optionsTypePlan.find(option => option.value === formData.product_plan.type_plan)?.label } : null}

                                onChange={(e: any) => handleSelectChange(e?.value || '', 'type_plan')}
                                options={optionsTypePlan}
                            />
                        </div>
                    </div>
                </div>

            </div>
            <div className="card-footer pt-4" id="kt_drawer_filter_footer">
                <div className='d-flex justify-content-center'>
                    <button
                        type='reset'
                        className='btn btn-sm btn-light btn-active-light-primary me-2 btn_reset_filter_all'
                        data-kt-menu-dismiss='true'
                        onClick={() => resetFilterForm()}
                    >
                        Resetar filtro
                    </button>

                    <button type="submit" data-kt-indicator='off' className="btn btn-sm btn-primary me-3 btn_filter_all" data-kt-menu-dismiss='true' data-kt-stepper-action="submit">
                        <span className="indicator-label">
                            Filtrar
                            <KTSVG className="svg-icon svg-icon-2" path="/media/icons/duotune/general/gen031.svg" />
                        </span>
                        <span className="indicator-progress">
                            Carregando... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                </div>
            </div>
        </form>
    );
};

export default FormFilterProduct;
