import axios, { AxiosInstance, AxiosRequestConfig, AxiosError, AxiosResponse, CancelToken, CancelTokenSource } from 'axios';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getToken } from '../../../../app/redux/api/auth/getToken';

import * as authHelper from '../../../../app/modules/auth/core/AuthHelpers'

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
    }
});

interface ExtendedAxiosRequestConfig extends AxiosRequestConfig {
    cancelled?: boolean;
    isFormFileData?: boolean;
}

// Mapa para armazenar requisições pendentes
const pendingRequests = new Map<string, CancelTokenSource>();

// Função para gerar uma chave única para cada requisição
const generateRequestKey = (config: ExtendedAxiosRequestConfig): string => {
    const { method, url, params, data } = config;
    return `${method}-${url}-${JSON.stringify(params || {})}-${JSON.stringify(data || {})}`;
};

// Função para cancelar requisições duplicadas
const cancelPendingRequests = (key: string): void => {
    if (pendingRequests.has(key)) {
        const source = pendingRequests.get(key);
        if (source) {
            source.cancel('Requisição duplicada cancelada');
        }
        pendingRequests.delete(key);
    }
};


const BaseServiceHttp = <T = any>(configRequest?: AxiosRequestConfig): AxiosInstance => {
    const dataLocalStorage = getToken();
    const uuid = authHelper.getAuthUui() || '';
    const lastUuid = authHelper.getAuthLastUui() || '';

    // console.log('BaseServiceHttp ', dataLocalStorage, localStorage)
    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            'Authorization': `Bearer ${dataLocalStorage?.token}`,
            'X-UUID-Assinante': uuid || lastUuid,
            'Content-Type': 'application/json',
        },
    });

    // Interceptor de requisição
    axiosInstance.interceptors.request.use(
        (request: ExtendedAxiosRequestConfig) => {
            // Garantir que 'headers' seja definido
            request.headers = request.headers || {};

            if (uuid) {
                request.headers['X-UUID-Assinante'] = uuid ? String(uuid) : '';
            } else {
                console.warn('UUID não encontrado para o cabeçalho.');
            }

            // Verificar se a solicitação foi cancelada e interromper a cadeia de promessas
            if (request.cancelled) {
                return Promise.reject(new axios.Cancel('Requisição cancelada devido a falta de permissão! status 403'));
            }

            if (request.isFormFileData) {
                // Configurar cabeçalho para envio de arquivos
                request.headers['Content-Type'] = 'multipart/form-data';
            }

            // Prevenção de requisições duplicadas
            const requestKey = generateRequestKey(request);

            // Cancelar requisição pendente com a mesma chave
            cancelPendingRequests(requestKey);

            // Criar um novo token de cancelamento para esta requisição
            const source = axios.CancelToken.source();
            request.cancelToken = source.token;

            // Armazenar o token de cancelamento para possível uso futuro
            pendingRequests.set(requestKey, source);

            return request;
        },
        (error: AxiosError) => {
            console.error('Erro na requisição:', error);
            return Promise.reject(error);
        }
    );

    // Interceptor de resposta
    axiosInstance.interceptors.response.use(
        (response: AxiosResponse) => {
            // Remover a requisição do mapa de pendentes quando concluída
            const requestKey = generateRequestKey(response.config as ExtendedAxiosRequestConfig);
            pendingRequests.delete(requestKey);

            return response;
        },
        (error: any) => {
            // Remover a requisição do mapa mesmo em caso de erro
            if (error.config) {
                const requestKey = generateRequestKey(error.config as ExtendedAxiosRequestConfig);
                pendingRequests.delete(requestKey);
            }

            // Se for um erro de cancelamento, não exibir mensagem de erro
            if (axios.isCancel(error)) {
                console.log('Requisição cancelada:', error.message);
                return Promise.reject(error);
            }

            const { response } = error;

            if (response) {
                const { status, data } = response;

                if (status === 401) {
                    window.location.href = '/auth';
                } else if (status === 403) {
                    if (data.type === 'message') {
                        Toast.fire({
                            icon: 'error',
                            title: data.messages.error,
                        });
                    } else if (data.type === 'redirect') {
                        window.location.href = '/permission';
                    }

                    response.config.cancelled = true;
                    return Promise.reject(error);
                } else {
                    console.error('Erro status:', status);
                }
            } else if (error.request) {
                console.error('Sem resposta recebida:', error.request);
            } else {
                console.error('Erro durante a configuração da requisição:', error.message);

                if (error.message.includes('CORS')) {
                    console.error('Erro de CORS:', error.message);
                }
            }

            return Promise.reject(error);
        }
    );

    // Aplicar configurações personalizadas, se fornecidas
    if (configRequest) {
        Object.assign(axiosInstance.defaults, configRequest);
    }

    return axiosInstance;
};

export default BaseServiceHttp;