import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IFilterGLobals } from '../../../interface/I_Filter';
import { IResponse } from '../../../interface/I_General';
import { getToken } from '../auth/getToken';

import * as authHelper from './../../../modules/auth/core/AuthHelpers';

const API_URL = process.env.REACT_APP_API_URL;

const uuid = authHelper.getAuthUui();

export const reportDashboard = createApi({
    reducerPath: 'reportDashboard',
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL,
        prepareHeaders: (headers) => {
            const dataLocalStorage = getToken();
            if (dataLocalStorage?.token) {
                headers.set('Authorization', `Bearer ${dataLocalStorage?.token}`);
            }

            if (uuid) {
                headers.set('X-UUID-Assinante', String(uuid));
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        fetchReportDasboard: builder.query<IResponse, IFilterGLobals>({
            query: (params) => ({
                url: `/module/reportDashboard`,
                method: 'POST',
                body: params,
            }),
        }),
    }),
});


export const { useFetchReportDasboardQuery, useLazyFetchReportDasboardQuery } = reportDashboard