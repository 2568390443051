import { create } from 'zustand';
import { permissionsUser } from '../request/permission';
import { I_Empresa } from '../interface/I_Empresa';

// Define route menu interface
export interface I_RouteMenu {
    to: string;
    allowedTo: string;
    title: string;
    icon: string;
    intl: string;
}

// Define store state interface
interface PermissionState {
    // Permission flags
    permissions: {
        access: boolean;
        list: boolean;
        create: boolean;
        edit: boolean;
        delete: boolean;
        import: boolean;
        export: boolean;
        dev: boolean;
    };

    // User data
    banks: string[];
    permissionsList: string[];
    routesMenu: I_RouteMenu[];
    roles: string;
    currentRoute: string;
    empresa: I_Empresa | undefined;

    // Actions
    setPermissionFlag: (key: string, value: boolean) => void;
    setBanks: (banks: string[]) => void;
    setPermissionsList: (permissions: string[]) => void;
    setRoutesMenu: (routesMenu: I_RouteMenu[]) => void;
    setRoles: (roles: string) => void;
    setEmpresaAssinante: (empresa: I_Empresa) => void;
    setCurrentRoute: (route: string) => void;

    // Complex actions
    fetchAndUpdatePermissions: () => Promise<void>;
    navigateTo: (route: string, fetchPermissions?: boolean) => Promise<void>;
}

const usePermissionStore = create<PermissionState>((set, get) => ({
    // Initial permission states
    permissions: {
        access: false,
        list: false,
        create: false,
        edit: false,
        delete: false,
        import: false,
        export: false,
        dev: false,
    },

    // Initial data states
    banks: [],
    permissionsList: [],
    routesMenu: [],
    roles: '',
    currentRoute: 'dashboard',
    empresa: undefined,

    // Simple setters
    setPermissionFlag: (key, value) => set(state => ({
        permissions: { ...state.permissions, [key]: value }
    })),

    setBanks: (banks) => set({ banks }),

    setPermissionsList: (permissions) => set({ permissionsList: permissions }),

    setRoutesMenu: (routesMenu) => set({ routesMenu }),

    setRoles: (roles) => set({ roles }),

    setEmpresaAssinante: (empresa) => set({ empresa }),

    setCurrentRoute: (route) => set({ currentRoute: route }),

    // Complex actions
    fetchAndUpdatePermissions: async () => {
        try {
            const response = await permissionsUser();
            console.log("Resposta bruta:", response); // Debug
            let count = 0;

            // Extract data with fallbacks
            const permissions = response.data.allowedsPermission || [];
            const routesMenu = response.data.allowedsRoutes || [];
            const roles = response.data.roles || '';

            // Se todos os dados estiverem vazios, tente novamente após um delay
            if (permissions.length === 0 && routesMenu.length === 0 && roles === '') {
                console.log("Dados vazios, tentando novamente em 1.5 segundos...");
                if (count <= 5) {

                    return new Promise((resolve) => {
                        setTimeout(async () => {
                            try {
                                const retryResponse = await permissionsUser();

                                const retryPermissions = retryResponse.data.allowedsPermission || [];
                                const retryRoutesMenu = retryResponse.data.allowedsRoutes || [];
                                const retryRoles = retryResponse.data.roles || '';

                                set({
                                    permissionsList: retryPermissions,
                                    routesMenu: retryRoutesMenu,
                                    roles: retryRoles
                                });
                                count++;
                                console.log("Retry successful:", retryPermissions, retryRoutesMenu, retryRoles);
                                resolve();
                            } catch (retryError) {
                                console.error("Error in retry:", retryError);
                                throw retryError;
                            }
                        }, 500);
                    });
                }
            }

            // Update store with fetched data
            set({
                permissionsList: permissions,
                routesMenu: routesMenu,
                roles: roles
            });

            // console.log("Permissions updated successfully", permissions, routesMenu, roles);
            // return response;
        } catch (error) {
            console.error("Error updating permissions:", error);
            throw error;
        }
    },

    navigateTo: async (route, fetchPermissions = false) => {
        try {
            // Optionally fetch permissions before navigation
            if (fetchPermissions) {
                await get().fetchAndUpdatePermissions();
            }

            // Update current route
            set({ currentRoute: route || 'dashboard' });
            return;
        } catch (error) {
            console.error('Error during navigation:', error);
            throw error;
        }
    }
}));

export default usePermissionStore;