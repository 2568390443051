/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react'
import { Routes, Route, BrowserRouter, Navigate, useParams } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../modules/auth'
import { App } from '../App'
import { DocumentationWrapper } from '../pages/suport/DocumentationWrapper'
import { HashValidatorProvider } from '../../_metronic/layout/HashMonitorProvider'
// import * as authHelper from './../modules/auth/core/AuthHelpers'
// import { HashValidatorProvider } from '../../_metronic/layout/HashMonitorProvider'
// import useUuiValidator from '../../_metronic/layout/UuidValidator'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const { currentUser } = useAuth();

  const hash = window.location.pathname.split('/');
  const uuid = hash[hash.length - 1];

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <HashValidatorProvider>
        <Routes>
          <Route element={<App />}>
            <Route path='error/*' element={<ErrorsPage />} />
            <Route path='logout/:uui/*' element={<Logout />} />
            {currentUser ? (
              <>
                <Route path='/*' element={<PrivateRoutes />} />
                <Route path='documentation/*' element={<DocumentationWrapper />} />
                <Route index element={<Navigate to={`/dashboard/access/${uuid}`} />} />
              </>
            ) : (
              <>
                {/* Página de autenticação */}
                {/* {uui ? (
                <Route path={`auth/${uui}/*`} element={<AuthPage />} />
              ) : (
                <Route path="auth/:uui/*" element={<AuthPage />} />
              )} */}

                <Route path="auth/:uui/*" element={<AuthPage />} />
                {/* Redireciona para a página de autenticação com UUID ou sem */}
                <Route path="*" element={<Navigate to={uuid ? `/auth/${uuid}` : '/auth'} />} />
                {/* <Route path="auth/:uui/*" element={<AuthPage />} />
              <Route path="auth" element={<AuthPage />} />
              <Route path="*" element={<Navigate to="/auth" />} /> */}
              </>
            )}
          </Route>
        </Routes>
      </HashValidatorProvider>
    </BrowserRouter>
  )
}

export { AppRoutes }
