import Uppy from "@uppy/core";
import { Dashboard, DashboardModal } from "@uppy/react";
// import AwsS3Multipart from "@uppy/aws-s3-multipart";
import XHRUpload from "@uppy/xhr-upload";
import ImageEditor from "@uppy/image-editor";
import ScreenCapture from "@uppy/screen-capture";
import Webcam from "@uppy/webcam";
import Compressor from "@uppy/compressor";
// import Tus from '@uppy/tus';
import { pt_BR } from "../../../../i18n/uppy/pt_BR";
import { Upload } from "../../../../../app/interface/I_Files";
import { getAuth } from '../../../../../app/modules/auth/core/AuthHelpers';
import Swal from "sweetalert2";

// Não se esqueça do CSS: núcleo e dos componentes de interface do usuário + plug-ins que você está usando.
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/image-editor/dist/style.min.css';
import '@uppy/screen-capture/dist/style.min.css';
import '@uppy/webcam/dist/style.min.css';
import axios from "axios";
import * as authHelper from './../../../../../app/modules/auth/core/AuthHelpers';
import { getToken } from "../../../../../app/redux/api/auth/getToken";

const uuid = authHelper.getAuthUui();
const dataLocalStorage = getToken();
const lastUuid = authHelper.getAuthLastUui() || '';


export const Importacao: React.FC<{ propsFiles: Upload, onFile?: any }> = ({ propsFiles, onFile }) => {

    const API_URL_UPLOAD = `${process.env.REACT_APP_API_URL}/module/${propsFiles.endPoint}`
    const API_URL_DELETE = `${process.env.REACT_APP_API_URL}/module/${propsFiles.urlDelete}`

    const uppy = new Uppy({
        debug: propsFiles.isDebug,
        id: propsFiles?.idUppy || 'importacao',
        autoProceed: propsFiles.isAutoProceed,
        allowMultipleUploads: propsFiles.isAllowMultipleUploads,
        restrictions: {
            maxFileSize: 50 * 1024 * 1024
        },

    });

    uppy.setOptions({
        locale: {
            strings: pt_BR
        },
        restrictions: {
            allowedFileTypes: propsFiles.fileTypes,
        },
    });

    uppy.use(XHRUpload, {
        endpoint: API_URL_UPLOAD, limit: propsFiles?.limit ?? 1,
        fieldName: 'upload',
        headers: {
            'Authorization': `Bearer ${getAuth()?.api_token}`,
            'X-UUID-Assinante': uuid || lastUuid
        },
        formData: true,

    });

    if (propsFiles.plugins) {
        if (propsFiles.plugins.includes('ImageEditor')) {
            uppy.use(ImageEditor, {
                id: propsFiles?.idImageEditor || 'ImageEditor',
                quality: 0.8,
            });
        }

        if (propsFiles.plugins.includes('ScreenCapture')) {
            uppy.use(ScreenCapture, {
                id: propsFiles?.idScreenCapture || 'ScreenCapture'
            }).use(Compressor);;
        }


        if (propsFiles.plugins.includes('Webcam')) {
            uppy.use(Webcam, {
                id: propsFiles?.idWebcam || 'Webcam',
                showVideoSourceDropdown: true,
                showRecordingLength: true
            });
        }

        // processa os arquivos para adicioanr ao carregar o uppy e adicona metada dados com id para recuperar depois
        if (propsFiles.filesData) {
            propsFiles.filesData.map((file: any, indexFiles: number) => {

                let typeFetch: any = '';
                if (file.type.startsWith('image/')) {
                    typeFetch = `data:image/jpeg;base64,${file.content}`;
                } else if (file.type === 'application/pdf') {
                    typeFetch = `data:application/pdf;base64,${file.content}`;
                    console.log('Processing PDF:', file);
                }

                if (typeFetch === '') return;

                fetch(typeFetch)
                    .then((response) => response.blob())
                    .then((blob) => {

                        uppy.addFile({
                            id: file.id,
                            name: file.name,
                            type: file.type,
                            data: blob,

                        });

                        uppy.getFiles().forEach((setFile, indexFile) => {
                            if (indexFiles === indexFile) {
                                uppy.setFileMeta(setFile.id, { id: file.id })
                            }

                            uppy.setFileState(setFile.id, {
                                progress: { uploadComplete: true, uploadStarted: true },
                            })
                        })
                    });
            });
        }
    }

    uppy.on('upload-success', (fileId, response: any): any => {

        if (propsFiles.isMessageReturn) {
            // Swal.fire({
            //     icon: response.body?.response?.messages?.icon || 'info',
            //     title: response.body?.response?.messages?.title,
            //     text: response.body?.response?.messages?.text
            // });


            onFile(response);
        }

    });

    uppy.on('file-removed', (file, reason) => {
        if (reason === 'removed-by-user') {
            deleteFile(file);
        }
    });

    // deleta o arquivo do servidor 
    async function deleteFile(file: any) {

        try {
            if (propsFiles.urlDelete) {
                await axios.delete(`${API_URL_DELETE}/${file.meta.id}`);
                uppy.removeFile(file.meta.id);
                console.log('delete ', file.meta.id)
                onFile(true)
            }
        } catch (e) {
            console.log(e);
            return;
        }
    }

    uppy.on('file-added', (file) => {

        uppy.setFileMeta(file.id, {
            id_parcel: propsFiles?.form?.id_parcel || null,
            id_contract: propsFiles?.form?.id_contract || null,
            id_client: propsFiles?.form?.id_client || null
        });
    });

    return (
        <Dashboard
            className="z-index-1"
            uppy={uppy}
            plugins={propsFiles.plugins}
            proudlyDisplayPoweredByUppy={false}
            showProgressDetails={propsFiles.isShowProgressDetails}
            hideUploadButton={propsFiles.isHideUploadButton}
            target="body"
            width={propsFiles.width}
            height={propsFiles.height}
            showRemoveButtonAfterComplete={true}
            metaFields={[
                { id: 'name', name: 'rename', placeholder: 'Renomear arquivo' },
                { id: 'caption', name: 'description', placeholder: 'Descreva do que se trata o arquivo' }
            ]}
        />
    )
}

