import { Link, useLocation } from "react-router-dom"
import * as authHelper from './../../../app/modules/auth/core/AuthHelpers';

export const TabHeaderContract = ({ id }: { id: number | undefined }) => {

    const location = useLocation();
    const uuid = authHelper.getAuthUui();

    return (
        <div className="d-flex flex-column gap-7 gap-lg-10 mb-7">
            <div className="d-flex flex-wrap flex-stack gap-5 gap-lg-10">
                <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-lg-n2 me-auto" role="tablist">
                    <li className="nav-item" role="presentation">
                        <Link
                            className={
                                `nav-link text-active-primary pb-4 me-6 ` +
                                (location.pathname === `/contract/details/${id}/overview/${uuid}` && 'active')

                            }

                            to={`/contract/details/${id}/overview/${uuid}`}
                        >
                            Visão geral
                        </Link>
                        {/* <a className="nav-link text-active-primary pb-4 active" data-bs-toggle="tab" href="#kt_ecommerce_sales_order_summary" aria-selected="true" role="tab">Order Summary</a> */}
                    </li>

                    <li className="nav-item" role="presentation">
                        <Link
                            className={
                                `nav-link text-active-primary pb-4 me-6 ` +
                                (location.pathname === `/contract/details/${id}/installment/${uuid}` && 'active')
                            }
                            to={`/contract/details/${id}/installment/${uuid}`}
                        >
                            Parcelas
                        </Link>
                        {/* <a className="nav-link text-active-primary pb-4" data-bs-toggle="tab" href="#kt_ecommerce_sales_order_history" aria-selected="false" tabIndex={-1} role="tab">Order History</a> */}
                    </li>

                    <li className="nav-item" role="presentation">
                        <Link
                            className={
                                `nav-link text-active-primary pb-4 me-6 ` +
                                (location.pathname === `/contract/details/${id}/historico/${uuid}` && 'active')
                            }
                            to={`/contract/details/${id}/historico/${uuid}`}
                        >
                            Histórico
                        </Link>
                        {/* <a className="nav-link text-active-primary pb-4" data-bs-toggle="tab" href="#kt_ecommerce_sales_order_history" aria-selected="false" tabIndex={-1} role="tab">Order History</a> */}
                    </li>

                    <li className="nav-item" role="presentation">
                        <Link
                            className={
                                `nav-link text-active-primary pb-4 me-6 ` +
                                (location.pathname === `/contract/details/${id}/documents/${uuid}` && 'active')
                            }
                            to={`/contract/details/${id}/documents/${uuid}`}
                        >
                            Documentos
                        </Link>
                        {/* <a className="nav-link text-active-primary pb-4" data-bs-toggle="tab" href="#kt_ecommerce_sales_order_history" aria-selected="false" tabIndex={-1} role="tab">Order History</a> */}
                    </li>
                </ul>
            </div>

        </div>

    )
}