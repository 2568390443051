import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import TabRouterBillingBank from "../../../../_metronic/partials/configurartion/billinBanks/TabRouterBillingBank";
import * as authHelper from './../../../modules/auth/core/AuthHelpers'
const uuid = authHelper.getAuthUui() || '';
const lastUuid = authHelper.getAuthLastUui() || '';


const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Contas',
    path: `/configuration/access/${uuid || lastUuid}`,
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const BanksWrapper = () => {
  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>Conta 2</PageTitle>
      <TabRouterBillingBank />
    </>

  )
}

