/* eslint-disable jsx-a11y/anchor-is-valid */
import { Navigate, Route, Routes } from 'react-router-dom'
import { Error500 } from './components/Error500'
import { Error404 } from './components/Error404'
import { ErrorsLayout } from './ErrorsLayout'
import { Error403 } from './components/Error403'
import * as authHelper from './../../modules/auth/core/AuthHelpers'

const ErrorsPage = () => {

    const uui = authHelper.getAuthLastUui();
    console.log('ErrorsPage ', uui)

    return (
        <Routes>
            <Route element={<ErrorsLayout />}>
                {/* <Route path='403' element={<Error403 />} /> */}
                <Route path={`404/*`} element={<Error404 />} />
                <Route path='500' element={<Error500 />} />
                <Route index element={<Navigate to={`/404/${uui}`} />} />
            </Route>
        </Routes>
    )
}

export { ErrorsPage }
