import React from 'react';
import { Tagify } from 'react-tagify';
import { optionsTypePayment } from '../../../app/globals/OptionsGlobals';

interface TagifyListCustomProps {
    selectedValues: string[];
    onSelectTagify: (action: string, value: string | string[]) => void;
    className?: string;
}

export const TagifyListCustom = ({ onSelectTagify, selectedValues, className }: TagifyListCustomProps) => {
    const handleClick = (text: string) => {
        if (text === 'Todos') {
            // Se "Todos" for clicado, adiciona todos os valores disponíveis
            const allValues = optionsTypePayment
                .filter((item) => item.value !== 'Todos')
                .map((item) => item.value);

            onSelectTagify('addAll', allValues);
        } else {
            onSelectTagify('add', text);
        }
    };

    return (
        <div className={className || 'w-100'}>
            <Tagify
                color="#0073e6"
                detectHashtags={true}
                detectMentions={false}
                onClick={handleClick}
            >
                <p>
                    {selectedValues.length > 0
                        ? selectedValues.map((val) => `#${val} `).join(' ')
                        : 'Selecione as formas de cobrança'}
                </p>
            </Tagify>
        </div>
    );
};
