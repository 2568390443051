import React, { useEffect } from "react";
import { useMutation } from 'react-query';
import { KTSVG } from "../../../../helpers";
import Select, { OptionsOrGroups, GroupBase } from "react-select";
import { FormProps, FormValues } from "../../../../../app/interface/I_Parcel";
import { formatarPercentage, formatarValor } from "../../../../helpers/functions/numbers";
import { NumericFormat } from 'react-number-format';
import { Option } from "../../../../../app/interface/I_General";
import { optionsFrequency, optionsGenerateBillingInstallments, optionsReadjustmentIndex, optionsStatusParcel, optionsTypeParcelCliente, optionsTypeParcelHouse, optionsTypePayment } from "../../../../../app/globals/OptionsGlobals";
import { useAuth } from "../../../../../app/modules/auth";

export const FormInsertParcel = ({
    closeModal,
    handlerSaveForm,
    formValues,
    handleChange
}: FormProps) => {


    const { auth } = useAuth();

    useEffect(() => {

        const formattedValue = formatarValor(formValues.value);
        const formattedInterestRate = formatarValor(formValues.interest_rate);
        handleChange("value", formattedValue);
        handleChange("interest_rate", formattedInterestRate);
    }, []);

    const handleInputChange = (
        value: string | number,
        fieldName: keyof FormValues
    ) => {
        handleChange(fieldName, value);
    };


    const handleSelectChange = <K extends keyof FormValues>(selectedOption: Option | null,
        fieldName: K) => {
        const value = selectedOption ? selectedOption.value : "";

        handleChange(fieldName, value);
    };

    let type_parcel = optionsTypeParcelHouse.find((option: any) => option.value === formValues.type_parcel)?.label || '';
    let optionsTypeParcel = optionsTypeParcelHouse;

    if (auth?.empresa?.type_assinante === "CLIENT") {
        type_parcel = optionsTypeParcelCliente.find((option: any) => option.value === formValues.type_parcel)?.label || '';
        optionsTypeParcel = optionsTypeParcelCliente;
    }

    return (
        <div className={`modal fade bg-dark bg-opacity-75 ${!closeModal ? "" : "show"}`} id="modalEditParcel" tabIndex={-1} aria-modal="true" role="dialog" style={{ "display": !closeModal ? "none" : 'block' }}>
            <div className="modal-dialog modal-xl p-5 p-lg-10" id="">
                <div className="modal-content rounded-4">
                    <div className="modal-header flex-stack border-0 px-10 pt-5 pb-0" id="">
                        <div id="" className="position-relative z-index-1"></div>

                        <div className="btn btn-sm btn-icon btn-active-color-primary me-n2 position-relative z-index-1" onClick={closeModal}>
                            <KTSVG className="svg-icon svg-icon-2x" path="/media/icons/duotune/general/gen034.svg" />
                        </div>
                    </div>

                    <div className="modal-body pt-0 pb-5 px-15 mt-n5" id="modaBody">
                        <div className="container-fluid">
                            <div className="d-block" id="kt_app_engage_prebuilts_view_image">
                                <div className="tab-content">
                                    <div className="pt-5 tab-pane fade active show" id="" role="tabpanel">
                                        <div
                                            className="pe-12 me-n12"
                                        >
                                            <div className="row gy-10">
                                                <div className="col-lg-3  fv-plugins-icon-container">
                                                    <label className='form-label fw-bold'>Parcela</label>
                                                    <div className='d-flex'>
                                                        <input
                                                            className="form-control"
                                                            name="number_parcel"
                                                            pattern="[1-9][0-9]*"
                                                            value={formValues.number_parcel}
                                                            readOnly={formValues?.id !== '' && formValues?.id !== '0' && formValues?.id !== 0 ? true : false}
                                                            onChange={(e) => {
                                                                const inputValue = e.target.value;
                                                                const isValidInput = /^[1-9][0-9]*$/.test(inputValue);

                                                                if (isValidInput || inputValue === "") {
                                                                    handleInputChange(inputValue, "number_parcel");
                                                                } else {
                                                                    handleInputChange('1', "number_parcel");
                                                                }
                                                            }
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div className="col-lg-3  fv-plugins-icon-container">
                                                        <label className='form-label fw-bold'>Recorrência</label>
                                                        <div className='d-flex'>
                                                            <Select
                                                                className="w-450px"
                                                                placeholder="Selecione"
                                                                name="is_recurring"
                                                                value={formValues.is_recurring !== '' ? { value: formValues.is_recurring, label: optionsRecurrence.find(option => option.value === formValues.is_recurring)?.label } : null}

                                                                onChange={(selectedOption:any) => handleSelectChange(selectedOption as Option | null, "is_recurring")}
                                                                options={optionsRecurrence}
                                                            />
                                                        </div>
                                                    </div> */}


                                                <div className="col-lg-3  fv-plugins-icon-container">
                                                    <label className='form-label fw-bold'>Períodicidade</label>
                                                    <div className='d-flex'>
                                                        <Select
                                                            styles={{ container: (base: any) => ({ ...base, zIndex: 99 }) }}
                                                            className="w-450px"
                                                            placeholder="Selecione"
                                                            name="frequency"
                                                            value={formValues.frequency !== '' ? { value: formValues.frequency, label: optionsFrequency.find(option => option.value === formValues.frequency)?.label } : null}
                                                            onChange={(selectedOption: any) => handleSelectChange(selectedOption as Option | null, "frequency")}
                                                            options={optionsFrequency}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-lg-3  fv-plugins-icon-container">
                                                    <label className='form-label fw-bold'>Forma de pargamento</label>
                                                    <div className='d-flex'>
                                                        <Select
                                                            styles={{ container: (base: any) => ({ ...base, zIndex: 99 }) }}
                                                            className="w-450px"
                                                            placeholder="Forma de pargamento"
                                                            name="form_payment"
                                                            value={formValues.form_payment !== '' ? { value: formValues.form_payment, label: optionsTypePayment.find(option => option.value === formValues.form_payment)?.label } : null}
                                                            onChange={(selectedOption: any) => handleSelectChange(selectedOption as Option | null, "form_payment")}
                                                            options={optionsTypePayment}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-lg-3  fv-plugins-icon-container">
                                                    <label className='form-label fw-bold'>Tipo de parcela</label>
                                                    <span className="ms-1 fs-9 text-danger">(Obrigatório)</span>
                                                    <div className='d-flex'>
                                                        <Select
                                                            styles={{ container: (base: any) => ({ ...base, zIndex: 99 }) }}
                                                            className="w-450px"
                                                            placeholder="Selecione"
                                                            name="type_parcel"
                                                            value={formValues.type_parcel !== '' ? { value: formValues.type_parcel, label: type_parcel } : null}
                                                            onChange={(selectedOption: any) => handleSelectChange(selectedOption as Option | null, "type_parcel")}
                                                            options={optionsTypeParcel}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-lg-3  fv-plugins-icon-container">
                                                    <label className='form-label fw-bold'>Vencimento</label>
                                                    <span className="ms-1 fs-9 text-danger">(Obrigatório)</span>
                                                    <div className='d-flex'>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            name="due_date"
                                                            value={formValues.due_date || ''}
                                                            onChange={(e) => {
                                                                handleInputChange(e.target.value, "due_date")
                                                            }
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-3  fv-plugins-icon-container">
                                                    <label className='form-label fw-bold'>Valor</label>
                                                    <span className="ms-1 fs-9 text-danger">(Obrigatório)</span>
                                                    <div className='d-flex'>
                                                        <NumericFormat
                                                            thousandSeparator="."
                                                            decimalSeparator=","
                                                            prefix="R$ "
                                                            thousandsGroupStyle="thousand"
                                                            fixedDecimalScale={true}
                                                            decimalScale={2}
                                                            allowLeadingZeros={true}
                                                            className={`form-control ${typeof formValues.token_boleto !== 'undefined' ? 'form-control-solid' : ''}`}
                                                            name="value"
                                                            value={formatarValor(formValues.value) || '0,00'}
                                                            onValueChange={(values) => {
                                                                handleInputChange(values.floatValue || 0, "value");

                                                            }}
                                                            readOnly={typeof formValues.token_boleto !== 'undefined' ? true : false}
                                                        />
                                                    </div>
                                                </div>

                                                {formValues?.table && formValues.table === 'parcel' ?
                                                    (
                                                        <div className="col-lg-3  fv-plugins-icon-container">
                                                            <label className='form-label fw-bold'>Situação</label>
                                                            <div className='d-flex'>
                                                                <Select
                                                                    styles={{ container: (base: any) => ({ ...base, zIndex: 99 }) }}
                                                                    className="w-450px"
                                                                    placeholder="Selecione"
                                                                    name="status"
                                                                    value={formValues.status !== '' ? { value: formValues.status, label: optionsStatusParcel.find(option => option.value === formValues.status)?.label } : null}
                                                                    onChange={(selectedOption: any) => handleSelectChange(selectedOption as Option | null, "status")}
                                                                    options={optionsStatusParcel}
                                                                />
                                                            </div>
                                                        </div>

                                                    ) :
                                                    (<></>)
                                                }

                                                <div className="col-lg-3  fv-plugins-icon-container">
                                                    <label className='form-label fw-bold'>Taxa de Juros</label>
                                                    <span className="ms-1 fs-9 text-danger">(Obrigatório)</span>
                                                    <div className='d-flex'>
                                                        <NumericFormat
                                                            className="form-control"
                                                            name="interest_rate"
                                                            thousandSeparator="."
                                                            decimalSeparator=","
                                                            suffix="%"
                                                            thousandsGroupStyle="thousand"
                                                            fixedDecimalScale={true}
                                                            decimalScale={2}
                                                            allowLeadingZeros={true}
                                                            value={formValues.interest_rate || '0,00'}
                                                            onValueChange={(values) => {
                                                                handleInputChange(values.floatValue || 0, "interest_rate");
                                                            }}

                                                        />



                                                    </div>
                                                </div>

                                                <div className="col-lg-3  fv-plugins-icon-container">
                                                    <label className='form-label fw-bold'>Índice de Reajuste</label>
                                                    <div className='d-flex'>
                                                        <Select
                                                            // styles={{ container: (base:any) => ({ ...base, zIndex: 99 }) }}
                                                            className="w-450px"
                                                            placeholder="Selecione"
                                                            name="readjustment_index"
                                                            value={formValues.readjustment_index !== 'Nenhum' ? { value: formValues.readjustment_index, label: optionsReadjustmentIndex.find(option => option.value === formValues.readjustment_index)?.label } : null}
                                                            onChange={(selectedOption: any) => handleSelectChange(selectedOption as Option | null, "readjustment_index")}
                                                            options={optionsReadjustmentIndex}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-lg-3  fv-plugins-icon-container">
                                                    <label className='form-label fw-bold'>Gerar parcelas de cobrança</label>
                                                    <div className='d-flex'>
                                                        <Select
                                                            // styles={{ container: (base:any) => ({ ...base, zIndex: 99 }) }}
                                                            className="w-450px"
                                                            placeholder="Selecione"
                                                            isDisabled={formValues?.id !== '' && formValues?.id !== '0' && formValues?.id !== 0 && formValues.generate_billing_installments !== 'nao' ? true : false}
                                                            name="generate_billing_installments"
                                                            value={formValues.generate_billing_installments !== '' ? { value: formValues.generate_billing_installments, label: optionsGenerateBillingInstallments.find(option => option.value === formValues.generate_billing_installments)?.label } : null}
                                                            onChange={(selectedOption: any) => handleSelectChange(selectedOption as Option | null, "generate_billing_installments")}
                                                            options={optionsGenerateBillingInstallments}
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="modal-footer border-0">
                                            <button type="button" className="btn btn-light" onClick={closeModal}>Fechar</button>
                                            <button type="button" className="btn btn-success me-4" onClick={handlerSaveForm}>
                                                Salvar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}