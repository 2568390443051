import { createElement } from "react";
import { ChildComponentProps } from "../../../../app/interface/I_Permission";

const Download: React.FC<ChildComponentProps> = ({ children }) => {
    return createElement(
        'div',
        { permissiontype: 'download' },
        children
    );
};

export default Download;
