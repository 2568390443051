
import axios from "axios";
import { IClient } from "../interface/I_Client";
import { AuthType, IResponse } from "../interface/I_General";
import { IAssociatedBillingInstallment, IDetailsInstallment, IPayments } from "../interface/I_Payments";

import { getToken } from './../redux/api/auth/getToken';
import * as authHelper from './../modules/auth/core/AuthHelpers';
import { FormNewBoleto } from "../interface/I_Parcel";

// URLs
const API_URL = process.env.REACT_APP_API_URL

// recupera lista de pagamentos todas as parcelas
export async function getListPayments() {

    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';
    const dataLocalStorage = getToken();

    const authorization_barear = {
        headers: {
            Authorization: `Bearer ${dataLocalStorage?.token}`,
            'X-UUID-Assinante': uuid || lastUuid
        }
    };

    try {
        const response = await axios.get<IPayments>(`${API_URL}/module/getListPayments`, authorization_barear);

        return response;
    } catch (error) {
        // Tratar o erro aqui
        console.error("Ocorreu um erro ao buscar lista de todos os pagamentos:", error);
        throw error;
    }

}

// recupera lista de pagamentos todas as parcelas
export async function getAssociatedBilling(id: string | number) {

    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';
    const dataLocalStorage = getToken();

    const authorization_barear = {
        headers: {
            Authorization: `Bearer ${dataLocalStorage?.token}`,
            'X-UUID-Assinante': uuid || lastUuid
        }
    };

    try {
        const response = await axios.get<IAssociatedBillingInstallment>(`${API_URL}/module/getAssociatedBilling`, authorization_barear);

        return response;
    } catch (error) {
        // Tratar o erro aqui
        console.error("Ocorreu um erro ao buscar dados da parcela: ", error);
        throw error;
    }

}

// recupera lista de pagamentos todas as parcelas
export async function detailsParcel(id: string | number) {

    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';
    const dataLocalStorage = getToken();

    const authorization_barear = {
        headers: {
            Authorization: `Bearer ${dataLocalStorage?.token}`,
            'X-UUID-Assinante': uuid || lastUuid
        }
    };
    try {
        const response = await axios.get<IDetailsInstallment>(`${API_URL}/module/getDetailsParcel`, authorization_barear);

        return response;
    } catch (error) {
        // Tratar o erro aqui
        console.error("Ocorreu um erro ao buscar dados da parcela: ", error);
        throw error;
    }

}

// recupera lista de pagamentos todas as parcelas
export async function getDataClient(id: string | number) {

    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';
    const dataLocalStorage = getToken();

    const authorization_barear = {
        headers: {
            Authorization: `Bearer ${dataLocalStorage?.token}`,
            'X-UUID-Assinante': uuid || lastUuid
        }
    };

    try {
        const response = await axios.get<IClient>(`${API_URL}/module/getDataClient`, authorization_barear);

        return response;
    } catch (error) {
        // Tratar o erro aqui
        console.error("Ocorreu um erro ao buscar dados da parcela: ", error);
        throw error;
    }
}

// faz o downlaod do boleto
export async function downloadBoletos(link_boleto: string) {
    try {
        window.open(link_boleto);
    } catch (error) {
        // Tratar o erro aqui
        console.error("Ocorreu um erro ao buscar dados da parcela: ", error);
        throw error;
    }
}

// faz o downlaod em excel dos pagamentos
export async function downloadExcel(form: any, auth: AuthType, url_api: string) {

    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';
    const dataLocalStorage = getToken();

    try {

        // let headers = {};
        // if (auth && auth.api_token) {
        //     headers = { Authorization: `Bearer ${auth.api_token}` };
        // }

        const response = await axios.post(
            url_api,
            { form },
            {
                headers: {
                    Authorization: `Bearer ${dataLocalStorage?.token || auth?.api_token}`,
                    'X-UUID-Assinante': uuid || lastUuid
                },
                responseType: 'arraybuffer'
            },
        )

        return response.data;
    } catch (error) {
        // Tratar o erro aqui
        console.error("Ocorreu um erro ao buscar dados da parcela: ", error);
        throw error;
    }

}

// envia dados do filtro para download
export async function sendFilterdownloadExcel(form: any, auth: AuthType) {
    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';
    const dataLocalStorage = getToken();

    try {

        // let headers = {};
        // if (auth && auth.api_token) {
        //     headers = { Authorization: `Bearer ${auth.api_token}` };
        // }
        //   `${API_URL}/report/parcels/download?${queryParams}`
        const response = await axios.post(`${API_URL}/module/report/parcels/download`,
            { form },
            {
                headers: {
                    Authorization: `Bearer ${dataLocalStorage?.token || auth?.api_token}`,
                    'X-UUID-Assinante': uuid || lastUuid
                }
            }
        );

        return response;
    } catch (error) {
        throw error;
    }

}

// envia dados do filtro para download
export async function novoBolegerarNovoBoletoto(data: FormNewBoleto) {
    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';
    const dataLocalStorage = getToken();

    try {

        const response = await axios.post<IResponse>(`${API_URL}/module/gerarNovoBoleto`,
            { data },
            {
                headers: {
                    Authorization: `Bearer ${dataLocalStorage?.token}`,
                    'X-UUID-Assinante': uuid || lastUuid
                }
            }
        );

        return response;
    } catch (error) {
        throw error;
    }

}
