
import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import Select from "react-select";
import { KTSVG } from "../../../../../../_metronic/helpers";
import { IBank, IBeneficiary, optionsStatusBank } from "../../../../../interface/I_Configurations";
import { Option } from "../../../../../interface/I_General";
import * as yup from 'yup';
import { SchemaRegister } from "./SchemaRegister";
import { optionsTypeApiBoletos } from "../../../../../globals/OptionsGlobals";

export const Itau: any = ({ listBeneficiary, formDataEdit, combineFormValues, errors }: any) => {
    const [formValues, setFormValues] = useState(formDataEdit || {
        id: '',
        id_sacador_avalista: '',
        bank_code: '',
        api_integration: [],
        action_api_integration: [],
        sequence_our_number: '',
        sequence_remessa: '',
        name_integration: 'itau_bank',
        beneficiary: '',
        name_apresentation: '',
        agency: '',
        agency_dv: '',
        account: '',
        account_dv: '',
        beneficiary_code: '',
        code_beneficiario_dv: '',
        chave_pix: '',
        carteira: '',
        status: '',
        message: '',

    });

    const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({

        // bank_code:'',
        // api_integration:[],
        beneficiary: '',
        name_apresentation: '',
        agency: '',
        account: '',
        carteira: '',
    });

    useEffect(() => {
        setFormErrors(errors);
    }, [errors]);

    const arrApiLength = formDataEdit.api_integration || {};
    const arrIntegrationApi = Object.keys(arrApiLength).length > 0 ? Object.values(arrApiLength) : []

    const [apiIntegration, setApiIntegration] = useState(arrIntegrationApi);
    const [actionApiIntegration, setActionApiIntegration] = useState(formDataEdit.action_api_integration || []);
    const [indexSelect, setIndexSelect] = useState(1);

    const loadApiIntegration = () => {

        const updatedApiIntegration = apiIntegration.map((value: any) => {
            return typeof value === 'string' && value.startsWith('hbr_') ? value.slice(4) : value;
        });

        setApiIntegration(updatedApiIntegration);

        combineFormValues({
            // ['api_integration']: arrayApiIntegration,
            ['action_api_integration']: actionApiIntegration
        });

        setFormValues((prevState: any) => {
            return {
                ...prevState,
                //   api_integration: updatedApiIntegration,
                action_api_integration: actionApiIntegration,
            };
        });
    }


    useEffect(() => {

        loadApiIntegration();
    }, []);


    const handleInputChange = (
        value: string | number,
        fieldName: keyof IBank
    ) => {

        if (formErrors.hasOwnProperty(fieldName)) {
            yup
                .reach(SchemaRegister, fieldName)
                .validate(value)
                .then(() => {
                    // Se o campo for válido, remove o erro correspondente
                    setFormErrors((prevErrors) => ({ ...prevErrors, [fieldName]: '' }));
                })
                .catch((error: { message: any; }) => {
                    // Se o campo for inválido, define o erro correspondente
                    setFormErrors((prevErrors) => ({ ...prevErrors, [fieldName]: error.message }));
                });
        }

        setFormValues({
            ...formValues,
            [fieldName]: value
        });

        combineFormValues({
            [fieldName]: value
        });


    };

    const handleSelectChange = <K extends keyof IBank>(selectedOption: Option | null, fieldName: K) => {
        const value = selectedOption ? selectedOption.value : "";
        const label = selectedOption ? selectedOption.label : "";
        if (fieldName === 'beneficiary') {
            setFormValues({
                ...formValues,
                [fieldName]: label,
                ['id_sacador_avalista']: value
            });

            // setFormValues((prevState:any) => ({
            //     ...prevState, 
            // }));

            combineFormValues({
                [fieldName]: label
            });

            combineFormValues({
                [fieldName]: label,
                ['id_sacador_avalista']: value
            });

        } else {

            setFormValues({
                ...formValues,
                [fieldName]: value
            });

            combineFormValues({
                [fieldName]: value
            });
        }

    };

    const handleFocus = (zIndex: number) => {
        setIndexSelect(zIndex);
    }

    const handleBlur = () => {
        setIndexSelect(1);
    }

    const addRowApiIntegration = () => {
        const newIndex = apiIntegration.length;
        setApiIntegration((prevState: any) => [...prevState, `itau_${newIndex}`]);
        setActionApiIntegration((prevState: any) => [...prevState, `consultar`]);
    }

    const removeRowApiIntegration = (indexToRemove: any, newValue: any) => {
        setApiIntegration((prevState: any) => {
            const updatedIntegration = [...prevState];
            updatedIntegration.splice(indexToRemove, 1);
            return updatedIntegration;
        });

        setActionApiIntegration((prevState: any) => {
            const updatedIntegration = [...prevState];
            updatedIntegration.splice(indexToRemove, 1);
            return updatedIntegration;
        });

        setFormValues((prevState: any) => {
            const updatedApiIntegration = Array.isArray(prevState.api_integration)
                ? prevState.api_integration.filter((_: any, index: any) => index !== indexToRemove)
                : [];

            const updatedActionApiIntegration = Array.isArray(prevState.action_api_integration)
                ? prevState.action_api_integration.filter((_: any, index: any) => index !== indexToRemove)
                : [];

            return {
                ...prevState,
                api_integration: updatedApiIntegration,
                action_api_integration: updatedActionApiIntegration,
            };
        });
    };


    const handleChangeApi = (index: any, newValue: any) => {

        const updatedIntegration = apiIntegration.map((value: any, idx: any) =>
            idx === index ? newValue : value
        );

        setApiIntegration(updatedIntegration);

    }

    const handleSelectActionApi = <K extends keyof IBank>(selectedOption: Option | null, index: number) => {
        const newValue = selectedOption ? selectedOption.value : "";
        let updatedActionIntegration = [...actionApiIntegration]; // Crie uma cópia do array original

        if (index < updatedActionIntegration.length) {
            // Atualize o valor se o índice existir no array
            updatedActionIntegration[index] = newValue;
        } else {
            // Adicione um novo valor se o índice estiver fora do limite atual
            updatedActionIntegration = [...updatedActionIntegration, newValue];
        }

        setActionApiIntegration(updatedActionIntegration);
        setFormValues((prevState: any) => ({
            ...prevState,
            action_api_integration: updatedActionIntegration
        }));

        combineFormValues({
            ['action_api_integration']: updatedActionIntegration
        });

    };

    useEffect(() => {

        const combinedApiIntegration = apiIntegration.reduce((acc: any, value: any, index: number) => {
            const action = actionApiIntegration[index] || 'consultar';

            acc[action] = value.startsWith('hbr_') ? value : `hbr_${value}`;
            return acc;
        }, {});

        setFormValues((prevState: any) => ({
            ...prevState,
            api_integration: combinedApiIntegration,
        }));

        combineFormValues({
            ['api_integration']: combinedApiIntegration,
        });

    }, [apiIntegration, actionApiIntegration]);


    return (<>
        <h5 className="fs-6 mb-3">DADOS DA CONTA</h5>

        <div className="col-md-12">

            <label htmlFor="">Beneficiário</label>
            <div className="form-floating mb-7">
                <Select
                    styles={{ container: (base: any) => ({ ...base, zIndex: 10 }) }}
                    className="w-100"
                    placeholder="Beneficiário"
                    value={
                        !!formValues.id_sacador_avalista
                            ? listBeneficiary.find((option: IBeneficiary) => option.value === formValues.id_sacador_avalista)
                            : { value: '', label: '' }
                    }
                    onChange={(selectedOption: any) => handleSelectChange(selectedOption as Option | null, "beneficiary")}
                    options={listBeneficiary}
                />

                {formErrors.beneficiary && (
                    <div className="fv-plugins-message-container invalid-feedback">{formErrors.beneficiary}</div>
                )}

            </div>
        </div>

        <div className="col-md-12">
            <div className="form-floating mb-7">
                <input className="form-control"
                    placeholder="Empresa"
                    name="name_apresentation"
                    id="name_apresentation"
                    value={formValues.name_apresentation || ''}
                    onChange={(e: any) => {
                        handleInputChange(e.target.value || '', "name_apresentation");
                    }
                    }
                />
                <label htmlFor="name_apresentation">Nome da conta</label>

                {formErrors.name_apresentation && (
                    <div className="fv-plugins-message-container invalid-feedback">{formErrors.name_apresentation}</div>
                )}
            </div>
        </div>

        <div className="col-md-3">
            <div className="form-floating mb-7">
                <input className="form-control"
                    placeholder="Agência"
                    name="agency"
                    id="agency"
                    value={formValues.agency || ''}
                    onChange={(e: any) => {
                        handleInputChange(e.target.value || '', "agency");
                    }
                    }
                />
                <label htmlFor="agency">Agência</label>

                {formErrors.agency && (
                    <div className="fv-plugins-message-container invalid-feedback">{formErrors.agency}</div>
                )}
            </div>
        </div>

        <div className="col-md-3">
            <div className="form-floating mb-7">
                <input type="number"
                    className="form-control"
                    placeholder="Agência digito"
                    name="agency_dv"
                    id="agency_dv"
                    value={formValues.agency_dv || ''}
                    onChange={(e: any) => {
                        handleInputChange(e.target.value || '', "agency_dv");
                    }
                    }
                />
                <label htmlFor="agency_dv">Ag. dígito</label>
            </div>
        </div>
        <div className="col-md-3">
            <div className="form-floating mb-7">
                <input className="form-control"
                    placeholder="Conta"
                    name="account"
                    id="account"
                    value={formValues.account || ''}
                    onChange={(e: any) => {
                        handleInputChange(e.target.value || '', "account");
                    }
                    }
                />
                <label htmlFor="account">Conta</label>
                {formErrors.account && (
                    <div className="fv-plugins-message-container invalid-feedback">{formErrors.account}</div>
                )}
            </div>
        </div>


        <div className="col-md-3">
            <div className="form-floating mb-7">
                <input className="form-control"
                    placeholder="Conta digito"
                    name="account_dv"
                    id="account_dv"
                    value={formValues.account_dv || ''}
                    onChange={(e: any) => {
                        handleInputChange(e.target.value || '', "account_dv");
                    }
                    }
                />
                <label htmlFor="account_dv">Conta digito</label>
            </div>
        </div>

        <div className='col-md-4'>
            <div className="form-floating mb-7">
                <input className="form-control"
                    placeholder="Código do beneficiário"
                    name="beneficiary_code"
                    id="beneficiary_code"
                    value={formValues.beneficiary_code || ''}
                    onChange={(e: any) => {
                        handleInputChange(e.target.value || '', "beneficiary_code");
                    }
                    }
                />
                <label htmlFor="beneficiary_code">Cód. Beneficiário</label>
            </div>

        </div>

        <div className='col-md-4'>
            <div className="form-floating mb-7">
                <input className="form-control"
                    placeholder="Beneficiário digito"
                    name="code_beneficiario_dv"
                    id="code_beneficiario_dv"
                    value={formValues.code_beneficiario_dv || ''}
                    onChange={(e: any) => {
                        handleInputChange(e.target.value || '', "code_beneficiario_dv");
                    }
                    }
                />
                <label htmlFor="code_beneficiario_dv">Beneficiário digito</label>
            </div>
        </div>

        <div className='col-md-4'>
            <div className="form-floating mb-7">
                <input className="form-control"
                    placeholder="Carteira"
                    name="carteira"
                    id="carteira"
                    value={formValues.carteira || ''}
                    onChange={(e: any) => {
                        handleInputChange(e.target.value || '', "carteira");
                    }
                    }
                />
                <label htmlFor="carteira">Carteira</label>
                {formErrors.carteira && (
                    <div className="fv-plugins-message-container invalid-feedback">{formErrors.carteira}</div>
                )}
            </div>
        </div>

        <div className='col-md-4'>

            <label htmlFor="">Sequêncial</label>
            <div className="form-floating mb-7">
                <input className="form-control"
                    placeholder="Nosso número"
                    name="sequence_our_number"
                    id="sequence_our_number"
                    value={formValues.sequence_our_number || ''}
                    onChange={(e: any) => {
                        handleInputChange(e.target.value || '', "sequence_our_number");
                    }
                    }
                />
                <label htmlFor="sequence_our_number">Nosso número</label>
            </div>
        </div>

        <div className='col-md-4'>

            <label htmlFor="">Sequêncial</label>
            <div className="form-floating mb-7">
                <input className="form-control"
                    placeholder="Remessa"
                    name="sequence_remessa"
                    id="sequence_remessa"
                    value={formValues.sequence_remessa || ''}
                    onChange={(e: any) => {
                        handleInputChange(e.target.value || '', "sequence_remessa");
                    }
                    }
                />
                <label htmlFor="sequence_remessa">Remessa</label>
            </div>
        </div>

        <div className='col-md-4 mt-1'>
            <label htmlFor="">Status</label>
            <div className="form-floating mb-7">
                <div className='d-flex'>
                    <Select
                        styles={{ container: (base: any) => ({ ...base, zIndex: 10 }) }}
                        className="w-450px"
                        placeholder="Selecione"
                        value={
                            !!formValues.active
                                ? optionsStatusBank.find((option: IBeneficiary) => option.value === formValues.active)
                                : { value: '2', label: 'Inativo' }
                        }

                        onChange={(selectedOption: any) => handleSelectChange(selectedOption as Option | null, "active")}
                        options={optionsStatusBank}
                    />
                </div>

            </div>
        </div>

        <div className='col-md-12 mt-1'>
            <div className="form-floating mb-7">
                <input className="form-control"
                    placeholder="Chave pix"
                    name="chave_pix"
                    id="chave_pix"
                    value={formValues.chave_pix || ''}
                    onChange={(e: any) => {
                        handleInputChange(e.target.value || '', "chave_pix");
                    }
                    }
                />
                <label htmlFor="sequence_remessa">Chave pix</label>
            </div>
        </div>

        <div className='col-md-12'>
            <div className="form-floating mb-7">
                <textarea className="form-control"
                    placeholder="Mensagem"
                    name="message"
                    id="message"
                    value={formValues.message || ''}
                    onChange={(e: any) => {
                        handleInputChange(e.target.value || '', "message");
                    }
                    }
                ></textarea>
                <label htmlFor="message">Mensagem</label>
            </div>
        </div>
        <div className="col-md-12">

            <label htmlFor="api_integration" className="form-label mt-3 mb-5">API DE INTEGRAÇÃO:</label>
            {

                apiIntegration && apiIntegration.length > 0 && Array.isArray(apiIntegration) ?
                    (
                        <div className="table-responsive">
                            <table id="kt_create_new_custom_fields" className="table align-middle table-row-dashed fw-semibold fs-6 gy-5 dataTable no-footer">
                                <thead>
                                    <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                                        <th className="pt-0 sorting_disabled w-100" rowSpan={1} colSpan={1}>API</th>
                                        <th className="pt-0 sorting_disabled" rowSpan={1} colSpan={1} style={{ width: '258.078px' }}>FUNÇÃO</th>
                                        <th className="pt-0 text-end sorting_disabled" rowSpan={1} colSpan={1} style={{ width: '60.9531px' }}>AÇÃO</th>
                                    </tr>
                                </thead>

                                <tbody>

                                    {
                                        apiIntegration.map((value: any, index: number) => (
                                            <tr className="odd" key={`hbr_${index}`}>
                                                <td>
                                                    <div className="input-group mb-5">
                                                        <span className="input-group-text">hbr_</span>
                                                        <input
                                                            className="form-control mb-2 mb-md-0"
                                                            id={`api_integration_${index}`}
                                                            name={`api_integration_${index}`}
                                                            placeholder="estruturações"
                                                            value={String(apiIntegration[index]) || ''}
                                                            onChange={(e) => handleChangeApi(index, e.target.value)}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <Select
                                                        styles={{ container: (base: any) => ({ ...base, zIndex: indexSelect === index ? 20 : 1 }) }}
                                                        className="w-125px mt-n5"
                                                        placeholder="Selecione"
                                                        value={
                                                            actionApiIntegration && actionApiIntegration[index] !== null && actionApiIntegration[index] !== ''
                                                                ? optionsTypeApiBoletos.find((option: IBeneficiary) => option.value === actionApiIntegration[index])
                                                                : { value: 'consultar', label: 'Consultar' }
                                                        }
                                                        onFocus={() => handleFocus(index)}
                                                        onBlur={() => handleBlur()}
                                                        onChange={(selectedOption: any) => handleSelectActionApi(selectedOption as Option | null, index)}
                                                        options={optionsTypeApiBoletos}
                                                    />
                                                </td>
                                                <td className="text-end">
                                                    <a type="button" onClick={() => removeRowApiIntegration(index, value)} className="btn btn-sm btn-flex flex-center btn-light-danger mt-3 mt-n5">
                                                        <KTSVG path="/media/icons/duotune/general/gen027.svg" /> Remover
                                                    </a>
                                                </td>
                                            </tr>
                                        ))
                                    }

                                </tbody>
                            </table>
                        </div>
                    ) :
                    (
                        <div className="alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row p-5 mb-10">

                            <KTSVG className="svg-icon svg-icon-2x" path="/media/icons/duotune/general/gen007.svg" />

                            <div className="d-flex flex-column pe-0 pe-sm-10">

                                <h4 className="fw-semibold">Atenção!</h4>

                                <span>Não existe configuração de api.</span>

                            </div>

                        </div>
                    )
            }

            <div >


                <div className="form-group">
                    <a type="button" onClick={() => addRowApiIntegration()} className="btn btn-flex flex-center btn-light-primary">
                        <KTSVG path="/media/icons/duotune/arrows/arr013.svg" />
                        Adionar
                    </a>
                </div>
            </div>

        </div>
    </>);
}