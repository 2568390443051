import { useEffect, useState } from "react";
import { getDocumentsClient } from "../../request/client";
import { KTSVG } from "../../../_metronic/helpers";

// Tipos para contrato e cliente
type AnexoContractType = {
    anexo_nome: string;
    anexo_url: string;
    anexo_path: string;
    anexo_nome_cliente: string;
    anexo_data_criacao: string;
    type: "contract";
};

type DocumentClientType = {
    documento_titulo: string;
    documento_tipo: string;
    documento_path: string;
    documento_nome: string;
    documento_texto_analise_credito: string;
    documento_texto_analise_credito_negocio: string;
    documento_data_criacao: string;
    type: "client";
};

type DocumentType = AnexoContractType | DocumentClientType;

export const DocumentsClientWrapper = ({ id }: { id: number | undefined }) => {
    const [documents, setDocuments] = useState<DocumentType[]>([]);

    const fetchDocuments = async () => {
        try {
            const response = await getDocumentsClient(id);

            const contractDocuments = response.data.contracts || [];
            const clientDocuments = response.data.clients || [];

            // Combinando os documentos de ambos os tipos
            const combinedDocuments = [
                ...contractDocuments.map((doc: DocumentType) => ({
                    ...doc,
                    type: "contract",
                })),
                ...clientDocuments.map((doc: DocumentType) => ({
                    ...doc,
                    type: "client",
                })),
            ];

            setDocuments(combinedDocuments);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchDocuments();
    }, [id]);

    const renderDocumentDetails = (doc: DocumentType) => {
        if (doc.type === "contract") {
            const contractDoc = doc as AnexoContractType;
            return (
                <>
                    <h5 className="card-title mb-2">
                        {contractDoc.anexo_nome.length > 20
                            ? contractDoc.anexo_nome.slice(0, 20) + "..."
                            : contractDoc.anexo_nome}
                    </h5>
                    <p className="card-text mb-3">
                        Cliente: {contractDoc.anexo_nome_cliente}
                    </p>
                </>
            );
        } else if (doc.type === "client") {
            const clientDoc = doc as DocumentClientType;
            return (
                <>
                    <h5 className="card-title mb-2">{clientDoc.documento_titulo}</h5>
                    <p className="card-text mb-3">
                        Tipo: {clientDoc.documento_tipo}
                    </p>
                    <p className="text-muted">
                        Texto de Análise de Crédito: {clientDoc.documento_texto_analise_credito}
                    </p>
                </>
            );
        }
        return null;
    };

    const isPdf = (path: string) => {
        return path.toLowerCase().endsWith('.pdf');
    };

    return (
        <div className="row">
            {documents.length === 0 ? (
                <div className="col-12 mt-20 mb-20">
                    <div className="card p-18">
                        <p>Nenhum documento encontrado.</p>
                    </div>
                </div>
            ) : (
                documents.map((doc, index) => (
                    <div className="col-md-3 col-12 mb-4" key={index}>
                        <div className="card h-100">
                            <div className="card-img-top d-flex justify-content-center mt-3">
                                {isPdf(doc.type === "contract" ? doc.anexo_path : doc.documento_path) ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#009cf2" className="bi bi-file-pdf" viewBox="0 0 16 16">
                                        <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1" />
                                        <path d="M4.603 12.087a.8.8 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.7 7.7 0 0 1 1.482-.645 20 20 0 0 0 1.062-2.227 7.3 7.3 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a11 11 0 0 0 .98 1.686 5.8 5.8 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.86.86 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.7 5.7 0 0 1-.911-.95 11.6 11.6 0 0 0-1.997.406 11.3 11.3 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.8.8 0 0 1-.58.029m1.379-1.901q-.25.115-.459.238c-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361q.016.032.026.044l.035-.012c.137-.056.355-.235.635-.572a8 8 0 0 0 .45-.606m1.64-1.33a13 13 0 0 1 1.01-.193 12 12 0 0 1-.51-.858 21 21 0 0 1-.5 1.05zm2.446.45q.226.244.435.41c.24.19.407.253.498.256a.1.1 0 0 0 .07-.015.3.3 0 0 0 .094-.125.44.44 0 0 0 .059-.2.1.1 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a4 4 0 0 0-.612-.053zM8.078 5.8a7 7 0 0 0 .2-.828q.046-.282.038-.465a.6.6 0 0 0-.032-.198.5.5 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822q.036.167.09.346z" />
                                    </svg>
                                ) : (
                                    <img
                                        src={doc.type === "contract" ? doc.anexo_url : doc.documento_path}
                                        alt={doc.type === "contract" ? doc.anexo_nome : doc.documento_nome}
                                        style={{
                                            maxHeight: "56px",
                                            width: "auto",
                                            objectFit: "contain",
                                            maxWidth: "100%",
                                            marginTop: '12px',
                                            marginBottom: '-15px'
                                        }}
                                    />
                                )}
                            </div>
                            <div className="card-body d-flex flex-column justify-content-between">
                                {renderDocumentDetails(doc)}
                                <div className="row align-items-center mt-auto">
                                    <span className="text-muted">
                                        Documento: {doc.type === "contract" ? "Contrato" : "Documento do Cliente"}
                                    </span>
                                    <a
                                        href={doc.type === "contract" ? doc.anexo_url : doc.documento_path}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="btn btn-sm btn-primary"
                                    >
                                        Ver arquivo
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            )}
        </div>
    );
};
