
import React, { MouseEventHandler, useCallback, useEffect, useRef, useState } from "react";
import { KTSVG } from "../../../../_metronic/helpers";
import { useAuth } from "../../../modules/auth";
import * as Yup from 'yup'
import { SchemaCorretor } from "../SchemaCorretor";
import InputMask from 'react-input-mask';
import { useMemo } from 'react';
import Swal from "sweetalert2";
import axios from "axios";
import { FormCorretorProps, OptionSelecteBank } from "../../../interface/I_Corretor";
import { editCorretorAPI } from "../../../request/corretor";
import { Option } from "../../../interface/I_General";
import Select from "react-select";
import { optionsBanks, optionsTypeBank } from "../../../globals/OptionsGlobals";

export const FormEditCorretor: React.FC<FormCorretorProps> = ({
    formValues,
    closeModal,
    onFormSubmit
}) => {

    const { auth, logout, setCurrentUser } = useAuth();

    const [formData, setFormData] = useState(formValues || {
        id: '0',
        name: '',
        rg: '',
        cpf: '',
        phone: '',
        email: '',
        creci: '',
        code_bank: '',
        bank: '',
        codigo_operation_bank: '',
        operation_bank: '',
        agency: '',
        account: '',
        account_digit: '',
        pix: '',
    });

    const [formErrors, setFormErrors] = useState<{ [key: string | number]: string | number }>({
        name: '',
        cpf: '',
        email: '',
    });

    useEffect(() => {
        if (formValues) {
            setFormData({
                id: formValues.id || '',
                name: formValues.name || '',
                cpf: formValues.cpf || '',
                rg: formValues.rg || '',
                phone: formValues.phone || '',
                email: formValues.email || '',
                creci: formValues.creci || '',
                code_bank: formValues.code_bank || '',
                bank: formValues.bank || '',
                codigo_operation_bank: formValues.codigo_operation_bank || '',
                operation_bank: formValues.operation_bank || '',
                agency: formValues.agency || '',
                account: formValues.account || '',
                account_digit: formValues.account_digit || '',
                pix: formValues.pix || '',
            });
        }

    }, [formValues]);

    const handleChange = useCallback((e: any) => {

        const { name, value } = e.target;

        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }, []);


    const handleSelectChange = <K extends keyof OptionSelecteBank>(selectedOption: Option | null, fieldName: K) => {
        const value = selectedOption ? selectedOption.value.toString() : "";
        const textSelected = selectedOption ? selectedOption.label : "";

        if (fieldName === 'code_bank') {

            setFormData(prevState => ({
                ...prevState,
                ['code_bank']: value,
                ['bank']: textSelected
            }));

        } else {

            setFormData(prevState => ({
                ...prevState,
                ['codigo_operation_bank']: value,
                ['operation_bank']: textSelected
            }));
        }
    };

    function returnForm() {
        if (formData.id !== '0') {
            if (typeof onFormSubmit === 'function') {
                onFormSubmit(formData);
            }
        }
    }

    // envia formulario
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {

            await SchemaCorretor.validate(formData, { abortEarly: false });
            setFormErrors({});

            await Swal.fire({
                icon: "info",
                title: "Atenção!",
                text: "Deseja salvar os dados do corretor?",
                showCancelButton: true,
                confirmButtonText: 'Sim, salvar!',
                cancelButtonText: 'Não',
            }).then((result) => {

                if (result.isConfirmed) {
                    saveForm();
                }
            });

        } catch (errors) {

            if (errors instanceof Yup.ValidationError) {
                const validationErrors: { [key: string]: string } = {};
                errors.inner.forEach((error) => {
                    validationErrors[error.path as string] = error.message
                });
                setFormErrors(validationErrors);
            }
        }
    };

    const saveForm = async () => {

        const response = await editCorretorAPI(formData);

        if (response.data.success) {
            Swal.fire({
                icon: "success",
                title: "Parabéns",
                text: `Dados  atualizados com sucesso!`,
                showCancelButton: false,
                confirmButtonText: 'Ok',
            });

            if (response.data.data !== "0" && response.data.data !== null && response.data.data !== "") {
                // let lastId = response.data.data.toString();  

                // setFormData(prevState => ({
                //     ...prevState,                
                //     ['id']: lastId
                // }));

                returnForm();
            }

        } else if (response.data.status && response.data.status !== 403) {
            Swal.fire({
                icon: "info",
                title: "Atenção",
                html: `Não foi possível salvar, verifique a conexao com internet, <br> ou fale com um administrador do sistema.`,
                showCancelButton: false,
                confirmButtonText: 'Entendi',
            });
        }
    }

    return (
        <div className={`modal fade bg-dark bg-opacity-75 ${!closeModal ? "" : "show"}`} id="modalEditContract" tabIndex={-1} aria-modal="true" role="dialog" style={{ "display": !closeModal ? "none" : 'block' }}>
            <div className="modal-dialog modal-xl p-5 p-lg-10" id="">
                <div className="modal-content rounded-4">
                    <div className="modal-header flex-stack border-0 px-10 pt-5 pb-0" id="">
                        <div id="" className="position-relative z-index-1"></div>

                        <div className="btn btn-sm btn-icon btn-active-color-primary me-n2 position-relative z-index-1" onClick={closeModal}>
                            <KTSVG className="svg-icon svg-icon-2x" path="/media/icons/duotune/general/gen034.svg" />
                        </div>
                    </div>

                    <div className="modal-body pt-0 pb-5 px-15 mt-n5" id="">
                        <div className="container-fluid">
                            <div className="d-block">
                                <div className="tab-content">
                                    <div className="pt-5 tab-pane fade active show" role="tabpanel">
                                        <form onSubmit={handleSubmit}>
                                            {/* Contrato */}
                                            <div className="separator d-flex flex-center">
                                                <span className="text-uppercase bg-body fs-7 fw-semibold text-muted px-3">Dados do Corretor</span>
                                            </div>
                                            <div className="pe-12 me-n12 mt-15" >
                                                <div className="row gy-5">

                                                    {/*  */}
                                                    <div className="col-lg-4 fv-row fv-plugins-icon-container form-floating">
                                                        <label className='form-label fw-bold'></label>
                                                        <input
                                                            name="name"
                                                            placeholder="Nome"
                                                            className={`form-control ${formErrors.name ? ' is-invalid' : ''}`}
                                                            value={formData.name || ''}
                                                            onChange={handleChange}

                                                        />
                                                        <label className="ms-3" htmlFor="name">Nome</label>
                                                        {formErrors.name && (
                                                            <div className="fv-plugins-message-container invalid-feedback">{formErrors.name}</div>
                                                        )}
                                                    </div>

                                                    <div className="col-lg-4 fv-row fv-plugins-icon-container form-floating">

                                                        <InputMask
                                                            className={`form-control form-control-lg ${formErrors.cpf ? ' is-invalid' : ''}`} placeholder="Cpf"
                                                            mask="999.999.999-99"
                                                            name="cpf"
                                                            value={formData.cpf}
                                                            onChange={handleChange}
                                                        />
                                                        <label className="ms-3" htmlFor="cpf">Cpf</label>
                                                        {formErrors.cpf && (
                                                            <div className="fv-plugins-message-container invalid-feedback">{formErrors.cpf}</div>
                                                        )}
                                                    </div>


                                                    <div className="col-lg-4 fv-row fv-plugins-icon-container form-floating">

                                                        <InputMask
                                                            className={`form-control ${formErrors.phone ? ' is-invalid' : ''}`}
                                                            placeholder="Telefone"
                                                            id="phone"
                                                            mask="(99) 99999-9999"
                                                            name="phone"
                                                            value={formData.phone || ''}
                                                            onChange={handleChange}
                                                        />
                                                        <label className="ms-3" htmlFor="phone">Telefone</label>
                                                        {formErrors.phone && (
                                                            <div className="fv-plugins-message-container invalid-feedback">{formErrors.phone}</div>
                                                        )}
                                                    </div>

                                                    <div className="col-lg-4 fv-row fv-plugins-icon-container form-floating">
                                                        <input
                                                            name="email"
                                                            placeholder="E-mail"
                                                            className={`form-control ${formErrors.email ? ' is-invalid' : ''}`}
                                                            value={formData.email || ''}
                                                            onChange={handleChange}

                                                        />
                                                        <label className="ms-3" htmlFor="email">E-mail</label>
                                                        {formErrors.email && (
                                                            <div className="fv-plugins-message-container invalid-feedback">{formErrors.email}</div>
                                                        )}
                                                    </div>

                                                    <div className="col-lg-4  fv-row fv-plugins-icon-container">
                                                        <Select
                                                            className={`w-auto ${formErrors.bank ? ' is-invalid' : ''}`}
                                                            placeholder="Código do Banco"
                                                            value={
                                                                formData.code_bank
                                                                    ? { value: formData.code_bank, label: formData.bank || '' }
                                                                    : null
                                                            }
                                                            onChange={(selectedOption: any) => handleSelectChange(selectedOption as Option | null, "code_bank")}
                                                            options={optionsBanks}
                                                        />
                                                        {formErrors.code_bank && (
                                                            <div className="fv-plugins-message-container invalid-feedback">{formErrors.code_bank}</div>
                                                        )}
                                                    </div>

                                                    <div className="col-lg-4  fv-row fv-plugins-icon-container">
                                                        <Select
                                                            className={`w-auto ${formErrors.bank ? ' is-invalid' : ''}`}
                                                            placeholder="Tipo de conta"
                                                            value={
                                                                formData.codigo_operation_bank
                                                                    ? { value: formData.codigo_operation_bank, label: formData.operation_bank || '' }
                                                                    : null
                                                            }
                                                            onChange={(selectedOption: any) => handleSelectChange(selectedOption as Option | null, "codigo_operation_bank")}
                                                            options={optionsTypeBank}
                                                        />
                                                        {formErrors.codigo_operation_bank && (
                                                            <div className="fv-plugins-message-container invalid-feedback">{formErrors.codigo_operation_bank}</div>
                                                        )}
                                                    </div>

                                                    <div className="col-lg-3 fv-row fv-plugins-icon-container form-floating">
                                                        <InputMask
                                                            className={`form-control ${formErrors.creci ? ' is-invalid' : ''}`}
                                                            placeholder="Creci"
                                                            id="creci"
                                                            mask=""
                                                            name="creci"
                                                            value={formData.creci || ''}
                                                            // unmask
                                                            onChange={handleChange}
                                                        />
                                                        <label className="ms-3" htmlFor="creci">Creci</label>
                                                        {formErrors.creci && (
                                                            <div className="fv-plugins-message-container invalid-feedback">{formErrors.creci}</div>
                                                        )}
                                                    </div>


                                                    <div className="col-lg-3 fv-row fv-plugins-icon-container form-floating">
                                                        <input
                                                            name="agency"
                                                            placeholder="Agência"
                                                            className={`form-control ${formErrors.agency ? ' is-invalid' : ''}`}
                                                            value={formData.agency || ''}
                                                            onChange={handleChange}

                                                        />
                                                        <label className="ms-3" htmlFor="agency">Agência</label>
                                                        {formErrors.agency && (
                                                            <div className="fv-plugins-message-container invalid-feedback">{formErrors.agency}</div>
                                                        )}
                                                    </div>

                                                    <div className="col-lg-4 fv-row fv-plugins-icon-container form-floating">
                                                        <input
                                                            name="account"
                                                            placeholder="Conta"
                                                            className={`form-control ${formErrors.account ? ' is-invalid' : ''}`}
                                                            value={formData.account || ''}
                                                            onChange={handleChange}

                                                        />
                                                        <label className="ms-3" htmlFor="Conta">Conta</label>
                                                        {formErrors.account && (
                                                            <div className="fv-plugins-message-container invalid-feedback">{formErrors.account}</div>
                                                        )}
                                                    </div>

                                                    <div className="col-lg-2 fv-row fv-plugins-icon-container form-floating">
                                                        <input
                                                            name="account_digit"
                                                            placeholder="Dígito da Conta"
                                                            className={`form-control ${formErrors.account_digit ? ' is-invalid' : ''}`}
                                                            value={formData.account_digit || ''}
                                                            onChange={handleChange}

                                                        />
                                                        <label className="ms-3" htmlFor="account_digit">Dígito da Conta</label>
                                                        {formErrors.account_digit && (
                                                            <div className="fv-plugins-message-container invalid-feedback">{formErrors.account_digit}</div>
                                                        )}
                                                    </div>

                                                    <div className="col-lg-12 fv-row fv-plugins-icon-container form-floating">
                                                        <input
                                                            name="pix"
                                                            placeholder="Pix"
                                                            className={`form-control ${formErrors.pix ? ' is-invalid' : ''}`}
                                                            value={formData.pix || ''}
                                                            onChange={handleChange}

                                                        />
                                                        <label className="ms-3" htmlFor="pix">Pix</label>
                                                        {formErrors.pix && (
                                                            <div className="fv-plugins-message-container invalid-feedback">{formErrors.pix}</div>
                                                        )}
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="modal-footer border-0 mt-5">
                                                <button type="button" className="btn btn-light" onClick={closeModal}>Fechar</button>
                                                <button type="submit" className="btn btn-success me-4">
                                                    {/* <button type="submit" className="btn btn-success me-4" onClick={handlerSaveForm}> */}
                                                    Salvar
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}