import { Option } from "./I_General"

export interface ICardConfiguration {
    id: string | number
    className?: string
    title: string
    description?: string
    icon: string
    background?: string
    routes: string
}

export interface PixParams {
    chave: string
    cidade: string
    recebedor: string
    valor?: number
    identificador: string
    mensagem?: string
    cep?: string
}
export interface Gradient {

    fbColorStart: string
    fbColorEnd: string
    directionGradient: string
}

export interface IConfigurationPix {
    ativo?: boolean,
    chave: string,
    cidade?: string,
    recebedor?: string,
    identificador?: string,
    valor?: number,
    mensagem?: string,
    icone?: string,
    cep?: string,
    nivel?: string
    fbColorStart?: string
    fbColorEnd?: string
    directionGradient?: string
}

export interface PixFormParams extends PixParams {
    icon: string;
}

export type GradientColor = {
    start: string;
    end: string;
};

export interface IListRemessa {
    beneficiary: string;
    bankAccount: string;
    nameCliente: string;
    numberBoletos: string;
    createDate: string;
    numberRemessa: string;
    nameFile: string;
    status: string;
}

export interface IBank {
    id: string | number,

    // dados da conta

    number_enrollment?: string,
    type_enrollment?: string,

    beneficiary?: string,
    name_apresentation: string,
    sequence_our_number: string,
    sequence_remessa: string,
    agency: string,
    agency_dv: string,
    account: string,
    account_dv: string,
    beneficiary_code: string,
    code_beneficiario_dv: string,
    chave_pix: string,
    carteira: string,
    codigo_transmissao?: string,
    token_integracao_boletocloud?: string,
    message: string,
    status: string,
    active: string,

    bank_code: string | number,
    name_bank: string | number,

    // configuarções padrão

    fine_rate: string | number,
    interest_rate: string | number,
    protestar: string | number,
    prazo_protesto: string | number,
    days_discount: string | number,
    discount: string | number,
    baixar: string | number,
    prazo_baixar: string | number,
    days_multa: string | number,
    emissao_boleto: string | number,
    type_title: string,
    layout: string,
    situation_file: string,
}

export interface IBeneficiary {
    value: string;
    label: string;
}

export interface DrawePropsBank {
    closeDrawer: () => void;
    handlerSaveForm?: () => void;
    formValues: IBank;
    handleChange: (fieldName: keyof IBank, value: string | number) => void;
}

export const optionsStatusBank: Option[] = [
    { value: "2", field: 'active', label: "Inativo" },
    { value: "1", field: 'active', label: "Ativo" },

];

export const optionsBillingBanks: Option[] = [
    { value: "", field: 'bank_code', label: "Selecione" },
    { value: "033", field: 'bank_code', label: "Santander" },
    { value: "237", field: 'bank_code', label: "API BOLETO CLOUD - BRADESCO" },
    // { value: "104", field:'bank_code', label: "CAIXA ECONOMICA FEDERAL - CEF" },
    // { value: "237", field:'bank_code', label: "BRADESCO" },
    { value: "341", field: 'bank_code', label: "ITAÚ" },

];

export const optionsProtestarBanks: Option[] = [
    { value: "", field: '', label: "Selecione" },
    { value: "1", field: 'protestar', label: "Protestar com (Prazo) dias" },
    { value: "3", field: 'protestar', label: "Devolver após (Prazo) dias" },

];

export const optionsBaixarBanks: Option[] = [
    { value: "", field: '', label: "Selecione" },
    { value: "1", field: 'baixar', label: "(Baixar/ Devolver)" },
    { value: "2", field: 'baixar', label: "(Não Baixar / Não Devolver)" },

];

export const optionsEmissaoBoleto: Option[] = [
    { value: "", field: '', label: "Selecione" },
    { value: "1", field: 'emissao_boleto', label: "Emissao pelo banco" },
    { value: "2", field: 'emissao_boleto', label: "Emissao pelo beneficiario" },

];

export const optionsCarteiraBanks: Option[] = [
    { value: "", field: '', label: "Selecione" },
    { value: "SIGCB_COM_REGISTRO", field: 'carteira', label: "SIGCB 01 - Com registro" },
    { value: "SIGCB_SEM_REGISTRO", field: 'carteira', label: "SIGCB 02 - Sem registro" },
    { value: "SINCO_SEM_REGISTRO", field: 'carteira', label: "SINCO SR - Sem registro" },
    { value: "SICOB_10_COBRANCA_RAPIDA", field: 'carteira', label: "SICOB CR - Cobrança rápida, nosso número com 10 dígitos" },
    { value: "SICOB_10_SEM_REGISTRO_80", field: 'carteira', label: "SICOB SR - Nº 80 Sem registro, nosso número com 10 dígitos" },
    { value: "SICOB_10_SEM_REGISTRO_81", field: 'carteira', label: "SICOB SR - Nº 81 Sem registro, nosso número com 10 dígitos" },
    { value: "SICOB_10_SEM_REGISTRO_82", field: 'carteira', label: "SICOB SR - Nº 82 Sem registro, nosso número com 10 dígitos" },
    { value: "SICOB_16_SEM_REGISTRO", field: 'carteira', label: "SICOB SR - Sem registro, nosso número com 16 dígitos" },

];


export const optionsTitulosBanks: Option[] = [
    { value: "", field: '', label: "Selecione" },
    { value: "AP", field: 'type_title', label: "AP - APÓLICE DE SEGURO" },
    { value: "BDP", field: 'type_title', label: "BDP - BOLETO DE PROPOSTA" },
    { value: "CC", field: 'type_title', label: "CC - CARTÃO DE CRÉDITO" },
    { value: "CH", field: 'type_title', label: "CH - CHEQUE" },
    { value: "CPR", field: 'type_title', label: "CPR - CÉDULA DE PRODUTO RURAL" },
    { value: "DAE", field: 'type_title', label: "DAE - DÍVIDA ATIVA DE ESTADO" },
    { value: "DAM", field: 'type_title', label: "DAM - DÍVIDA ATIVA DE MUNICÍPIO" },
    { value: "DAU", field: 'type_title', label: "DAU - DÍVIDA ATIVA DA UNIÃO" },
    { value: "DD", field: 'type_title', label: "DD - DOCUMENTO DE DÍVIDA" },
    { value: "DM", field: 'type_title', label: "DM - DUPLICATA MERCANTIL" },
    { value: "DMI", field: 'type_title', label: "DMI - DUPLICATA MERCANTIL PARA INDICAÇÃO" },
    { value: "DR", field: 'type_title', label: "DR - DUPLICATA RURAL" },
    { value: "DS", field: 'type_title', label: "DS - DUPLICATA DE SERVIÇO" },
    { value: "DSI", field: 'type_title', label: "DSI - DUPLICATA DE SERVIÇO PARA INDICAÇÃO" },
    { value: "EC", field: 'type_title', label: "EC - ENCARGOS CONDOMINIAIS" },
    { value: "FAT", field: 'type_title', label: "FAT - FATURA" },
    { value: "LC", field: 'type_title', label: "LC - LETRA DE CÂMBIO" },
    { value: "ME", field: 'type_title', label: "ME - MENSALIDADE ESCOLAR" },
    { value: "NCC", field: 'type_title', label: "NCC - NOTA DE CRÉDITO COMERCIAL" },
    { value: "NCE", field: 'type_title', label: "NCE - NOTA DE CRÉDITO À EXPORTAÇÃO" },
    { value: "NCI", field: 'type_title', label: "NCI - NOTA DE CRÉDITO INDUSTRIAL" },
    { value: "NCR", field: 'type_title', label: "NCR - NOTA DE CRÉDITO RURAL" },
    { value: "ND", field: 'type_title', label: "ND - NOTA DE DÉBITO" },
    { value: "NF", field: 'type_title', label: "NF - NOTA FISCAL" },
    { value: "NP", field: 'type_title', label: "NP - NOTA PROMISSÓRIA" },
    { value: "NPR", field: 'type_title', label: "NPR - NOTA PROMISSÓRIA RURAL" },
    { value: "NS", field: 'type_title', label: "NS - NOTA DE SEGURO" },
    { value: "O", field: 'type_title', label: "O - OUTROS" },
    { value: "PC", field: 'type_title', label: "PC - PARCELA DE CONSÓRCIO" },
    { value: "RC", field: 'type_title', label: "RC - RECIBO" },
    { value: "TM", field: 'type_title', label: "TM - TRIPLICATA MERCANTIL" },
    { value: "TS", field: 'type_title', label: "TS - TRIPLICATA DE SERVIÇO" },
    { value: "W", field: 'type_title', label: "W - WARRANT" },

];