import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { permissionsList } from './permissionList';

// Estado inicial
const initialState = {
    permissionsList: [] as string[],
    routesMenu: [] as any[],
    allowedsPermission: [] as string[], // Adicionado para compatibilidade com ComponentPermission
    roles: '',
    currentRoute: '',
    loading: false,
    error: null as string | null,
};

// Tipo para o estado
type PermissionState = typeof initialState;

const permissionSlice = createSlice({
    name: 'permissions',
    initialState,
    reducers: {
        setRoutesMenu(state, action: PayloadAction<any[]>) {
            state.routesMenu = action.payload;
        },
        setRoles(state, action: PayloadAction<string>) {
            state.roles = action.payload;
        },
        setCurrentRoute(state, action: PayloadAction<string>) {
            state.currentRoute = action.payload;
        },
    },
    extraReducers: (builder) => {
        // Adicione um caso para quando o RTK Query atualizar os dados
        builder.addMatcher(
            permissionsList.endpoints.fetchPermission.matchFulfilled,
            (state, action) => {
                // Atualize o estado com os dados do RTK Query
                if (action.payload.permissionsList) {
                    state.permissionsList = action.payload.permissionsList;
                }
                if (action.payload.allowedsPermission) {
                    state.allowedsPermission = action.payload.allowedsPermission;
                }
                if (action.payload.routesMenu) {
                    state.routesMenu = action.payload.routesMenu;
                }
                state.loading = false;
            }
        );
    },
});

export const { setRoutesMenu, setRoles, setCurrentRoute } = permissionSlice.actions;
export default permissionSlice.reducer;