import { Navigate, Outlet, Route, Routes } from "react-router-dom"
import { AccountsBilling } from "../../../../app/pages/configurations/bank/accountsBilling/AccountsBilling"
import { Beneficiary } from "../../../../app/pages/configurations/bank/beneficiary/Beneficiary"
import { PageLink, PageTitle } from "../../../layout/core"
import { TabHeaderBillingBank } from "./TabHeaderBillingBank"
import * as authHelper from './../../../../app/modules/auth/core/AuthHelpers'
const uuid = authHelper.getAuthUui() || '';
const lastUuid = authHelper.getAuthLastUui() || '';

const profileBreadCrumbs: Array<PageLink> = [
    {
        title: 'Conta',
        path: `configuration/access/${uuid || lastUuid}`,
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const TabRouterBillingBank = () => {
    // const location = useLocation();
    const uuid = authHelper.getAuthUui();

    return (
        <Routes>
            <Route
                element={
                    <>
                        <TabHeaderBillingBank />
                        <Outlet />
                    </>
                }
            >
                <Route
                    path={`bank/${uuid}`}
                    element={
                        <>
                            <PageTitle breadcrumbs={profileBreadCrumbs} >Contas</PageTitle>
                            <AccountsBilling />
                        </>
                    }
                />

                <Route
                    path={`beneficiary/${uuid}`}
                    element={
                        <>
                            <PageTitle breadcrumbs={profileBreadCrumbs} >Beneficiario</PageTitle>
                            <Beneficiary />
                        </>
                    }
                />

                <Route
                    path={`boleto/${uuid}`}
                    element={
                        <>
                            <PageTitle breadcrumbs={profileBreadCrumbs} >Boleto</PageTitle>
                            {/* <ConfigurationPix/> */}
                        </>
                    }
                />

                <Route index element={<Navigate to={`accountsBilling`} />} />
                {/* <Route path="*" element={<div>404 Not Found</div>} /> */}
            </Route>
        </Routes>
    )
}

export default TabRouterBillingBank