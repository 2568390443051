/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { getUserByToken, login, validaUuid } from '../core/_requests'
import { useAuth } from '../core/Auth'
import usePermissionStore from '../../../zustand/PermissionStore'
import { permissionsUser } from '../../../request/permission'

import * as authHelper from './../../../modules/auth/core/AuthHelpers';
import { ApiResponseEmpresa, I_Empresa } from '../../../interface/I_Empresa'
import useUuiValidator from '../../../../_metronic/layout/UuidValidator'
import useHashRoute from '../../../../_metronic/layout/UuidValidator'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../redux/store'
// import { fetchPermissions } from '../../../redux/api/permission/permissionSlice'
// import { useHashMonitorContext } from '../../../../_metronic/layout/HashMonitorProvider'

const loginSchema = Yup.object().shape({
    email: Yup.string()
        .email('Formato de e-mail incorreto')
        .min(3, 'Mínimo 3 símbolos')
        .max(50, 'Máximo de 50 símbolos')
        .required('O e-mail é obrigatório'),
    password: Yup.string()
        .min(3, 'Mínimo 3 símbolos')
        .max(50, 'Máximo de 50 símbolos')
        .required('A senha é obrigatória'),
})

const initialValues = {
    email: '',
    password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {

    const navigate = useNavigate();

    // authHelper.removeAuth();
    // authHelper.removeAuthUui();

    const [loading, setLoading] = useState(false)
    const { saveAuth, setCurrentUser } = useAuth()
    // const [loginSuccess, setLoginSuccess] = useState(false)

    const { uui } = useParams();

    const { isValid, assinante, isLoading, message } = useHashRoute();
    // const dispatch = useDispatch<AppDispatch>();
    // const {
    //     setPermissionsList,
    //     setRoutesMenu,
    //     setRoles,
    //     routesMenu,
    //     setEmpresaAssinante,
    //     fetchAndUpdatePermissions,
    //     setCurrentRoute
    // } = usePermissionStore();

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values: any, { setStatus, setSubmitting }: any) => {
            setLoading(true)

            try {
                setTimeout(async () => {
                    const { data: auth } = await login(values.email, values.password, uui);
                    saveAuth(auth)

                    // saveAuthUui({ uui: uui });

                    // Despachar a ação setFilter com os dados do filtro
                    // dispatch(setAuthSlice({
                    //   api_token,
                    //   refreshToken: refreshToken || ''
                    // }));


                    // (() => {
                    //     routesMenu.length
                    // })()

                    const { data: user } = await getUserByToken(auth.api_token)
                    setCurrentUser(user);
                    // await dispatch(fetchPermissions());
                    // if (!auth.empresa) {
                    //     setEmpresaAssinante(auth?.empresa);
                    // }

                    // await fetchAndUpdatePermissions();
                    // setCurrentRoute('dashboard');
                    navigate(`dashboard/access/${uui}`);
                    // setLoginSuccess(true);

                }, 1000);


            } catch (error) {
                console.error(error)
                saveAuth(undefined)
                setStatus('Os detalhes de login estão incorretos ou uui invalido')
                setSubmitting(false)
                setLoading(false)
            }
        },
    });

    // useEffect(() => {
    //     if (loginSuccess) {
    //         // Garantir que as permissões sejam atualizadas após o login
    //         usePermissionStore.getState().fetchAndUpdatePermissions().then(() => {
    //             // console.log("Permissões atualizadas com sucesso após login");
    //             navigate(`dashboard/access/${uui}`);
    //         }).catch(error => {
    //             console.error("Erro ao atualizar permissões:", error);
    //         });
    //     }
    // }, [loginSuccess, uui, navigate]);


    return (
        <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
        >
            {/* begin::Heading */}
            <div className='text-center mb-11'>
                <h1 className='text-dark fw-bolder mb-3'>Login</h1>
                {/* <div className='text-gray-500 fw-semibold fs-6'>Financeiro</div> */}
            </div>

            {/* {isValid && ( */}
            <div
                className={`alert ${isValid ? 'alert-success' : 'alert-danger'
                    }`}
            >
                <span>{message}!</span>
                <span>{
                    !!assinante?.empresa && (
                        <>
                            <br />
                            <span> "{assinante?.empresa}".</span>
                            <br />
                            <span> Entre com seu email e senha</span>
                        </>
                    )
                }</span>

            </div>
            {/* )} */}
            {/* begin::Heading */}

            {/* {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
            Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
            continue.
          </div>
        </div>
      )} */}

            {/* begin::Form group */}
            <div className='fv-row mb-8'>
                <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
                <input
                    placeholder='Email'
                    {...formik.getFieldProps('email')}
                    className={clsx(
                        'form-control bg-transparent',
                        { 'is-invalid': formik.touched.email && formik.errors.email },
                        {
                            'is-valid': formik.touched.email && !formik.errors.email,
                        }
                    )}
                    type='email'
                    name='email'
                    autoComplete='off'
                />
                {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.email}</span>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-3'>
                <label className='form-label fw-bolder text-dark fs-6 mb-0'>Senha</label>
                <input
                    type='password'
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                        'form-control bg-transparent',
                        {
                            'is-invalid': formik.touched.password && formik.errors.password,
                        },
                        {
                            'is-valid': formik.touched.password && !formik.errors.password,
                        }
                    )}
                />
                {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.password}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Wrapper */}
            <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
                <div />

                {/* begin::Link */}
                {/* <Link to='/auth/forgot-password' className='link-primary'>
          Forgot Password ?
        </Link> */}
                {/* end::Link */}
            </div>
            {/* end::Wrapper */}

            {/* begin::Action */}
            <div className='d-grid mb-10'>
                <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-primary'
                    disabled={formik.isSubmitting || !formik.isValid || !isValid}
                >
                    {!loading && <span className='indicator-label'>Continue</span>}
                    {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            Por favor aguarde...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            </div>
            {/* end::Action */}

            <div className='text-gray-500 text-center fw-semibold fs-6'>
                {/* Not a Member yet?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Sign up
        </Link> */}
            </div>
        </form>
    )
}
