import React, { useState, useEffect } from 'react';
import { getContractByName } from '../../../../app/request/contract';
import { getClientByName } from '../../../../app/request/client';
import { getCorretorByName } from '../../../../app/request/corretor';
import { getUnitByName } from '../../../../app/request/unit';
import { useAuth } from '../../../../app/modules/auth';

interface Suggestion {
    id: string;
    value: string;
}

interface TagifyProps {
    onSelectTagify: (e: any, endPoint: string) => void;
    selectedId?: string;
    selectedName?: string;
    endPoint: string;
    className?: string;
    reset?: boolean;
}

export const TagifyCustom = ({ onSelectTagify, reset, selectedId = '', selectedName = '', endPoint, className }: TagifyProps) => {
    const [inputValue, setInputValue] = useState<string>('');
    const [tags, setTags] = useState<Suggestion[]>(selectedName ? [{ id: selectedId, value: selectedName }] : []);
    const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
    const [showDropdown, setShowDropdown] = useState(false);


    const { auth } = useAuth();

    const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setInputValue(value);

        if (value.length > 0) {
            const searchResults = await handleSearch(value);
            setSuggestions(searchResults);
            setShowDropdown(true);
        } else {
            setSuggestions([]);
            setShowDropdown(false);
        }
    };

    const handleSearch = async (value: string): Promise<Suggestion[]> => {
        if (!value) return [];

        let response;
        if (auth && auth.api_token) {
            try {
                switch (endPoint) {
                    case 'contract':
                        response = await getContractByName(value, auth);
                        break;
                    case 'client':
                        response = await getClientByName(value, auth);
                        break;
                    case 'corretor':
                        response = await getCorretorByName(value, auth);
                        break;
                    case 'unit':
                        response = await getUnitByName(value, auth);
                        break;
                    default:
                        return [];
                }

                if (!response) return [];

                if (!response?.data) return [];

                const responseData = Array.isArray(response.data.data)
                    ? response.data.data
                    : Array.isArray(response.data)
                        ? response.data
                        : [];

                return responseData.map(row => ({
                    id: row.id.toString(),
                    value: row.name
                }));

            } catch (error) {
                console.error('Error fetching suggestions:', error);
                return [];
            }
        }
        return [];
    };

    const handleSelect = (suggestion: Suggestion) => {
        setTags([suggestion]);
        setInputValue('');
        setShowDropdown(false);

        const target = {
            name: `id_${endPoint}`,
            value: suggestion.id,
            innerText: suggestion.value
        };

        onSelectTagify({ target }, endPoint);
    };

    const handleRemoveTag = () => {
        setTags([]);

        const target = {
            name: `id_${endPoint}`,
            value: '',
            innerText: ''
        };

        onSelectTagify({ target }, endPoint);
    };


    useEffect(() => {
        if (reset) {
            setTags([]);
            setInputValue('');
            setShowDropdown(false);

            // Disparar o evento de limpeza para o componente pai
            const target = {
                name: `id_${endPoint}`,
                value: '',
                innerText: ''
            };
            onSelectTagify({ target }, endPoint);
        }
    }, [reset]); // Certifique-se de incluir todas as dependências

    return (
        <div className={`position-relative ${className}`}>
            <div className="d-flex flex-wrap gap-2 border rounded bg-light">
                {tags.map((tag: Suggestion, index: number) => (
                    <span key={index} className="bg-primary text-white px-2 py-1 rounded d-flex align-items-center">
                        {tag.value}
                        <button onClick={handleRemoveTag} className="ms-2 text-white border-0 bg-transparent">
                            <span className=''>&times;</span>
                        </button>
                    </span>
                ))}

                {tags.length === 0 && (
                    <input
                        type="text"
                        value={inputValue}
                        onChange={handleInputChange}
                        className="form-control border-0 flex-grow-1"
                        placeholder="Digite algo.."
                    />
                )}
            </div>

            {showDropdown && suggestions.length > 0 && (
                <ul
                    className="position-absolute w-100 mt-1 border rounded shadow bg-white overflow-auto"
                    style={{
                        maxHeight: '240px',
                        zIndex: 1050,
                        padding: '0.5rem 0',
                        listStyle: 'none',
                    }}
                >
                    {suggestions.map((suggestion: Suggestion, index: number) => (
                        <li
                            key={index}
                            onClick={() => handleSelect(suggestion)}
                            className="px-3 py-2 d-flex align-items-center"
                            style={{
                                cursor: 'pointer',
                                transition: 'background 0.2s ease-in-out',
                            }}
                            onMouseEnter={(e) => (e.currentTarget.style.background = '#F3F6F9')}
                            onMouseLeave={(e) => (e.currentTarget.style.background = 'transparent')}
                        >
                            {suggestion.value}
                        </li>
                    ))}
                </ul>
            )}
        </div>

    );
};
