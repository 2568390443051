import { useState, useEffect } from 'react';
import axios from 'axios';
import { ApiResponseEmpresa, Assinante } from '../../app/interface/I_Empresa';
import { validaUuid } from '../../app/modules/auth/core/_requests';

export interface HashInfo {
    uui: string | null;
    message: string;
    isValid: boolean;
    assinante: Assinante | null;
    isLoading: boolean;
    error: string | null;
}

// Correct API URL construction
const API_URL = `${process.env.REACT_APP_API_URL}/module/validaUuid`;

/**
 * Hook personalizado para gerenciar o hash UUID na URL e sua validação
 * @returns {Object} Objeto contendo informações sobre o hash e funções relacionadas
 */
const useHashRoute = () => {
    const [hashInfo, setHashInfo] = useState<HashInfo>({
        uui: null,
        message: '',
        isValid: false,
        assinante: null,
        isLoading: true,
        error: null
    });

    // Extrai o hash UUID da URL atual
    const extractHashFromUrl = (): string | null => {
        const url = window.location.pathname;
        const segments = url.split('/').filter(segment => segment);

        // Verificamos se o último segmento parece ser um UUID
        const lastSegment = segments[segments.length - 1];
        const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

        if (lastSegment && uuidPattern.test(lastSegment)) {
            return lastSegment;
        }

        return null;
    };

    // Salva o hash UUID no localStorage
    const saveHashToStorage = (hash: string, assinante: any) => {
        localStorage.setItem(hash, JSON.stringify(assinante));
        localStorage.setItem('auth-uui', JSON.stringify(hash));
    };

    // Verifica se o hash UUID já existe no localStorage
    const hashExistsInStorage = (hash: string): boolean => {
        return localStorage.getItem(hash) !== null;
    };

    // Valida o hash UUID no backend
    const validateHash = async (hash: string): Promise<{
        isValid: boolean;
        message: string;
        uui: string;
    }> => {
        try {
            setHashInfo(prev => ({ ...prev, isLoading: true, error: null }));

            const response = await validaUuid(hash);
            const data: ApiResponseEmpresa = response;

            if (data.isOk) {
                // Se for válido, salvamos no storage
                saveHashToStorage(hash, {
                    uui: data.uui,
                    ...data.assinante || null,
                });

                // Atualizamos o estado
                setHashInfo({
                    uui: data.uui,
                    message: 'Token validado com sucesso',
                    isValid: true,
                    assinante: data.assinante || null,
                    isLoading: false,
                    error: null
                });

                // if (hashInfo.assinante?.cadastro) {
                //     localStorage.setItem(hashInfo.assinante?.cadastro, JSON.stringify(hashInfo.assinante));
                // }

                return {
                    isValid: true,
                    message: "Hash válido",
                    uui: hash
                };
            } else {
                // Se não for válido
                setHashInfo({
                    uui: hash,
                    message: 'Token inválido',
                    isValid: false,
                    assinante: null,
                    isLoading: false,
                    error: 'Hash inválido'
                });

                return {
                    isValid: false,
                    message: "Hash inválido",
                    uui: hash
                };
            }
        } catch (error: any) {
            // Em caso de erro na requisição
            setHashInfo({
                uui: hash,
                message: 'Erro ao tentar vaidar token',
                isValid: false,
                assinante: null,
                isLoading: false,
                error: error.message || 'Erro ao validar hash'
            });

            return {
                isValid: false,
                message: error.message || 'Erro ao validar hash',
                uui: hash
            };
        }
    };

    // Função principal para processar a rota atual
    const processCurrentRoute = async (): Promise<{
        isValid: boolean;
        message: string;
        uui: string | null;
    }> => {
        const hash = extractHashFromUrl();

        if (!hash) {
            setHashInfo({
                uui: null,
                message: 'Token não encontrado na URL',
                isValid: false,
                assinante: null,
                isLoading: false,
                error: 'Hash não encontrado na URL'
            });

            return {
                isValid: false,
                message: 'Hash não encontrado na URL',
                uui: null
            };
        }

        // Verifica se estamos na rota de autenticação
        const isAuthRoute = window.location.pathname.includes('/auth/');

        if (isAuthRoute) {
            // Se já existe no storage, usamos diretamente
            if (hashExistsInStorage(hash)) {
                setHashInfo({
                    uui: hash,
                    message: 'Token válido',
                    isValid: true,
                    assinante: null, // Será atualizado após a validação
                    isLoading: false,
                    error: null
                });

                // Mesmo assim validamos novamente com o backend para atualizar o ambiente
                return await validateHash(hash);
            } else {
                // Se não existe, validamos com o backend
                return await validateHash(hash);
            }
        } else {
            // Para outras rotas, apenas extraímos o hash
            setHashInfo({
                uui: hash,
                message: 'Token válido',
                isValid: hashExistsInStorage(hash), // Consideramos válido se estiver no storage
                assinante: null,
                isLoading: false,
                error: null
            });

            // Podemos validar silenciosamente para garantir
            if (hashExistsInStorage(hash)) {
                return await validateHash(hash);
            }

            return {
                isValid: hashExistsInStorage(hash),
                message: hashExistsInStorage(hash) ? 'Hash encontrado no storage' : 'Hash não validado',
                uui: hash
            };
        }
    };

    // Processa a rota quando o componente monta ou a URL muda
    useEffect(() => {
        processCurrentRoute();

        // Adicionamos um listener para mudanças na URL (história do navegador)
        const handleLocationChange = () => {
            processCurrentRoute();
        };

        window.addEventListener('popstate', handleLocationChange);

        return () => {
            window.removeEventListener('popstate', handleLocationChange);
        };
    }, []);

    return {
        ...hashInfo,
        validateHash,
        refreshRoute: processCurrentRoute,
        getStoredHash: (hash: string) => localStorage.getItem(hash)
    };
};

export default useHashRoute;

// http://localhost:8080/module/validaUuid/cefe65db-1080-437f-b4ae-ba74aaafac64