import axios, { AxiosError, AxiosResponse } from "axios";
import Swal from "sweetalert2";
import BaseServiceHttp from "../../../_metronic/helpers/functions/http/BaseServiceHttp";
import { IBank, IConfigurationPix } from "../../interface/I_Configurations";
import { AuthType, IResponse } from "../../interface/I_General";

import { getToken } from '../../redux/api/auth/getToken';
import * as authHelper from '../../modules/auth/core/AuthHelpers';

// URLs
const API_URL = process.env.REACT_APP_API_URL

// retorna a configuração do pix
export async function getConfigurationPix() {
    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';
    const dataLocalStorage = getToken();

    const authorization_barear = {
        headers: {
            Authorization: `Bearer ${dataLocalStorage?.token}`,
            'X-UUID-Assinante': uuid || lastUuid
        }
    };


    try {
        const response = await axios.get<IConfigurationPix>(`${API_URL}/module/getConfigurationPix`, authorization_barear);

        return response;
    } catch (error) {
        // Tratar o erro aqui
        console.error("Ocorreu um erro :", error);
        throw error;
    }

}


// envia as configurações do pix
export async function postConfigurationPix(data: IConfigurationPix) {
    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';
    const dataLocalStorage = getToken();

    const authorization_barear = {
        headers: {
            Authorization: `Bearer ${dataLocalStorage?.token}`,
            'X-UUID-Assinante': uuid || lastUuid
        }
    };


    try {
        const response = await axios.post<IConfigurationPix>(`${API_URL}/module/postConfigurationPix`, { data }, authorization_barear);
        // Tratar a resposta aqui
        return response;
    } catch (error) {
        // Tratar o erro aqui
        console.log("Ocorreu um erro ao enviar :", error);
        throw error;
    }

}

export const queryFileRemessa = async (path_cloud: string, auth: AuthType) => {

    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';

    try {

        let headers = {};
        if (auth && auth.api_token) {
            headers = {
                Authorization: `Bearer ${auth.api_token}`,
                'X-UUID-Assinante': uuid || lastUuid
            };
        }

        const response = await axios.post<IResponse>(`${API_URL}/module/queryFileRemessa`,
            { path_cloud },
            { headers }
        );

        return response;
    } catch (error) {
        throw error;
    }
}


export const updateLastDownloadRemessa = async (id: string | number, auth: AuthType) => {

    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';


    try {

        let headers = {};
        if (auth && auth.api_token) {
            headers = {
                Authorization: `Bearer ${auth.api_token}`,
                'X-UUID-Assinante': uuid || lastUuid
            };
        }

        const response = await axios.post<IResponse>(`${API_URL}/module/updateLastDownloadRemessa`,
            { id },
            { headers }
        );

        return response;
    } catch (error) {
        throw error;
    }
}


export const gerarRemessaApi = async (auth: any) => {

    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';

    try {

        let headers = {};
        if (auth && auth.api_token) {
            headers = {
                Authorization: `Bearer ${auth.api_token}`,
                'X-UUID-Assinante': uuid || lastUuid
            };
        }

        const response = await axios.post<IResponse>(`${API_URL}/module/gerarRemessa`, {}, { headers });

        return response;
    } catch (error) {
        throw error;
    }

}


export const confirmationSendRemessaToBank = async (data: any, auth: any) => {

    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';

    try {

        let headers = {};
        if (auth && auth.api_token) {
            headers = {
                Authorization: `Bearer ${auth.api_token}`,
                'X-UUID-Assinante': uuid || lastUuid
            };
        }

        const response = await axios.post<IResponse>(`${API_URL}/module/confirmationSendRemessaToBank`,
            { data },
            { headers }
        );

        return response;
    } catch (error) {
        throw error;
    }

}

// envia a planilha para dar baixa nas parcelas
export const paidParcelInSpreadSheet = async (data: any, auth: AuthType) => {

    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';

    try {

        let headers = {};
        if (auth && auth.api_token) {
            headers = {
                Authorization: `Bearer ${auth.api_token}`,
                'X-UUID-Assinante': uuid || lastUuid
            };
        }

        const response = await axios.post(`${API_URL}/module/upload/paidParcelInSpreadSheet`,
            { data },
            { headers }
        );

        return response;
    } catch (error) {
        throw error;
    }
}

// insere um cliente
export async function authGroups() {

    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';
    const dataLocalStorage = getToken();


    const customConfig: any = {
        method: 'get',
        url: `/module/authGroups`,
        headers: {
            Authorization: `Bearer ${dataLocalStorage?.token}`,
            'X-UUID-Assinante': uuid || lastUuid,
        },
    };

    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
        .then((response: AxiosResponse<any>) => {
            return response;
        })
        .catch((error: AxiosError) => {
            // throw error; // Rejeita a Promise para propagar o erro
            const { response } = error;
            let statusResponse: any;
            if (response) {
                const { status, data } = response;
                statusResponse = status;
            }

            return {
                data: {
                    success: false,
                    data: [],
                    status: statusResponse,
                    message: 'Ocorreu um erro ao tentar buscar por funções'
                }
            }
        });
    return response;

}