import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Interfaces para cada tipo de filtro
interface ContractFilter {
    id_client: string;
    id_contract: string;
    id_unit: string;
    status: string;
    // date_start_payment: string;
    // date_end_payment: string;
    // date_start_contract: string;
    // date_end_contract: string;
}

interface PaymentsFilter {
    type_payment: string;
    id_contract: string;
    id_client: string;
    start_due_date: string;
    end_due_date: string;
    start_date_contract: string;
    end_date_contract: string;
    start_payment_date: string;
    end_payment_date: string;
    value: string;
    status_contract: string;
    form_payment: string;
    date_payment: string;
    assessment: string;
    boleto: string;
    or_number: string;
    possui_nota: string;
}

interface ParcelFilter {
    id_product?: string;
    id_client?: string;
    status: string;
    start_due_date: string;
    end_due_date: string;
    start_date_payment: string;
    end_date_payment: string;
    type_parcel: string;
    form_payment: string;
    nota_fiscal: string;
    boleto?: string;
}

interface ProductFilter {
    id_product: string;
}

interface ProductPlanFilter {
    type_plan: string;
}

interface UnitFilter {
    id_unit: string;
}

interface ClientFilter {
    id_client: string;
}

interface DashboardFilter {
    id_product: string;
    start_date: string;
    end_date: string;
    status: string;
}

// Interface para o estado completo
interface FilterState {
    contract: ContractFilter;
    payment: PaymentsFilter;
    parcel: ParcelFilter;
    product: ProductFilter;
    product_plan: ProductPlanFilter;
    unit: UnitFilter;
    dashboard: DashboardFilter;
    client: ClientFilter;
}

// Estado inicial
const initialState: FilterState = {
    contract: {
        id_client: '',
        id_contract: '',
        id_unit: '',
        status: '',
        // date_start_payment: '',
        // date_end_payment: '',
        // date_start_contract: '',
        // date_end_contract: ''
    },
    payment: {
        type_payment: '',
        id_contract: '',
        id_client: '',
        start_due_date: '',
        end_due_date: '',
        start_date_contract: '',
        end_date_contract: '',
        start_payment_date: '',
        end_payment_date: '',
        value: '',
        status_contract: '',
        form_payment: '',
        date_payment: '',
        assessment: '',
        boleto: '',
        or_number: '',
        possui_nota: ''
    },
    parcel: {
        id_product: '',
        id_client: '',
        status: '',
        start_due_date: '',
        end_due_date: '',
        start_date_payment: '',
        end_date_payment: '',
        type_parcel: '',
        form_payment: '',
        nota_fiscal: ''
    },
    product: {
        id_product: '',
    },
    product_plan: {
        type_plan: '',
    },
    unit: {
        id_unit: ''
    },
    dashboard: {
        id_product: '',
        start_date: '',
        end_date: '',
        status: ''
    },
    client: {
        id_client: '',
    },
};

// Tipos para as ações
type SetContractFilterPayload = Partial<ContractFilter>;
type SetPaymentsFilterPayload = Partial<PaymentsFilter>;
type SetParcelFilterPayload = Partial<ParcelFilter>;
type SetProductFilterPayload = Partial<ProductFilter>;
type SetProductPlanFilterPayload = Partial<ProductPlanFilter>;
type SetUnitFilterPayload = Partial<UnitFilter>;
type SetDashboardFilterPayload = Partial<DashboardFilter>;
type SetClientFilterPayload = Partial<ClientFilter>;

const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        // Ações específicas para cada categoria
        setContractFilter: (state, action: PayloadAction<SetContractFilterPayload>) => {

            state.contract = {
                ...state.contract,
                ...action.payload
            };
        },

        setPaymentsFilter: (state, action: PayloadAction<SetPaymentsFilterPayload>) => {
            state.payment = {
                ...state.payment,
                ...action.payload
            };
        },

        setParcelFilter: (state, action: PayloadAction<SetParcelFilterPayload>) => {
            state.parcel = {
                ...state.parcel,
                ...action.payload
            };
        },

        setProductFilter: (state, action: PayloadAction<SetProductFilterPayload>) => {
            state.product = {
                ...state.product,
                ...action.payload
            };
        },

        setProductPlanFilter: (state, action: PayloadAction<SetProductPlanFilterPayload>) => {
            state.product_plan = {
                ...state.product_plan,
                ...action.payload
            };
        },

        setUnitFilter: (state, action: PayloadAction<SetUnitFilterPayload>) => {
            state.unit = {
                ...state.unit,
                ...action.payload
            };
        },

        setDashboardFilter: (state, action: PayloadAction<SetDashboardFilterPayload>) => {

            state.dashboard = {
                ...state.dashboard,
                ...action.payload
            };
        },
        setClientFilter: (state, action: PayloadAction<SetClientFilterPayload>) => {
            state.client = {
                ...state.client,
                ...action.payload
            };
        },

        // Resetar categoria específica
        resetContractFilter: (state) => {
            state.contract = initialState.contract;
        },

        resetPaymentsFilter: (state) => {
            state.payment = initialState.payment;
        },

        resetParcelFilter: (state) => {
            state.parcel = initialState.parcel;
        },

        resetProductFilter: (state) => {
            state.product = initialState.product;
        },

        resetProductPlanFilter: (state) => {
            state.product_plan = initialState.product_plan;
        },

        resetUnitFilter: (state) => {
            state.unit = initialState.unit;
        },

        resetDashboardFilter: (state) => {
            state.dashboard = initialState.dashboard;
        },

        resetClientFilter: (state) => {
            state.client = initialState.client;
        },

        // Resetar todos os filtros
        resetAllFilters: () => initialState
    },
});

export const {
    setContractFilter,
    setParcelFilter,
    setPaymentsFilter,
    setProductFilter,
    setProductPlanFilter,
    setUnitFilter,
    setDashboardFilter,
    setClientFilter,
    resetContractFilter,
    resetPaymentsFilter,
    resetParcelFilter,
    resetProductFilter,
    resetProductPlanFilter,
    resetUnitFilter,
    resetDashboardFilter,
    resetClientFilter,
    resetAllFilters
} = filterSlice.actions;

export default filterSlice.reducer;

// Função auxiliar para a API antiga (compatibilidade)
export const setFilter = (payload: {
    contract?: Partial<ContractFilter>,
    payment?: Partial<PaymentsFilter>,
    parcel?: Partial<ParcelFilter>,
    product?: Partial<ProductFilter>,
    product_plan?: Partial<ProductPlanFilter>
    unit?: Partial<UnitFilter>
    dashboard?: Partial<DashboardFilter>
    client?: Partial<ClientFilter>
}) => {
    return (dispatch: any) => {
        if (payload.contract) {
            dispatch(setContractFilter(payload.contract));
        }
        if (payload.payment) {
            dispatch(setPaymentsFilter(payload.payment));
        }
        if (payload.parcel) {
            dispatch(setParcelFilter(payload.parcel));
        }
        if (payload.product) {
            dispatch(setProductFilter(payload.product));
        }
        if (payload.product_plan) {
            dispatch(setProductPlanFilter(payload.product_plan));
        }
        if (payload.unit) {
            dispatch(setUnitFilter(payload.unit));
        }
        if (payload.dashboard) {
            dispatch(setDashboardFilter(payload.dashboard));
        }
        if (payload.client) {
            dispatch(setClientFilter(payload.client));
        }
    };
};

export const resetFilterCategory = (category: keyof FilterState) => {
    return (dispatch: any) => {
        switch (category) {
            case 'contract':
                dispatch(resetContractFilter());
                break;
            case 'payment':
                dispatch(resetPaymentsFilter());
                break;
            case 'parcel':
                dispatch(resetParcelFilter());
                break;
            case 'product':
                dispatch(resetProductFilter());
                break;
            case 'product_plan':
                dispatch(resetProductPlanFilter());
                break;
            case 'unit':
                dispatch(resetUnitFilter());
                break;
            case 'dashboard':
                dispatch(resetDashboardFilter());
                break;
            case 'client':
                dispatch(resetClientFilter());
                break;
        }
    };
};