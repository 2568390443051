import { useCallback, useState } from "react";
import Swal from "sweetalert2";
import { FormInsertProduct, FormProductProps } from "../../../../app/interface/I_Product";
import { schemaInsertProduc } from "../../../../app/pages/product/SchemaProduct";
import { KTSVG } from "../../../helpers";
import * as Yup from 'yup';
import { useAuth } from "../../../../app/modules/auth";
import { saveProduct } from "../../../../app/request/product";
import Select from "react-select";
import { InputActionMeta } from "react-select";

export const InsertProduct: React.FC<FormProductProps> = ({
    formValues,
    closeModal,
    onFormSubmit
}) => {

    const { auth, logout, setCurrentUser } = useAuth();

    const [formData, setFormData] = useState<FormInsertProduct>(formValues || {
        name: '',
        product_plan: 'H',
    });

    const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({
        name: '',
        product_plan: '',
    });

    interface Option {
        value: string;
        label: string;
    }

    const optionTypeProduct: Option[] = [
        { value: "H", label: "Terreno" },
        { value: "V", label: "Prédio" },
    ];

    const handleInputChange = (
        value: string,
        fieldName: keyof FormInsertProduct
    ) => {

        setFormData(prevState => ({
            ...prevState,
            [fieldName]: value
        }));
    };

    const handleSelectChange = <K extends keyof FormInsertProduct>(selectedOption: Option | null,
        fieldName: K) => {
        const value = selectedOption ? selectedOption.value : "";
        handleInputChange(value, fieldName);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {

            await schemaInsertProduc.validate(formData, { abortEarly: false });
            setFormErrors({});

            await Swal.fire({
                icon: "info",
                title: "Atenção!",
                text: "Deseja criar este novo produto?",
                showCancelButton: true,
                confirmButtonText: 'Sim, salvar!',
                cancelButtonText: 'Não',
            }).then((result) => {

                if (result.isConfirmed) {
                    saveForm();
                }
            });

        } catch (errors) {

            if (errors instanceof Yup.ValidationError) {
                const validationErrors: { [key: string]: string } = {};
                errors.inner.forEach((error) => {
                    validationErrors[error.path as string] = error.message
                });
                setFormErrors(validationErrors);
            }
        }
    };

    const saveForm = async () => {
        const response = await saveProduct(formData);

        if (response.data.success) {
            Swal.fire({
                icon: "success",
                title: "Parabéns",
                html: `${response.data.message} <br> você será redirecionado`,
                showCancelButton: false,
                confirmButtonText: 'Ok',
            });

            if (typeof onFormSubmit === 'function') {
                onFormSubmit(formData);
            }

            if (response.data.data !== "0" && response.data.data !== null && response.data.data !== "") {
                let lastId = response.data.data.toString();

                setFormData(prevState => ({
                    ...prevState,
                }));

                console.log('salvo ', response.data.data, lastId)

                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        //   toast.addEventListener('mouseenter', Swal.stopTimer)
                        //   toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: 'Redirecionando...'
                });

                setTimeout(function () { window.location.href = `product/edit/${lastId}` }, 3001);
            }

        } else if (response.data.status && response.data.status !== 403) {
            Swal.fire({
                icon: "info",
                title: "Atenção",
                html: `Não foi possível salvar, verifique a conexao com internet, <br> ou fale com um administrador do sistema.`,
                showCancelButton: false,
                confirmButtonText: 'Entendi',
            });
        }
    }
    return (
        <div className={`modal fade bg-dark bg-opacity-75 ${!closeModal ? "" : "show"}`} id="modalInsertProduct" tabIndex={-1} aria-modal="true" role="dialog" style={{ "display": !closeModal ? "none" : 'block' }}>
            <div className="modal-dialog modal-lg p-5 p-lg-10" id="">
                <div className="modal-content rounded-4">
                    <div className="modal-header flex-stack border-0 px-10 pt-5 pb-0" id="">
                        <div id="" className="position-relative z-index-1"></div>

                        <div className="btn btn-sm btn-icon btn-active-color-primary me-n2 position-relative z-index-1" onClick={closeModal}>
                            <KTSVG className="svg-icon svg-icon-2x" path="/media/icons/duotune/general/gen034.svg" />
                        </div>
                    </div>

                    <div className="modal-body pt-0 pb-5 px-15 mt-n5" id="">
                        <div className="container-fluid">
                            <div className="d-block">
                                <div className="tab-content">
                                    <div className="pt-5 tab-pane fade active show" role="tabpanel">
                                        <form onSubmit={handleSubmit}>
                                            {/* Produto */}
                                            <div className="separator d-flex flex-center">
                                                <span className="text-uppercase bg-body fs-7 fw-semibold text-muted px-3">Dados do Produto</span>
                                            </div>
                                            <div className="pe-12 me-n12 mt-15" >
                                                <div className="row gy-5">


                                                    {/* informações adicioanis */}
                                                    <div className="col-lg-8  fv-plugins-icon-container">
                                                        <label className='form-label fw-bold'>Nome do produto</label>
                                                        <div className=''>
                                                            <input
                                                                name="name"
                                                                className="form-control"
                                                                value={formData.name || ''}
                                                                onChange={(e) => handleInputChange(e.target.value, "name")}

                                                            />
                                                            {formErrors.name && (
                                                                <div className="fv-plugins-message-container invalid-feedback">{formErrors.name}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-4  fv-plugins-icon-container">
                                                        <label className='form-label fw-bold'>Tipo de produto</label>
                                                        <div className='d-flex'>
                                                            <Select
                                                                styles={{ container: (base: any) => ({ ...base, zIndex: 99 }) }}
                                                                className="w-450px"
                                                                placeholder="Tipo de produto"
                                                                name="product_plan"
                                                                value={formData.product_plan ? { value: formData.product_plan, label: optionTypeProduct.find(option => option.value === formData.product_plan)?.label } : null}
                                                                onChange={(selectedOption: any) => handleSelectChange(selectedOption as Option | null, "product_plan")}
                                                                options={optionTypeProduct}
                                                            />


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="modal-footer border-0 mt-5">
                                                <button type="button" id="btn_close" className="btn btn-light" onClick={closeModal}>Fechar</button>
                                                <button type="submit" className="btn btn-success me-4">
                                                    Salvar
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}