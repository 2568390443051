import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { permissionsUser } from "../../../../app/request/permission";
import { PermissionsProps, ChildComponentProps } from "../../../../app/interface/I_Permission";
import usePermissionStore from "../../../../app/zustand/PermissionStore";

// Importação dos componentes filhos
import Access from "./Access";
import Create from "./Create";
import Edit from "./Edit";
import Delete from "./Delete";
import List from "./List";
import Import from "./Import";
import Export from "./Export";
import Download from "./Download";
import Retorno from "./Retorno";
import Remessa from "./Remessa";
import Spreadsheet from "./Spreadsheet";
import Bolecode from "./Bolecode";
import Approval from "./Approval";
import Dev from "./Dev";
import { useFetchPermissionQuery } from "../../../../app/redux/api/permission/permissionList";
import { useLocation } from "react-router-dom";

const ComponentPermission: React.FC<PermissionsProps> & {
    Access: React.FC<ChildComponentProps>;
    Create: React.FC<ChildComponentProps>;
    Edit: React.FC<ChildComponentProps>;
    Delete: React.FC<ChildComponentProps>;
    List: React.FC<ChildComponentProps>;
    Import: React.FC<ChildComponentProps>;
    Export: React.FC<ChildComponentProps>;
    Download: React.FC<ChildComponentProps>;
    Retorno: React.FC<ChildComponentProps>;
    Remessa: React.FC<ChildComponentProps>;
    Spreadsheet: React.FC<ChildComponentProps>;
    Bolecode: React.FC<ChildComponentProps>;
    Approval: React.FC<ChildComponentProps>;
    Dev: React.FC<ChildComponentProps>;
} = ({ children, ...props }) => {
    const { renderType, className, includeVerify } = props;
    // const permissionsStore = usePermissionStore();
    const { data, error, isLoading }: any = useFetchPermissionQuery(null);

    const allowedsPermission = useMemo(() => {
        return data?.allowedsPermission ?? [];
    }, [data]);


    const [allowedPermissions, setAllowedPermissions] = useState<string[]>([]);

    const location = useLocation();
    const firstSegment = location.pathname.split("/")[1];

    useEffect(() => {
        setAllowedPermissions(allowedsPermission);
    }, [allowedsPermission]);

    // Mapeia componentes para seus tipos de permissão
    const componentTypeMap: Record<string, string> = {
        'Access': 'access',
        'Create': 'create',
        'Edit': 'edit',
        'Delete': 'delete',
        'List': 'list',
        'Import': 'import',
        'Export': 'export',
        'Download': 'download',
        'Retorno': 'retorno',
        'Remessa': 'remessa',
        'Spreadsheet': 'spreadsheet',
        'Bolecode': 'bolecode',
        'Approval': 'approval',
        'Dev': 'dev'
    };

    // Extrai segmentos significativos da URL atual
    const getUrlModules = (): string[] => {
        const path = window.location.pathname;

        // Divide o caminho em segmentos e filtra segmentos vazios e IDs (UUIDs, números)
        const segments = path.split('/')
            .filter((segment: string) => segment.trim() !== '')
            .filter((segment: string) => !segment.match(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/)) // Remove UUIDs
            .filter((segment: string) => !segment.match(/^\d+$/)); // Remove IDs numéricos

        return segments;
    };

    const hasWildcardMatch = (module?: string): boolean => {
        return allowedPermissions.some(permission => {
            if (permission.includes('.*')) {
                const [prefix] = permission.split('.*');

                return module ? module === prefix : firstSegment === prefix;
            }
            return false;
        });
    };

    const isAllowed = (action: string, equalVerify?: string): boolean => {
        // Caso 1: Se equalVerify foi fornecido, verificar permissão específica
        if (equalVerify) {
            const permissionToCheck = `${equalVerify}.${action}`;

            // Verifica wildcard primeiro
            if (hasWildcardMatch(equalVerify)) {
                return true;
            }

            return allowedPermissions.includes(permissionToCheck);
        }

        // Caso 2: Se includeVerify foi fornecido, verificar em qualquer um dos módulos incluídos
        if (includeVerify && Array.isArray(includeVerify) && includeVerify.length > 0) {
            return includeVerify.some(module => {
                if (hasWildcardMatch(module)) {
                    return true;
                }

                const permissionToCheck = `${module}.${action}`;
                return allowedPermissions.includes(permissionToCheck);
            });
        }

        // Caso 3: Auto-detectar a partir dos segmentos da URL
        const urlModules = getUrlModules();

        for (const module of urlModules) {
            // Verifica se este segmento da URL é um módulo válido com a permissão necessária
            if (hasWildcardMatch(module)) {
                return true;
            }

            const permissionToCheck = `${module}.${action}`;
            if (allowedPermissions.includes(permissionToCheck)) {
                return true;
            }
        }

        // Caso 4: Verificação padrão baseada na rota atual
        if (hasWildcardMatch()) {
            return true;
        }

        const permissionToCheck = `${firstSegment}.${action}`;
        return allowedPermissions.includes(permissionToCheck);
    };

    const processChild = (child: ReactNode): ReactNode | null => {
        if (React.isValidElement(child)) {
            const equalVerify = child.props?.equalVerify;
            const childType = child.type as React.ComponentType<any>;

            // Obter o tipo de permissão do nome do componente
            let permissionType = '';

            if (typeof childType === 'function') {
                const componentName = childType.name || '';
                // Usar o mapa de tipos ou o nome do componente em minúsculas
                permissionType = componentTypeMap[componentName] || componentName.toLowerCase();
            }

            // Se o componente tem o atributo permissiontype, usá-lo como prioridade
            if (child.props?.permissiontype) {
                permissionType = child.props.permissiontype;
            }

            if (permissionType && isAllowed(permissionType, equalVerify)) {
                return React.cloneElement(child, { isallowed: 'true' } as any);
            }
        }
        return null;
    };

    const processChildren = (children: React.ReactNode): React.ReactNode => {
        return React.Children.map(children, processChild);
    };

    const processedChildren = processChildren(children);

    if (renderType) {
        switch (renderType) {
            case "disabled":
                return (
                    <div className={className || ''} style={{ cursor: 'not-allowed' }}>
                        <div style={{ pointerEvents: 'none', opacity: '0.5' }}>
                            {processedChildren}
                        </div>
                    </div>
                );
            case "remove":
                return null;
            default:
                return (
                    <div className={className || ''} style={{ cursor: 'not-allowed' }}>
                        <div style={{ pointerEvents: 'none', opacity: '0.5' }}>
                            {processedChildren}
                        </div>
                    </div>
                );
        }
    } else {
        return <>{processedChildren}</>;
    }
};

// Atribuir componentes filhos
ComponentPermission.Access = Access;
ComponentPermission.Create = Create;
ComponentPermission.Edit = Edit;
ComponentPermission.Delete = Delete;
ComponentPermission.List = List;
ComponentPermission.Import = Import;
ComponentPermission.Export = Export;
ComponentPermission.Download = Download;
ComponentPermission.Retorno = Retorno;
ComponentPermission.Remessa = Remessa;
ComponentPermission.Spreadsheet = Spreadsheet;
ComponentPermission.Bolecode = Bolecode;
ComponentPermission.Approval = Approval;
ComponentPermission.Dev = Dev;

export default ComponentPermission;