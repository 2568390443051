/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'
import { isUri } from '../../../helpers/functions/url'
import usePermissionStore from '../../../../app/zustand/PermissionStore'
import * as authHelper from './../../../../app/modules/auth/core/AuthHelpers';

const HeaderUserMenu: FC = () => {
  const { currentUser, logout } = useAuth();
  const permissionStore = usePermissionStore();
  const uuid = authHelper.getAuthUui();
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-40px me-5 w-40px h-40px d-flex align-items-center bg-opacity-50 bg-primary'>
            {
              isUri(currentUser?.photo) ? (
                <img src={currentUser?.photo} alt={currentUser?.fullname} />
              ) : (

                <div className="d-flex justify-content-center col-md-12 text-light-secondary">
                  <span className="align-self-center text-center fs-5">{currentUser?.name_short}</span>
                </div>
              )
            }

          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.first_name} {currentUser?.last_name}
              {permissionStore.roles &&
                <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>{permissionStore.roles}</span>
              }

            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={`/user/edit/${currentUser?.id}/overview/${uuid}`} className='menu-link px-5'>
          Meu perfil
        </Link>
      </div>

      {/* <Languages /> */}

      {/* <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Configurações
        </Link>
      </div> */}

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <a onClick={() => logout(uuid)} className='menu-link px-5'>
          Sair
        </a>
      </div>
    </div>
  )
}

export { HeaderUserMenu }
