import React, { useContext, useEffect, useState } from 'react';
import DateRangePickerComponent from '../../../layout/components/dates/DateRangePickerComponent';
import Select from "react-select";
// import { FormContext } from '../../../context/dashboard/ContextFormFilter';
import FormData from 'form-data';
import moment, { Moment } from "moment";
import "moment/locale/pt-br";


import { useDispatch } from 'react-redux';
import { resetParcelFilter, setFilter, setParcelFilter } from '../../../../app/redux/filter/filterSlice';
import { TagifyCustom } from '../../../layout/components/buttons/TagifyCustom';
import { useFetchListProductQuery } from '../../../../app/redux/api/product/listProduct';
import { IFilterParcel } from '../../../../app/interface/I_Parcel';

import { Option } from '../../../../app/interface/I_General';
import { optionsPossuiNotaFiscal, optionsStatusParcel, optionsTypeParcelCliente, optionsTypeParcelHouse, optionsTypePayment } from '../../../../app/globals/OptionsGlobals';
import { KTSVG } from '../../../helpers';
import { useAuth } from '../../../../app/modules/auth';
import { useFormFilter } from '../../../layout/useFormFilter';

const FormFilterLisParcel: React.FC = () => {

    const dispatch = useDispatch();

    const [resetDueDate, setResetDueDate] = useState<boolean>(false);
    const [resetPaymentDate, setResetPaymentDate] = useState<boolean>(false);
    const { data: products, error, isLoading } = useFetchListProductQuery(null);
    const [optionsProduct, setOptionsProduct] = useState<Option[]>([]);

    const { formData, setFormData, handleChange, handleDateRange, handleSubmit } = useFormFilter('parcel');

    const [nameClient, setNameClient] = useState('');

    const fetchProducts = () => {

        if (products && Array.isArray(products)) {
            const newOptionsProduct = products.map((product: { id: any; name: any; }) => ({
                value: product.id.toString(),
                label: product.name,
            }));

            setOptionsProduct(newOptionsProduct);
        }
    };

    useEffect(() => {
        fetchProducts();
    }, [products]);


    const onSelectTagify = (e: any) => {

        setFormData(prevState => ({
            ...prevState,
            ['id_client']: e.target.value || '',
            // ['id_client']: e.target.innerText,
        }));
    }


    const handleResetComplete = () => {
        setResetDueDate(false);
        setResetPaymentDate(false);
    };

    const resetFilterForm = () => {

        setResetDueDate(true);
        setResetPaymentDate(true);
        const resetData = {
            parcel: {
                id_client: "",
                status: "",
                start_due_date: "",
                end_due_date: "",
                start_date_payment: "",
                end_date_payment: "",
                type_parcel: "",
                form_payment: "",
                boleto: "",
                nota_fiscal: ""
            }
        }


        setFormData(resetData);

        // Despachar a ação setFilter com os dados do filtro
        dispatch(resetParcelFilter());
    }

    const { auth } = useAuth();

    let type_parcel = optionsTypeParcelHouse.find(option => option.value === formData?.parcel?.type_parcel)?.label || '';
    let optionsTypeParcel = optionsTypeParcelHouse;
    if (auth?.empresa?.type_assinante === "CLIENT") {
        type_parcel = optionsTypeParcelCliente.find(option => option.value === formData?.parcel?.type_parcel)?.label || '';
        optionsTypeParcel = optionsTypeParcelCliente;
    }

    return (

        <form id="form_filter_contract" onSubmit={handleSubmit} >
            <div className="d-flex flex-column align-items align-items-start">
                <div className="d-flex align-items-center mb-2"></div>
                <div className='mb-10 row'>
                    {/* <div className="col-md-6">
                            <label className='form-label fw-bold'>Produto</label>
                            <div className='d-flex'>
                                <Select
                                    styles={{ container: (base:any) => ({ ...base, zIndex: 99 }) }}
                                    className="w-450px"
                                    placeholder="Selecione um produto"
                                    value={formData.id_product !== '0' && formData.id_product !== '' ? { value: formData.id_product, label: optionsProduct.find(option => option.value === formData.id_product)?.label } : null}
                                    onChange={(selectedOption:any) => handleSelectChange(selectedOption as Option | null, "id_product")}
                                    options={optionsProduct}
                                />
                            </div>
                        </div> */}
                    {/* <div className="col-md-12">
                        <label className='form-label fw-bold'>Cliente</label>
                        <div className=''>
                            <TagifyCustom
                                onSelectTagify={onSelectTagify}
                                selectedId=""
                                selectedName=""
                                endPoint="client"
                            />
                        </div>
                    </div> */}


                    {/* <div className="mb-4"></div> */}

                    <div className="col-md-6">
                        <label className='form-label fw-bold'>Forma de Pagamento</label>
                        <div className='d-flex'>
                            <Select
                                styles={{ container: (base: any) => ({ ...base, zIndex: 99 }) }}
                                className="w-450px"
                                placeholder="Selecione"
                                value={{ value: formData?.parcel?.form_payment, label: optionsTypePayment.find(option => option.value === formData?.parcel?.form_payment)?.label }}
                                onChange={(selectedOption: Option) => handleChange("form_payment", selectedOption?.value)}
                                options={optionsTypePayment}
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <label className='form-label fw-bold'>Status</label>
                        <div className='d-flex'>
                            <Select
                                styles={{ container: (base: any) => ({ ...base, zIndex: 11 }) }}
                                className="w-450px"
                                placeholder="Selecione"
                                value={{ value: formData?.parcel?.status, label: optionsStatusParcel.find(option => option.value === formData?.parcel?.status)?.label || 'Todos' }}
                                onChange={(selectedOption: Option) => handleChange("status", selectedOption?.value)}
                                options={optionsStatusParcel}
                            />
                        </div>
                    </div>

                    <div className="mb-4"></div>

                    <div className="col-md-6">
                        <label className='form-label fw-bold'>Tipo de parcela</label>
                        <div className='d-flex'>
                            <Select
                                styles={{ container: (base: any) => ({ ...base, zIndex: 10 }) }}
                                className="w-450px"
                                placeholder="Selecione"
                                value={{ value: formData?.parcel?.type_parcel, label: optionsTypeParcel.find(option => option.value === formData?.parcel?.type_parcel)?.label }}

                                onChange={(selectedOption: Option) => handleChange("type_parcel", selectedOption?.value)}
                                options={optionsTypeParcel}
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <label className='form-label fw-bold'>Possui nota?</label>
                        <div className='d-flex'>
                            <Select
                                styles={{ container: (base: any) => ({ ...base, zIndex: 10 }) }}
                                className="w-450px"
                                placeholder="Selecione"
                                value={{ value: formData?.parcel?.nota_fiscal, label: optionsPossuiNotaFiscal.find(option => option.value === formData?.parcel?.nota_fiscal)?.label }}
                                onChange={(selectedOption: Option) => handleChange("nota_fiscal", selectedOption?.value)}
                                options={optionsPossuiNotaFiscal}
                            />
                        </div>
                    </div>

                    <div className="mb-4"></div>

                    <div className='col-md-6'>
                        <label className='form-label fw-bold'>Data de pagamento</label>
                        <DateRangePickerComponent
                            onDateRangeSelection={(event, picker) => handleDateRange(event, picker, 'date_payment')}
                            className="w-100"
                            name='date_payment'
                            resetDate={resetDueDate}
                            onResetComplete={handleResetComplete}
                        />

                    </div>

                    <div className='col-md-6'>
                        <label className='form-label fw-bold'>Vencimento</label>
                        <DateRangePickerComponent
                            onDateRangeSelection={(event, picker) => handleDateRange(event, picker, 'due_date')}
                            name='due_date'
                            className="w-100"
                            resetDate={resetDueDate}
                            onResetComplete={handleResetComplete}
                        />

                    </div>


                </div>
            </div>
            <div className="card-footer pt-4" id="kt_drawer_filter_footer">
                <div className='d-flex justify-content-center'>
                    <button
                        type='reset'
                        className='btn btn-sm btn-light btn-active-light-primary me-2 btn_reset_filter_all'
                        data-kt-menu-dismiss='true'
                        onClick={() => resetFilterForm()}
                    >
                        Resetar filtro
                    </button>

                    <button type="submit" data-kt-indicator='off' className="btn btn-sm btn-primary me-3 btn_filter_all" data-kt-menu-dismiss='true' data-kt-stepper-action="submit">
                        <span className="indicator-label">
                            Filtrar
                            <KTSVG className="svg-icon svg-icon-2" path="/media/icons/duotune/general/gen031.svg" />
                        </span>
                        <span className="indicator-progress">
                            Carregando... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                </div>
            </div>
        </form>
    );
};

export default FormFilterLisParcel;
