import { IParticipant } from "../../../../app/interface/I_Client";
import { ICarne } from "../../../../app/interface/I_Contract";
import { IPayments } from "../../../../app/interface/I_Payments";
import { IContract } from "../../../../app/interface/I_Contract";
import { IAssociatedBillingInstallment } from "../../../../app/interface/I_Payments";
import { IInterfaceMap } from "../../../../app/interface/I_Table";
import { changeParcelInMassaApi, deleteParcelAPI, editParcelAPI } from "../../../../app/request/parcel";
import { FormValues } from "../../../../app/interface/I_Parcel";
import { ChangeInMass } from "../../../../app/types/T_General";
import { changeContractInMassaApi, deleteContractAPI } from "../../../../app/request/contract";
import { deleteUnitsAPI } from "../../../../app/request/unit";
import { deleteProductsAPI } from "../../../../app/request/product";
import { deleteClientAPI } from "../../../../app/request/client";

export const interfaceMap: IInterfaceMap = {
    participante: {
        id: 0,
        initialName: "",
        link: "#",
        name: "",
        email: "",
        confirmation: "",
    } as IParticipant,

    carne: {
        id: 0,
        idUnidade: 0,
        idCliente: 0,
        link: "#",
        paymentType: "",
        creationDate: "",
        firstExpiration: "",
        lastExpiration: "",
        email: "",
    } as ICarne,

    listPayment: {
        id: 0,
        idContract: 0,
        idClient: 0,
        link: "#",
        contract: '',
        numberInstallment: '',
        nameClient: '',
        expiration: '',
        valueInstallment: '',
        descriptionValueInstallment: '',
        datePayment: '',
        due_date: '',
        valuePayment: '',
        situationInstallment: '',
        email: ''
    } as IPayments,

    associatedBilling: {
        id: 0,
        link: "#",
        due_date: '',
        value: '',
        createdVia: '',
        type_parcel: '',
        number_parcel: ''
    } as IAssociatedBillingInstallment,

    contract: {
        id: 0,
        link: "#",
        name: '',
        value: '0.0',
        status: '',
        next_payment: '',
        contract_expiration: ''
    } as IContract,

    parcels: {
        link: "#",
        id: 0,
        form_payment: '',
        value: 'R$ 0,00',
        due_date: '',
        status: 'Pedente'
    } as FormValues,

};



type DynamicFunction = (...args: any[]) => any;

// Um objeto com as funções disponíveis
// export const functionMap = {
//     deleteParcel: async function deleteParcel(ids:string[], auth:any): Promise<any>
//     {
//         const response = await deleteParcelAPI(ids, auth );
//         return response;
//     }
//     // ,changeParcelInMassa: async function changeParcelInMassa(data: ChangeInMass[], auth:any): Promise<any>
//     // {
//     //     const response = await changeParcelInMassaApi(data, auth );
//     //     return response;
//     // }

// };

interface Callable {
    [key: string]: (...args: any[]) => any;
}

export const functionMap: Callable = {
    deleteParcelInMassa: async function deleteParcel(ids: string[], auth: any): Promise<any> {
        const response = await deleteParcelAPI(ids, auth);
        return response;
    },
    changeParcelInMassa: async function changeParcelInMassa(ids: string[], data: ChangeInMass[], auth: any): Promise<any> {
        const response = await changeParcelInMassaApi(ids, data, auth);
        return response;
    },
    changeContractInMassa: async function changeContractInMassa(ids: string[], data: ChangeInMass[], auth: any): Promise<any> {
        const response = await changeContractInMassaApi(ids, data, auth);
        return response;
    },
    deleteContractInMassa: async function deleteContract(ids: string[], auth: any): Promise<any> {
        const response = await deleteContractAPI(ids, auth);
        return response;
    },
    deleteUnitInMassa: async function deleteUnit(ids: string[], auth: any): Promise<any> {
        const response = await deleteUnitsAPI(ids, auth);
        return response;
    },
    deleteProductInMassa: async function deleteProducts(ids: string[], auth: any): Promise<any> {
        const response = await deleteProductsAPI(ids, auth);
        return response;
    },
    deleteClientInMassa: async function deleteClient(ids: string[], auth: any): Promise<any> {
        const response = await deleteClientAPI(ids, auth);
        return response;
    }
};


export const returMarkParcelPaid = (baixa: string | null) => {
    switch (baixa) {
        case '1':
            return 'PAID_MANUAL_COMPENSACAO';
            break;
        case '2':
            return 'PAID_MANUAL_SEM_COMPENSACAO';
            break;
        case '5':
            return 'BAIXA';
            break;
        default: return null
            break;
    }
}

export const returMarkParcelPaidAmigavel = (baixa: string | null) => {
    switch (baixa) {
        case '0':
            return 'Pendente';
            break;
        case '1':
            return 'Pago e compensado (manual)';
            break;
        case '2':
            return 'Pago sem compensação (manual)';
            break;
        case '5':
            return 'Baixa';
            break;
        default: return 'Pendente';
            break;
    }
}
