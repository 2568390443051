import { Navigate, Outlet, Route, Routes } from "react-router-dom"
import { PageLink, PageTitle } from "../../../../layout/core"
import { ConfigurationPix } from "../../../../layout/components/configurations/qrcodepix/ConfigurationPix"
import { TabHeaderPix } from "./TabHeaderPix"
import * as authHelper from './../../../../../app/modules/auth/core/AuthHelpers'
// import { CustomQrCode } from "../../../../layout/components/configurations/qrcodepix/CustomQrCode"
const uuid = authHelper.getAuthUui() || '';
const lastUuid = authHelper.getAuthLastUui() || '';

const profileBreadCrumbs: Array<PageLink> = [
    {
        title: 'Configuração',
        path: `configuration/access/${uuid || lastUuid}`,
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const TabRouteHeaderPixQrCode = () => {

    const uuid = authHelper.getAuthUui();
    return (
        <Routes>
            <Route
                element={
                    <>
                        <TabHeaderPix />
                        <Outlet />
                    </>
                }
            >
                <Route
                    path={`settings/${uuid}`}
                    element={
                        <>
                            <PageTitle breadcrumbs={profileBreadCrumbs} >QrCode - Pix</PageTitle>
                            <ConfigurationPix />
                        </>
                    }
                />

                <Route
                    path={`qrcode/${uuid}`}
                    element={
                        <>
                            <PageTitle breadcrumbs={profileBreadCrumbs} >Geração em lotes</PageTitle>
                            <ConfigurationPix />
                            {/* <CustomQrCode value="" /> */}
                        </>
                    }
                />

                <Route index element={<Navigate to={`settings/${uuid}`} />} />
            </Route>
        </Routes>
    )
}

export default TabRouteHeaderPixQrCode