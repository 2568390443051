import { AuthUui } from '../../../interface/I_Auth'
import { Assinante, I_Empresa } from '../../../interface/I_Empresa'
import { AuthModel } from './_models'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthModel | undefined => {
	if (!localStorage) {
		return
	}

	const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
	if (!lsValue) {
		return
	}

	try {
		const auth: AuthModel = JSON.parse(lsValue) as AuthModel
		if (auth) {
			// You can easily check auth_token expiration also
			return auth
		}
	} catch (error) {
		console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
	}
}

const setAuth = (auth: AuthModel) => {
	if (!localStorage) {
		return
	}

	try {
		const lsValue = JSON.stringify(auth)
		localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
	} catch (error) {
		console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
	}
}

const removeAuth = () => {
	if (!localStorage) {
		return
	}

	try {
		localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
	} catch (error) {
		console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
	}
}

export function setupAxios(axios: any) {
	axios.defaults.headers.Accept = 'application/json'
	axios.interceptors.request.use(
		(config: { headers: { Authorization: string } }) => {
			const auth = getAuth()
			if (auth && auth.api_token) {
				config.headers.Authorization = `Bearer ${auth.api_token}`
			}

			return config
		},
		(err: any) => Promise.reject(err)
	)
}

const getAuthUui = (): string | undefined => {
	if (typeof localStorage === 'undefined') {
		console.warn('localStorage não está disponível.')
		return
	}

	const hash = window.location.pathname.split('/');
	const uuid = hash[hash.length - 1];

	const lsUui = localStorage.getItem(uuid)

	if (!lsUui) {
		return;
	}

	try {
		const assinante: Assinante = JSON.parse(lsUui); // Converte a string para objeto
		return assinante.uui;

	} catch (error) {
		console.error('GET AUTH UUI LOCAL STORAGE PARSE ERROR', error)
		return;
	}
}

const getAuthUui_backup = () => {
	if (typeof localStorage === 'undefined') {
		console.warn('localStorage não está disponível.')
		return
	}

	const lsUui: string | null | undefined = localStorage.getItem('auth-uui')


	if (!lsUui) {
		return;
	}

	try {
		const uui = JSON.parse(lsUui)
		if (uui) {
			return uui
		}
	} catch (error) {
		console.error('GET AUTH UUI LOCAL STORAGE PARSE ERROR', error)
		return;
	}
}

export const setAuthUui = (uui: AuthUui | undefined) => {
	if (!localStorage) {
		return
	}

	try {

		const lsUui = JSON.stringify(uui?.uui);
		localStorage.setItem('auth-uui', lsUui);
	} catch (error) {
		console.error('SET AUTH UUI LOCAL STORAGE SAVE ERROR', error)
	}
}

export const getAuthLastUui = (): string | undefined => {
	if (typeof localStorage === 'undefined') {
		console.warn('localStorage não está disponível.')
		return
	}

	const lsUui: string | null | undefined = localStorage.getItem('auth-uui')

	if (!lsUui) {
		return;
	}

	try {
		const uui: AuthUui = JSON.parse(lsUui) as AuthUui
		if (uui) {
			// You can easily check auth_token expiration also
			return uui.uui
		}
	} catch (error) {
		console.error('GET AUTH UUI LOCAL STORAGE PARSE ERROR', error)
		return;
	}
}

export const setAuthLastUui = (uui: AuthUui | undefined) => {
	if (!localStorage) {
		return
	}

	try {

		const lsUui = JSON.stringify(uui?.uui);
		localStorage.setItem('auth-uui', lsUui);
	} catch (error) {
		console.error('SET AUTH UUI LOCAL STORAGE SAVE ERROR', error)
	}
}

const removeAuthUui = () => {
	if (!localStorage) {
		return
	}

	try {
		localStorage.removeItem('auth-uui')
	} catch (error) {
		console.error('AUTH UUI LOCAL STORAGE REMOVE ERROR', error)
	}
}

const getAssinante = (): Assinante | undefined => {
	if (typeof localStorage === 'undefined') {
		console.warn('localStorage não está disponível.')
		return
	}

	const hash = window.location.pathname.split('/');
	const uuid = hash[hash.length - 1];

	const lsAssinante = localStorage.getItem(uuid)

	if (!lsAssinante) {
		return;
	}

	try {
		const empresa: Assinante = JSON.parse(lsAssinante)
		if (empresa) {
			return empresa
		}
	} catch (error) {
		console.error('GET EMPRESA ASSINANTE LOCAL STORAGE PARSE ERROR', error)
		return;
	}
}

const setAssinante = (assinante: Assinante | undefined) => {
	if (!localStorage) {
		return
	}

	try {
		const lsAssinante = JSON.stringify(assinante)
		localStorage.setItem('assinante', lsAssinante)
	} catch (error) {
		console.error('SET EMPRESA ASSINANTE LOCAL STORAGE SAVE ERROR', error)
	}
}

const removeAssinante = () => {
	if (!localStorage) {
		return
	}

	try {
		localStorage.removeItem('assinante')
	} catch (error) {
		console.error('EMPRESA ASSINANTE LOCAL STORAGE REMOVE ERROR', error)
	}
}

export { getAuth, setAuth, removeAuth, removeAuthUui, getAuthUui, getAssinante, setAssinante, removeAssinante, AUTH_LOCAL_STORAGE_KEY }
