import axios from "axios";
import { IBank } from "../../interface/I_Configurations";
import { AuthType, IResponse } from "../../interface/I_General";
import { getToken } from '../../redux/api/auth/getToken';
import * as authHelper from '../../modules/auth/core/AuthHelpers';

const API_URL = process.env.REACT_APP_API_URL

export const getBanks = async (auth: AuthType) => {

    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';

    try {

        let headers = {};
        if (auth && auth.api_token) {
            headers = {
                Authorization: `Bearer ${auth.api_token}`,
                'X-UUID-Assinante': uuid || lastUuid
            };
        }

        const response = await axios.post<IResponse>(`${API_URL}/module/getBanks`,
            {},
            { headers }
        );

        return response;
    } catch (error) {
        throw error;
    }
}

export const listBank = async (auth: AuthType) => {

    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';

    try {

        let headers = {};
        if (auth && auth.api_token) {
            headers = {
                Authorization: `Bearer ${auth.api_token}`,
                'X-UUID-Assinante': uuid || lastUuid
            };
        }

        const response = await axios.post<IResponse>(`${API_URL}/module/listBank`,
            {},
            { headers }
        );

        return response;
    } catch (error) {
        throw error;
    }
}

export const getBeneficiary = async (auth: AuthType) => {

    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';

    try {

        let headers = {};
        if (auth && auth.api_token) {
            headers = {
                Authorization: `Bearer ${auth.api_token}`,
                'X-UUID-Assinante': uuid || lastUuid
            };
        }

        const response = await axios.post<IResponse>(`${API_URL}/module/getBeneficiary`,
            {},
            { headers }
        );

        return response;
    } catch (error) {
        throw error;
    }
}


export const saveBank = async (data: IBank, auth: AuthType) => {

    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';

    try {

        let headers = {};
        if (auth && auth.api_token) {
            headers = {
                Authorization: `Bearer ${auth.api_token}`,
                'X-UUID-Assinante': uuid || lastUuid
            };
        }

        const response = await axios.post<IResponse>(`${API_URL}/module/saveConfigurationBank`,
            { data },
            { headers }
        );

        return response;
    } catch (error) {
        throw error;
    }
}

export const saveBeneficiary = async (data: any, auth: AuthType) => {

    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';

    try {

        let headers = {};
        if (auth && auth.api_token) {
            headers = {
                Authorization: `Bearer ${auth.api_token}`,
                'X-UUID-Assinante': uuid || lastUuid
            };
        }

        const response = await axios.post<IResponse>(`${API_URL}/module/saveBeneficiary`,
            { data },
            { headers }
        );

        return response;
    } catch (error) {
        throw error;
    }
}


export const ativarInativarBank = async (data: any, auth: AuthType) => {

    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';

    try {

        let headers = {};
        if (auth && auth.api_token) {
            headers = {
                Authorization: `Bearer ${auth.api_token}`,
                'X-UUID-Assinante': uuid || lastUuid
            };
        }

        const response = await axios.post<IResponse>(`${API_URL}/module/ativarInativarBank`,
            { data },
            { headers }
        );

        return response;
    } catch (error) {
        throw error;
    }
}

export const ativarInativarBeneficiary = async (data: any, auth: AuthType) => {
    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';

    try {

        let headers = {};
        if (auth && auth.api_token) {
            headers = {
                Authorization: `Bearer ${auth.api_token}`,
                'X-UUID-Assinante': uuid || lastUuid
            };
        }

        const response = await axios.post<IResponse>(`${API_URL}/module/ativarInativarBeneficiary`,
            { data },
            { headers }
        );

        return response;
    } catch (error) {
        throw error;
    }
}