import { useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/redux/store';
import _ from 'lodash';

interface TableFilterState {
    [key: string]: any;
}

export const useTableFilters = () => {
    const location = useLocation();
    const [tableFilters, setTableFilters] = useState<TableFilterState>({});
    const [lastAppliedFilters, setLastAppliedFilters] = useState<TableFilterState>({});

    // Filtros do Redux
    const contractFilter = useSelector((state: RootState) => state.filter.contract);
    const paymentsFilter = useSelector((state: RootState) => state.filter.payment);
    const parcelFilter = useSelector((state: RootState) => state.filter.parcel);
    const productFilter = useSelector((state: RootState) => state.filter.product);
    const productPlanFilter = useSelector((state: RootState) => state.filter.product_plan);
    const unitFilter = useSelector((state: RootState) => state.filter.unit);
    const clientFilter = useSelector((state: RootState) => state.filter.client);
    const dashboardFilter = useSelector((state: RootState) => state.filter.dashboard);

    // Filtro global de produto da navbar
    const navbarProductFilter = useSelector((state: RootState) => state.filter.product);

    // Determina qual filtro deve ser aplicado com base na rota
    const currentPath = location.pathname;

    // Função para aplicar filtros dependendo da rota
    const getActiveFilters = useMemo(() => {
        const filters: TableFilterState = {};

        // Aplique o filtro do produto da navbar, se existir
        if (navbarProductFilter && navbarProductFilter.id_product && navbarProductFilter.id_product !== '0') {
            filters['navbar_id_product'] = navbarProductFilter.id_product;
        }

        // Adicionar filtros específicos de cada rota
        if (currentPath.includes('/installment')) {
            // Filtros da rota parcela
            Object.entries({ parcel: parcelFilter }).forEach(
                ([key, value]) => {
                    if (value) {

                    }
                    filters[`${key}`] = value;
                }
            );
        } else if (currentPath.includes('/contract')) {

            // Filtros da rota contrato
            Object.entries({ contract: contractFilter }).forEach(
                ([key, value]) => {

                    if (!!value) {
                        let full = temPropriedadePreenchida(value);
                        if (full) {

                        }
                        filters[`${key}`] = value;
                    }
                }
            );
        } else if (currentPath.includes('/payment')) {
            // Filtros da rota pagamento
            Object.entries({ payments: paymentsFilter }).forEach(
                ([key, value]) => {
                    if (value) {
                        let full = temPropriedadePreenchida(value);
                        if (full) {
                        }
                        filters[`${key}`] = value;
                    }
                }
            );

        } else if (currentPath.includes('/product')) {
            // Filtros da rota produto
            Object.entries({ product_plan: productPlanFilter }).forEach(([key, value]) => {
                if (value) {
                    filters[`${key}`] = value;
                }
            });
        } else if (currentPath.includes('/dashboard')) {
            // Filtros da rota dashboard
            Object.entries({ dashboard: dashboardFilter }).forEach(([key, value]) => {
                if (value) {
                    filters[`${key}`] = value;
                }
            });
        } else if (currentPath.includes('/unit')) {
            // Filtros da rota unidade
            Object.entries({ unit: unitFilter }).forEach(([key, value]) => {
                if (value) {
                    filters[`${key}`] = value;
                }
            });
        } else if (currentPath.includes('/client')) {
            // Filtros da rota unidade
            Object.entries({ client: clientFilter }).forEach(([key, value]) => {
                if (value) {
                    filters[`${key}`] = value;
                }
            });
        }

        return filters;
    }, [
        currentPath,
        contractFilter,
        paymentsFilter,
        parcelFilter,
        productFilter,
        productPlanFilter,
        unitFilter,
        clientFilter,
        dashboardFilter,
        navbarProductFilter,
    ]);

    // Atualize os filtros da tabela quando houver mudanças
    useEffect(() => {
        if (!_.isEqual(getActiveFilters, lastAppliedFilters)) {
            setTableFilters(getActiveFilters);
            setLastAppliedFilters(getActiveFilters);
        }
    }, [getActiveFilters, lastAppliedFilters]);

    // Função para preparar os filtros para a API
    const prepareFiltersForAPI = () => {
        const apiFilters: TableFilterState = {};
        const othersFilters: TableFilterState = {};
        const productFilters: TableFilterState = {};

        // Garante que `id_product` venha de `navbar_id_product` ou de qualquer objeto dentro de `tableFilters` que tenha a chave `id_product`
        // apiFilters.id_product = tableFilters.navbar_id_product || Object.entries(tableFilters).find(([key, value]) =>
        //     typeof value === 'object' && value !== null && 'id_product' in value
        // )?.[1]?.id_product || "";

        // Remove `navbar_id_product` após usá-lo
        // delete tableFilters.navbar_id_product;

        Object.entries(tableFilters).forEach(([key, value]) => {
            if (key === 'navbar_id_product') {
                productFilters['id_product'] = value;
                return;
            }

            // Verifica se o valor é um objeto (não array) e não é nulo
            if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                // Adiciona cada propriedade do objeto como um filtro separado
                Object.entries(value).forEach(([subKey, subValue]) => {
                    othersFilters[subKey] = subValue;
                });
            } else {
                // Mantém valores primitivos e arrays como estão
                othersFilters[key] = value;
            }
        });


        Object.assign(apiFilters, productFilters, othersFilters);

        return apiFilters;
    };

    const prepareFiltersForAPI_bkp = () => {
        const apiFilters: TableFilterState = {};
        const othersFilters: TableFilterState = {};
        const productFilters: TableFilterState = {};

        // Copia de tableFilters para garantir que não alteramos o original
        const tableFiltersCopy = { ...tableFilters };

        // Garante que `id_product` venha de `navbar_id_product` ou de qualquer objeto dentro de `tableFilters` que tenha a chave `id_product`
        apiFilters.id_product = tableFiltersCopy.navbar_id_product || Object.entries(tableFiltersCopy).find(([key, value]) =>
            typeof value === 'object' && value !== null && 'id_product' in value
        )?.[1]?.id_product || "";

        // Remover `navbar_id_product` apenas da cópia, sem afetar o original
        delete tableFiltersCopy.navbar_id_product;

        // Processa os outros filtros, mantendo os que não são objetos ou arrays
        Object.entries(tableFiltersCopy).forEach(([key, value]) => {
            if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                // Pode adicionar as propriedades do objeto aqui, caso necessário
                // Object.entries(value).forEach(([subKey, subValue]) => {
                //     othersFilters[subKey] = subValue;
                // });
            } else {
                othersFilters[key] = value;
            }
        });

        // Combina os filtros processados
        Object.assign(apiFilters, othersFilters);

        return apiFilters;
    };


    // Função para limpar filtros específicos
    const clearFilters = (filterTypes: string[]) => {
        const newFilters = { ...tableFilters };

        Object.keys(newFilters).forEach(key => {
            // const filterType = key.split('_')[0];
            // const filterType = key.split('_')[0];
            if (filterTypes.includes(key)) {
                delete newFilters[key];
            }
        });

        setTableFilters(newFilters);
        setLastAppliedFilters(newFilters);
    };

    return {
        tableFilters,
        prepareFiltersForAPI,
        clearFilters,
        currentPath,
        hasFiltersChanged: !_.isEqual(tableFilters, lastAppliedFilters),
    };
};

function temPropriedadePreenchida(objeto: any) {
    // Se não for um objeto ou for null, retorna false
    if (!objeto || typeof objeto !== 'object') {
        return false;
    }

    // Percorre todas as propriedades do objeto
    for (const chave in objeto) {
        const valor = objeto[chave];

        // Verifica o tipo do valor
        if (Array.isArray(valor)) {
            // Se for um array e tiver elementos, retorna true
            if (valor.length > 0) {
                return true;
            }
        } else if (typeof valor === 'object' && valor !== null) {
            // Se for um objeto, chama a função recursivamente
            if (temPropriedadePreenchida(valor)) {
                return true;
            }
        } else {
            // Para strings, verificar se não está vazia
            if (typeof valor === 'string' && valor.trim() !== '') {
                return true;
            }
            // Para números, verificar se é diferente de zero
            // else if (typeof valor === 'number' && valor !== 0) {
            else if (typeof valor === 'number') {
                return true;
            }
            // Para booleanos, verificar se é true
            else if (typeof valor === 'boolean' && valor === true) {
                return true;
            }
            // Para outros tipos que não são undefined ou null
            else if (valor !== undefined && valor !== null && valor !== '') {
                return true;
            }
        }
    }

    // Se chegou até aqui, não encontrou nenhuma propriedade preenchida
    return false;
}
