import axios from 'axios'
import { AuthModel, UserModel } from './_models'
// import { AuthUui } from '../../../interface/I_Auth'

import { getToken } from '../../../../app/redux/api/auth/getToken';
import * as authHelper from './../../../modules/auth/core/AuthHelpers';
import { useParams } from 'react-router-dom';
import { ApiResponseEmpresa } from '../../../interface/I_Empresa';

const API_URL = process.env.REACT_APP_API_URL


export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/module/verify_token`
export const LOGIN_URL = `${API_URL}/module/auth`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

const uuid = authHelper.getAuthUui();
const dataLocalStorage = getToken();

const authorization_barear = {
  headers: {
    Authorization: `Bearer ${dataLocalStorage?.token}`,
    'X-UUID-Assinante': uuid ? String(uuid) : '',
  }
};

// Server should return AuthModel
export function login(email: string, password: string, uui?: string) {

  return axios.post<AuthModel>(LOGIN_URL, { uui: uui },
    {
      auth: {
        username: email,
        password: password,
      },
      headers: {
        'X-UUID-Assinante': String(uui),
      }

    })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  }, authorization_barear)
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {

  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  }, authorization_barear)
}

export function getUserByToken(token: string) {

  let uuid = authHelper.getAuthUui();
  let dataLocalStorage = getToken();

  const authorization_barear = {
    headers: {
      Authorization: `Bearer ${dataLocalStorage?.token}`,
      'X-UUID-Assinante': uuid ? String(uuid) : '',
    }
  };

  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {},
    authorization_barear)
}

export async function validaUuid(uuid: string): Promise<ApiResponseEmpresa> {

  const headers = {
    'X-UUID-Assinante': uuid ? String(uuid) : ''
  };

  const response = await axios.post<ApiResponseEmpresa>(`${API_URL}/module/validaUuid/${uuid}`, {}, { headers });
  return response.data;
}
