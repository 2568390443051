import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// import { IFilterGLobals } from '../../../interface/I_Filter';
// import { IResponse } from '../../../interface/I_General';
import { getToken } from '../auth/getToken';
import * as authHelper from './../../../modules/auth/core/AuthHelpers';

const uuid = authHelper.getAuthUui();
const API_URL = process.env.REACT_APP_API_URL;
// const tokenLocal = getToken()?.token;

export const billingPosition = createApi({
    reducerPath: 'billingPosition',
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL,
        prepareHeaders: (headers) => {
            const dataLocalStorage = getToken();

            if (!headers.has("Authorization")) {
                if (dataLocalStorage?.token) {
                    headers.set('Authorization', `Bearer ${dataLocalStorage?.token}`);
                }
            }

            if (uuid) {
                headers.set('X-UUID-Assinante', String(uuid));
            }


            return headers;
        },
    }),
    endpoints: (builder) => ({
        fetchBillingPosition: builder.query({
            query: (params) => ({
                url: `/module/billingPosition`,
                method: 'POST',
                body: params,
                // headers: {
                //     Authorization: `Bearer ${tokenLocal}`,
                // },
            }),
        }),

    }),

});

export const { useFetchBillingPositionQuery, useLazyFetchBillingPositionQuery } = billingPosition;