import { useEffect, useState } from "react"
import Swal from "sweetalert2"
import { KTSVG } from "../../../../_metronic/helpers"
import { Importacao } from "../../../../_metronic/layout/components/configurations/importacao/Importacao"
import { PageLink, PageTitle } from "../../../../_metronic/layout/core"
import { Upload } from "../../../interface/I_Files"
import { CampareBaixaBoletos } from "../modal/CampareBaixaBoletos"
import * as authHelper from './../../../modules/auth/core/AuthHelpers'
const uuid = authHelper.getAuthUui() || '';
const lastUuid = authHelper.getAuthLastUui() || '';

const profileBreadCrumbs: Array<PageLink> = [
    {
        title: 'Configuração',
        path: `/configuration/access/${uuid || lastUuid}`,
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

export const ArquivoRetorno = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [data, setData] = useState([]);


    const closeModal = () => {
        setIsModalOpen(false);
    };

    const proposUpload: Upload = {
        idUppy: 'importacao',
        idImageEditor: 'ImageEditor',
        plugins: ['ImageEditor', 'ScreenCapture', 'Webcam'],
        isDebug: true,
        isAutoProceed: false,
        isAllowMultipleUploads: true,
        isShowProgressDetails: false,
        isHideUploadButton: false,
        fileTypes: ['.RET', '.ret', '.TXT', '.txt'],
        endPoint: 'arquivoRetorno',
        width: 'auto',
        height: '350px',
        isMessageReturn: true
    }
    const handleFile = (response: any) => {

        if (
            response.body.response.message &&
            response.body.response.message.api &&
            Array.isArray(response.body.response.message.api) &&
            response.body.response.message.api.length > 0
        ) {
            setData(response.body.response.message.api)
            setIsModalOpen(true);
        } else {
            setIsModalOpen(false);
            Swal.fire(
                response.body.response.message.title,
                response.body.response.message.text,
                response.body.response.message.icon
            )
        }
    };

    useEffect(() => {

    }, [data])

    return (
        <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Importação CNAB</PageTitle>
            <div className="col-xl-12 mb-xl-10">
                <div className="alert alert-primary d-flex align-items-center p-5 mb-10">
                    <KTSVG path={""} />
                    <div className="d-flex flex-column">
                        <h4 className="mb-1 text-primary">Área de Importação de Arquivo Retorno</h4>
                        <span>Importe seus arquivo retorno do banco.</span>
                    </div>
                </div>

                <div className="card">

                    <div className="card-body">
                        <div className="row align-items-center">
                            <Importacao
                                propsFiles={proposUpload}
                                onFile={handleFile}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {isModalOpen &&
                <CampareBaixaBoletos
                    closeModal={closeModal}
                    data={data}
                />
            }
        </>
    )
}