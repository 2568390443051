import { useDispatch } from "react-redux";
import { useFetchListProductQuery } from "../../app/redux/api/product/listProduct";
import { useEffect, useState } from "react";
import { OptionProductNav } from "../../app/interface/I_General";
import { setProductFilter } from "../../app/redux/filter/filterSlice";

// useNavbarProductFilter.ts
export const useNavbarProductFilter = () => {
  const dispatch = useDispatch();
  const { data: products, error, isLoading } = useFetchListProductQuery(null);
  const [optionsProduct, setOptionsProduct] = useState<OptionProductNav[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<string>('');

  const [formData, setFormData] = useState({
    product: {
      id_product: '',
    }
  });

  useEffect(() => {
    if (products && Array.isArray(products)) {
      const allOption = {
        value: '',
        label: 'Todos',
      };

      const productOptions = [
        allOption,
        ...products.map((product: { id: any; name: string; beneficiary: string }) => ({
          value: product.id.toString(),
          label: (
            <div className="d-flex align-items-center">
              <div className="ms-5">
                <span className="fw-bold text-gray-600">{product.name}</span>
                <div className="fs-7 text-muted">{product.beneficiary}</div>
              </div>
            </div>
          )
        }))
      ];

      setOptionsProduct(productOptions);
    }
  }, [products]);

  // Manipulador para mudança de seleção
  const handleProductChange = (selectedOption: OptionProductNav | null) => {
    const value = selectedOption ? selectedOption.value : "";

    setSelectedProduct(value);

    // Atualiza o formData
    const updatedProduct = {
      ...formData.product,
      id_product: value,
    };

    setFormData(prev => ({
      ...prev,
      product: updatedProduct
    }));

    // Dispara a ação Redux
    dispatch(setProductFilter(updatedProduct)); // Agora o dispatch é feito aqui, ao invés do Navbar
  };

  return {
    optionsProduct,
    selectedProduct,
    formData,
    setFormData,
    isLoading,
    handleProductChange,
  };
};
