
import { FC } from 'react'
// import {useIntl} from 'react-intl'
// import {toAbsoluteUrl} from '../../../_metronic/helpers'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { ContentGeneral } from './ContentGeneral'
import * as authHelper from './../../modules/auth/core/AuthHelpers'
const uuid = authHelper.getAuthUui() || '';
const lastUuid = authHelper.getAuthLastUui() || '';


const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: `/dashboard/access/${uuid || lastUuid}`,
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ConfiguartionsPage: FC = () => (
  <>
    <ContentGeneral />
  </>
)

const ConfigurationsWrapper: FC = () => {
  // const intl = useIntl()
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle> */}
      <PageTitle breadcrumbs={profileBreadCrumbs}>Configuracao</PageTitle>
      <ConfiguartionsPage />
    </>
  )
}

export { ConfigurationsWrapper }
