import React, { useContext, useEffect, useState } from 'react';
import Select from "react-select";
import "moment/locale/pt-br";
import { useDispatch } from 'react-redux';
import { resetUnitFilter, setFilter, setUnitFilter } from '../../../../app/redux/filter/filterSlice';
import { getUnitsByIdProduct } from '../../../../app/request/unit';
import { useAuth } from '../../../../app/modules/auth';
import { useSelector } from 'react-redux';
import { KTSVG } from '../../../helpers';

const FormFilterUnit: React.FC = () => {

    const dispatch = useDispatch();

    const filterGlobalProduct = useSelector((state: any) => state.productSlice);

    const { auth, logout, setCurrentUser } = useAuth();

    interface Option {
        value: string;
        label: string;
    }

    interface OptionSelected {
        id_unit: string;
    }

    const optionsTypePlan: Option[] = [
        { value: "H", label: "Terreno" },
        { value: "V", label: "Predio" }
    ];

    const [formData, setFormData] = useState(
        {
            unit: {
                id_unit: ''
            }
        }
    );

    const [optionsUnit, setOptionsUnit] = useState<Option[]>([]);

    useEffect(() => {

        fetchUnits(filterGlobalProduct?.product?.id_product || '');

    }, [filterGlobalProduct?.product?.id_product || '']);

    interface Option {
        value: string;
        label: string;
    }

    const handleSelectChange = <K extends keyof OptionSelected>(selectedOption: Option | null, fieldName: K) => {
        const value = selectedOption ? selectedOption.value : "0";
        setFormData((prevState: any) => ({
            ...prevState,
            unit: {
                ...prevState.unit,
                [fieldName]: value.toString()
            },
        }));
    };

    const fetchUnits = async (id: string) => {
        if (auth && auth.api_token) {
            const response = await getUnitsByIdProduct(id, auth);

            if (response && response.data && Array.isArray(response.data)) {
                const newOptionsUnit = response.data.map((unit: { id: any; name: any; }) => ({
                    value: unit.id.toString(),
                    label: unit.name,
                }));

                setOptionsUnit(newOptionsUnit);
            }
        }
    };


    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault();

        setFormData((prevState) => ({
            ...prevState,
            unit: {
                ...prevState.unit,
                id_unit: formData.unit.id_unit || '',
            },
        }));

        // Despachar a ação setFilter com os dados do filtro
        dispatch(setUnitFilter(formData.unit));

    }

    const resetFilterForm = () => {

        setFormData((prevState) => ({
            ...prevState,
            unit: {
                ...prevState.unit,
                id_unit: '',
            },
        }));

        // Despachar a ação setFilter com os dados do filtro
        dispatch(resetUnitFilter());
        // dispatch(setFilter(formData));
    }

    return (

        <form id="form_filter_contract" onSubmit={handleSubmit}>
            <div className="d-flex flex-column align-items align-items-start">
                <div className="d-flex align-items-center mb-2"></div>
                <div className='mb-10 row'>
                    {/* <div className="col-md-6">
                            <label className='form-label fw-bold'>Produto</label>
                            <div className='d-flex'>
                                <Select
                                    className="w-450px"
                                    placeholder="Selecione um produto"
                                    value={formData.id_product !== '0' && formData.id_product !== '' ? { value: formData.id_product, label: optionsProduct.find(option => option.value === formData.id_product)?.label } : null}
                                    
                                    onChange={(selectedOption:any) => handleSelectChange(selectedOption as Option | null, "id_product")}
                                    options={optionsProduct}
                                />
                            </div>
                        </div> */}
                    <div className="col-md-12">
                        <label className='form-label fw-bold'>Unidade</label>
                        <div className='d-flex'>
                            <Select
                                name="id_unit"
                                className="w-450px"
                                placeholder="Selecione uma Unidade"
                                value={formData.unit.id_unit !== '0' && formData.unit.id_unit !== '' ? { value: formData.unit.id_unit, label: optionsUnit.find(option => option.value === formData.unit.id_unit)?.label } : null}
                                options={optionsUnit}
                                onChange={(selectedOption: any) => handleSelectChange(selectedOption as Option | null, "id_unit")}
                            />
                        </div>
                    </div>
                </div>

            </div>
            <div className="card-footer pt-4" id="kt_drawer_filter_footer">
                <div className='d-flex justify-content-center'>
                    <button
                        type='reset'
                        className='btn btn-sm btn-light btn-active-light-primary me-2 btn_reset_filter_all'
                        data-kt-menu-dismiss='true'
                        onClick={() => resetFilterForm()}
                    >
                        Resetar filtro
                    </button>

                    <button type="submit" data-kt-indicator='off' className="btn btn-sm btn-primary me-3 btn_filter_all" data-kt-menu-dismiss='true' data-kt-stepper-action="submit">
                        <span className="indicator-label">
                            Filtrar
                            <KTSVG className="svg-icon svg-icon-2" path="/media/icons/duotune/general/gen031.svg" />
                        </span>
                        <span className="indicator-progress">
                            Carregando... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                </div>
            </div>
        </form>
    );
};

export default FormFilterUnit;
