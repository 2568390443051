import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IResponse } from "../../../interface/I_General";
import { OptionSelect } from "../../../interface/I_Product";
import { getToken } from "../auth/getToken";
import * as authHelper from './../../../modules/auth/core/AuthHelpers';

export interface IPermissionResponse {
    permissionsList?: string[];
    allowedsPermission?: string[];
    routesMenu?: any[];
    // Outros campos que possam vir do backend
}
const API_URL = process.env.REACT_APP_API_URL;
const uuid = authHelper.getAuthUui();

export const permissionsList = createApi({
    reducerPath: 'listPermission',
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL,
        prepareHeaders: (headers) => {
            const dataLocalStorage = getToken();

            if (dataLocalStorage?.token) {
                headers.set('Authorization', `Bearer ${dataLocalStorage?.token}`);
            }

            if (uuid) {
                headers.set('X-UUID-Assinante', String(uuid));
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        fetchPermission: builder.query<IPermissionResponse, null>({
            query: (params) => ({
                url: `module/permissions`,
                method: 'post'
            }),
        }),

    }),
});
export const { useFetchPermissionQuery } = permissionsList