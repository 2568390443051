import { Link } from "react-router-dom";
import { IHeaderWithCell } from "../../../app/interface";
import ComponentPermission from "../../../_metronic/layout/components/permissions/ComponentPermission";
import * as authHelper from './../../../app/modules/auth/core/AuthHelpers';

export const HeadertTableProduct = (type: string, handleEdit?: (row: any) => void): IHeaderWithCell[] => {

    const uuid = authHelper.getAuthUui();

    switch (type) {
        case 'product': // carne
            return [
                { Header: 'Produto', accessor: 'name' },
                { Header: 'Tipo', accessor: 'product_plan' },
                { Header: 'Cidade', accessor: 'city' },
                { Header: 'Proprietário', accessor: 'name_owner' },
                {
                    Header: 'Ações',
                    accessor: (row: any) => (
                        <ComponentPermission>
                            <ComponentPermission.Edit>
                                <div>

                                    <Link className="btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary" to={`${row.link}/${uuid}`}>
                                        Editar
                                    </Link>
                                </div>
                            </ComponentPermission.Edit>
                        </ComponentPermission>
                    )
                }
            ];

        default:
            return [
                { Header: 'Produto', accessor: 'name' },
                { Header: 'Tipo', accessor: 'type' },
                { Header: 'Cidade', accessor: 'city' },
                { Header: 'Proprietário', accessor: 'owner' },
                {
                    Header: 'Ações',
                    accessor: (row: any) => (
                        <div>
                            <button className="btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary" onClick={() => console.log(`Ação para ID  `, row)}>
                                Editar
                            </button>
                        </div>
                    )
                }
            ];
    }
};

export default HeadertTableProduct