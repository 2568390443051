import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { reportDashboard } from './api/dashboard/reportDashboard';
import { listProduct } from './api/product/listProduct';
import { billingPosition } from './api/payment/billingPosition';
import filterReducer from './filter/filterSlice';
// import productSlice from './filter/productSlice';
import resetReducer from './filter/resetSlice';
import authReducer from './api/auth/auth';
import { listHistoric } from './api/historico/Historic';
import historicSlice from './api/historico/historicSlice';
import permissionReducer from './api/permission/permissionSlice'
import { permissionsList } from './api/permission/permissionList'

const rootReducer = combineReducers({
    filter: filterReducer,
    // productSlice: productSlice,
    reset: resetReducer,
    historic: historicSlice,
    permission: permissionReducer,
    // auth: authReducer,
    [reportDashboard.reducerPath]: reportDashboard.reducer,
    [listProduct.reducerPath]: listProduct.reducer,
    [billingPosition.reducerPath]: billingPosition.reducer,
    [listHistoric.reducerPath]: listHistoric.reducer,
    [permissionsList.reducerPath]: permissionsList.reducer,
});

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(reportDashboard.middleware)
            .concat(listProduct.middleware)
            .concat(billingPosition.middleware)
            .concat(listHistoric.middleware)
            .concat(permissionsList.middleware),
});


setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export default store;