import axios from "axios";
import { AuthType, IResponse } from "../interface/I_General";

import { getToken } from './../redux/api/auth/getToken';
import * as authHelper from './../modules/auth/core/AuthHelpers';

const API_URL = process.env.REACT_APP_API_URL;


// recupera dados do histroico
export async function getHistoric(id: number, auth: AuthType) {

    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';
    const dataLocalStorage = getToken();

    try {
        const response = await axios.get<IResponse>(
            `${API_URL}/module/historicList/${id}`,
            {
                //   headers: auth && auth.api_token ? { Authorization: `Bearer ${auth.api_token}` } : {}
                headers: {
                    Authorization: `Bearer ${dataLocalStorage?.token || auth.api_token}`,
                    'X-UUID-Assinante': uuid || lastUuid
                }
            }
        );
        return response.data;
    } catch (error) {
        // Tratar o erro aqui
        console.error("Ocorreu um erro ao buscar os dados:", error);
        throw error;
    }

}


// recupera dados do histroico
export async function getHistoricByIdParcel(id: number | string, auth: AuthType) {
    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';
    const dataLocalStorage = getToken();

    try {
        const response = await axios.get<IResponse>(
            `${API_URL}/module/historicListByParcel/${id}`,
            {
                // headers: auth && auth.api_token ? { Authorization: `Bearer ${auth.api_token}` } : {}
                headers: {
                    Authorization: `Bearer ${dataLocalStorage?.token || auth.api_token}`,
                    'X-UUID-Assinante': uuid || lastUuid
                }
            }
        );
        return response.data;
    } catch (error) {
        // Tratar o erro aqui
        console.error("Ocorreu um erro ao buscar os dados:", error);
        throw error;
    }

}

// insere historico
export async function insertHistoricAPI(data: any, auth: AuthType) {
    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';
    const dataLocalStorage = getToken();

    try {

        const response = await axios.post<IResponse>(`${API_URL}/module/historicInsert`,
            { data },
            {
                headers: {
                    Authorization: `Bearer ${dataLocalStorage?.token || auth.api_token}`,
                    'X-UUID-Assinante': uuid || lastUuid
                }
            }
        );

        return response;
    } catch (error) {
        throw error;
    }

}

// deleta historico
export async function deleteHistoricAPI(ids: any, auth: AuthType) {
    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';
    const dataLocalStorage = getToken();

    try {

        const response = await axios.post<IResponse>(`${API_URL}/module/historicDelete`,
            { ids },
            {
                headers: {
                    Authorization: `Bearer ${dataLocalStorage?.token || auth.api_token}`,
                    'X-UUID-Assinante': uuid || lastUuid
                }
            }
        );

        return response;
    } catch (error) {
        throw error;
    }

}



// recupera dados do histroico
export async function getVerifyHistory(idParcel: number | string, auth: AuthType) {
    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';
    const dataLocalStorage = getToken();

    try {

        const response = await axios.post<IResponse>(`${API_URL}/module/getVerifyHistory`,
            { idParcel },
            {
                headers: {
                    Authorization: `Bearer ${dataLocalStorage?.token || auth.api_token}`,
                    'X-UUID-Assinante': uuid || lastUuid
                }
            }
        );

        return response;

    } catch (error) {
        // Tratar o erro aqui
        console.error("Ocorreu um erro ao buscar os dados:", error);
        throw error;
    }

}