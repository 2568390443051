import axios, { AxiosError, AxiosResponse } from 'axios'
import BaseServiceHttp from '../../_metronic/helpers/functions/http/BaseServiceHttp';
import { IResponse, AuthType } from '../interface/I_General';
import { IFormUnit } from '../interface/I_Unit';

import { getToken } from './../redux/api/auth/getToken';
import * as authHelper from './../modules/auth/core/AuthHelpers';

const API_URL = process.env.REACT_APP_API_URL;

export const getUnitsByIdProduct = async (idProduct: string, auth: AuthType) => {

    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';
    const dataLocalStorage = getToken();
    try {

        const response = await axios.post<IResponse>(`${API_URL}/module/unitByProduct`,
            { idProduct },
            {
                headers: {
                    Authorization: `Bearer ${dataLocalStorage?.token || auth?.api_token}`,
                    'X-UUID-Assinante': uuid || lastUuid
                }
            }
        );

        return response;
    } catch (error) {

    }
}

// editar um contrato
export async function editUnitAPI(form: IFormUnit) {

    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';
    const dataLocalStorage = getToken();

    const customConfig: any = {
        method: 'post',
        url: `/module/editUnit`,
        data: {
            form
        },
        headers: {
            Authorization: `Bearer ${dataLocalStorage?.token}`,
            'X-UUID-Assinante': uuid || lastUuid
        }
    };

    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
        .then((response: AxiosResponse<any>) => {
            return response;
        })
        .catch((error: AxiosError) => {
            // throw error; // Rejeita a Promise para propagar o erro
            const { response } = error;
            let statusResponse: any;
            if (response) {
                const { status, data } = response;
                statusResponse = status;
            }

            return {
                data: {
                    success: false,
                    data: [],
                    status: statusResponse,
                    message: 'Ocorreu um erro ao tentar editar unidade'
                }
            }
        });
    return response;

}

// deleta uma ou mais unidades
export async function deleteUnitsAPI(ids: string[], auth: AuthType) {

    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';
    const dataLocalStorage = getToken();

    const customConfig: any = {
        method: 'post',
        url: `/module/deleteUnit`,
        data: {
            ids
        },
        headers: {
            Authorization: `Bearer ${dataLocalStorage?.token || auth?.api_token}`,
            'X-UUID-Assinante': uuid || lastUuid
        }
    };

    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
        .then((response: AxiosResponse<any>) => {
            return response;
        })
        .catch((error: AxiosError) => {
            // throw error; // Rejeita a Promise para propagar o erro
            const { response } = error;
            let statusResponse: any;
            if (response) {
                const { status, data } = response;
                statusResponse = status;
            }

            return {
                data: {
                    success: false,
                    data: [],
                    status: statusResponse,
                    message: 'Ocorreu um erro ao tentar deletar unidade'
                }
            }
        });
    return response;
}

// pega unidade pelo nome
export async function getUnitByName(name: string, auth: AuthType) {

    const uuid = authHelper.getAuthUui();
    const lastUuid = authHelper.getAuthLastUui() || '';
    const dataLocalStorage = getToken();

    try {
        const response = await axios.post<IResponse>(`${API_URL}/module/getUnitByName`,
            { name },
            {
                headers: {
                    Authorization: `Bearer ${dataLocalStorage?.token || auth?.api_token}`,
                    'X-UUID-Assinante': uuid || lastUuid
                }
            }
        );

        return response;
    } catch (error) {
        throw error;
    }
}

