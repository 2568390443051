import React, { useEffect, useState } from "react";
import { useMutation } from 'react-query';
import { KTSVG } from "../../../../helpers";
import { FormNewBoleto, FormProps, FormPropsBoleto, FormValues } from "../../../../../app/interface/I_Parcel";
import { NumericFormat } from "react-number-format";
import Swal from "sweetalert2";

export const FormDateValue = ({
    closeModal,
    handlerSaveForm,
    formValues,
    handleChange
}: FormProps) => {


    useEffect(() => {
        const formattedValue = formValues.value;
        handleChange("value", formattedValue);
    }, []);

    const handleInputChange = (
        value: string,
        fieldName: keyof FormValues
    ) => {

        handleChange(fieldName, value);
    };

    const modalMessage = 'A data fornecida é apenas uma sugestão para a emissão dos boletos. Recomendamos selecionar a data mais adequada para sua conveniência.';

    return (
        <div className={`modal fade bg-dark bg-opacity-75 ${!closeModal ? "" : "show"}`} id="modalDaeValue" tabIndex={-1} aria-modal="true" role="dialog" style={{ "display": !closeModal ? "none" : 'block' }}>
            <div className={`modal-dialog modal-lg p-5 p-lg-10`} id="">
                <div className="modal-content rounded-4">
                    <div className="modal-header flex-stack border-0 px-10 pt-5 pb-0" id="">
                        <div id="" className="position-relative z-index-1"></div>

                        <div className="btn btn-sm btn-icon btn-active-color-primary me-n2 position-relative z-index-1" onClick={closeModal}>
                            <KTSVG className="svg-icon svg-icon-2x" path="/media/icons/duotune/general/gen034.svg" />
                        </div>
                    </div>

                    <div className="modal-body pt-0 pb-5 px-15 mt-n5" id="modaBody">
                        <div className="container-fluid">
                            <div className="d-block" id="kt_app_engage_prebuilts_view_image">
                                <div className="tab-content">
                                    <div className="pt-5 tab-pane fade active show" id="" role="tabpanel">
                                        <div className="separator d-flex flex-center">
                                            <span className="text-uppercase bg-body fs-7 fw-semibold text-muted px-3">Dados do Boleto</span>
                                        </div>
                                        <div className="pe-12 me-n12 mt-10">
                                            <div className="overflow-auto pb-5">


                                                <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed min-w-lg-600px flex-shrink-0 p-6">



                                                    <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">

                                                        <div className="mb-3 mb-md-0 fw-semibold">
                                                            <h4 className="text-gray-900 fw-bold">Atenção</h4>

                                                            <div className="fs-6 text-gray-700 pe-7">{modalMessage}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="row gy-5">

                                                <div className="col-lg-6 fv-plugins-icon-container">
                                                    <label className='form-label fw-bold'>Novo Vencimento</label>
                                                    <div className='d-flex'>
                                                        <input
                                                            type="date"
                                                            className={`form-control`}
                                                            name="due_date"
                                                            value={formValues.due_date}
                                                            onChange={(e) => {
                                                                handleInputChange(e.target.value, "due_date")
                                                            }
                                                            }
                                                        // readOnly={formValues.token_boleto !== '' ? true : false}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 fv-plugins-icon-container">
                                                    <label className='form-label fw-bold'>Novo Valor</label>
                                                    <div className='d-flex'>
                                                        <NumericFormat
                                                            thousandSeparator="."
                                                            decimalSeparator=","
                                                            prefix="R$ "
                                                            thousandsGroupStyle="thousand"
                                                            fixedDecimalScale={true}
                                                            decimalScale={2}
                                                            allowLeadingZeros={true}
                                                            // className={`form-control ${typeof formValues.token_boleto !== 'undefined' && formValues.token_boleto !== '' && formValues.name_integration != "itau_bank"  ? 'form-control-solid' : ''}`} 
                                                            className={`form-control`}
                                                            name="value"
                                                            value={formValues.value || '0.00'}
                                                            onValueChange={(values) => {
                                                                handleInputChange(String(values.floatValue || '0.00'), "value");
                                                            }}
                                                        // readOnly={typeof formValues.token_boleto !== 'undefined' && formValues.token_boleto !== '' && formValues.name_integration != "itau_bank" ? true : false}
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="modal-footer border-0">
                                            <button type="button" className="btn btn-light" onClick={closeModal}>Fechar</button>
                                            <button type="button" className="btn btn-success me-4" onClick={handlerSaveForm}>
                                                Salvar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}